import { Box, Typography } from "@material-ui/core";
import { FiberManualRecord } from "@material-ui/icons";
import React from "react";
import useGetColor from "../../../common/hooks/useGetColor";

interface StatusProps {
  text: string;
}

const Status = ({ text }: StatusProps) => {
  const { getColor } = useGetColor();

  return (
    <Box display="flex" alignItems="center">
      <FiberManualRecord
        fontSize="small"
        style={{ color: getColor(text), marginRight: 5 }}
      />
      <Typography variant="caption">{text}</Typography>
    </Box>
  );
};

export default Status;
