import { RequestQueryBuilder } from "@nestjsx/crud-request";
import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { apiInstance } from "../../../common/api";
import { RoleTypes } from "../../../common/enums/roleTypes.enum";
import { Severity } from "../../../common/enums/severity.enum";
import useAPINotification from "../../../common/hooks/useAPINotification";
import { APINotificationActionTypes } from "../../../common/providers/APINotification/enums/APINotificationActionType.enum";
import { GlobalStateContext } from "../../../common/providers/global-state-provider/global-state.context";
import { User } from "../../../models";
import { SubProcedureText } from "../../../models/SubProcedureText";
import useFetchConsultants from "./useFetchConsultants";

export default function useCreateApplications(
  patientId: string,
  closeModal: () => void
) {
  const { dispatchNotification } = useAPINotification();
  const { globalState } = useContext(GlobalStateContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [procedureId, setProcedureId] = useState();
  const { clinicConsultants, isLoading } = useFetchConsultants();

  const [consultantId, setConsultantId] = useState();
  const [subProcedures, setSubProcedures] = useState<string[]>([]);
  const [subProceduresText, setSubProceduresText] = useState("");
  const [addedText, setAddedText] = useState("");

  const getSubprocedureTexts = useCallback(
    (subProcedureTexts: SubProcedureText[]) => {
      const result: SubProcedureText[] = [];
      subProcedures.forEach((subProcedure) => {
        const texts = subProcedureTexts.filter(
          (subProcedureText) => subProcedureText.procedureId === subProcedure
        );

        if (texts[0]) {
          result.push(texts.find((text) => text.userId !== null) || texts[0]);
        }
      });

      let finalText = "";

      result.forEach((subProcedureText) => {
        finalText =
          finalText + (subProcedureText ? subProcedureText.text : "") + ". ";
      });

      setSubProceduresText(finalText);
    },
    [subProcedures]
  );

  useEffect(() => {
    const fetchSubProcedureTexts = async () => {
      try {
        const orArr = subProcedures.map((procedure) => ({
          procedureId: procedure,
        }));
        const qb = RequestQueryBuilder.create();
        qb.search({
          $and: [
            {
              userId: { $isnull: true },
            },
            {
              $or: orArr,
            },
          ],
        });
        // qb.search({
        // 	$and: [
        // 		{
        // 			$or: [
        // 				{
        // 					userId: globalState.user.id
        // 				},
        // 				{
        // 					userId: { $isnull: true }
        // 				}
        // 			]

        // 		},
        // 		{
        // 			$or: orArr
        // 		}
        // 	]
        // });

        qb.query();

        const res = await apiInstance.get(
          `/sub-procedure-texts?${qb.queryString}`
        );

        if (res.data && res.data.length > 0) {
          getSubprocedureTexts(res.data);
        }
      } catch (err) {
        console.log(err);
        dispatchNotification({
          type: APINotificationActionTypes.SET_NOTIFICATION,
          payload: {
            message: "Error trying to get sub-procedure texts.",
            severity: Severity.ERROR,
          },
        });
      }
    };

    if (subProcedures.length > 0) {
      fetchSubProcedureTexts();
    }
  }, [dispatchNotification, getSubprocedureTexts, globalState, subProcedures]);

  const handleChange = (event: ChangeEvent<any>) => {
    switch (event.target.name) {
      case "procedure":
        setProcedureId(event.target.value);
        break;
      case "subProcedures":
        setSubProcedures(event.target.value);
        break;
      case "addedText":
        setAddedText(event.target.value);
        break;
      case "consultant":
        setConsultantId(event.target.value);
        break;

      default:
        break;
    }
  };

  const handleClose = () => {
    console.log("handleClose");
    setProcedureId(undefined);
    setSubProcedures([]);
    setConsultantId(undefined);

    setSubProceduresText("");
    setAddedText("");
  };

  const createApplication = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    try {
      const values = new FormData(event.currentTarget);
      const procedureId = values.get("procedure");

      const res = await apiInstance.post("/consent-forms", {
        patientId,
        consultantId,
        procedureId,
        subProceduresText: subProceduresText + addedText,
        subProcedures: subProcedures.map((subProcedure) => ({
          id: subProcedure,
        })),
      });

      if (res.status === 201) {
        handleClose();
        closeModal();
      }
    } catch (error) {
      console.log(error);
      dispatchNotification({
        type: APINotificationActionTypes.SET_NOTIFICATION,
        payload: {
          message: "Error trying to create application.",
          severity: Severity.ERROR,
        },
      });
    } finally {
      setLoading(false);
    }
  };

  return {
    addedText,
    createApplication,
    handleChange,
    handleClose,
    loading,
    procedureId,
    clinicConsultants,
    consultantId,
    subProcedures,
    subProceduresText,
  };
}
