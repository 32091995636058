import { Button, makeStyles } from "@material-ui/core";
import React from "react";
import EmptyTemplate from "./templates/Empty";
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	bg: {
		height: "100vh",
		padding: 50,
		background: `linear-gradient(90deg, rgba(25,103,113,1) 0%, rgba(80,169,180,1) 100%)`,
		fallbacks: {
			background: theme.palette.primary.main,
		}
	},
	title: {
		color: "#fff",
		fontSize: "5rem",
		marginBottom: 0
	},
	subtitle: {
		color: "#fff",
		fontSize: "1.2rem",
		marginBottom: 25,
		marginTop: 0
	}
}));

interface Props {
	title: string;
	desc: string;
}

const ErrorPage = ({ title, desc }: Props) => {
	const classes = useStyles();

	return (
		<EmptyTemplate title="Page not found" className={classes.bg}>
			<h1 className={classes.title}>{title}</h1>
			<p className={classes.subtitle}>{desc}</p>
			<Button variant="contained" color="primary" component={RouterLink} to="/">
				Back to Home
			</Button>
		</EmptyTemplate>
	);
}

export default ErrorPage;
