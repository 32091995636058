import { RequestQueryBuilder } from "@nestjsx/crud-request";
import { useCallback, useEffect, useState } from "react";
import { apiInstance } from "../../../common/api";
import { Severity } from "../../../common/enums/severity.enum";
import useAPINotification from "../../../common/hooks/useAPINotification";
import { APINotificationActionTypes } from "../../../common/providers/APINotification/enums/APINotificationActionType.enum";

import { Patient } from "../../../models";

//TODO research if this hook should be common.

export default function useFetchPatient() {
	const { dispatchNotification } = useAPINotification();
	const [loading, setLoading] = useState<boolean>(false);
	const [patients, setPatients] = useState<Patient[]>([]);

	const fetchPatients = useCallback(async (searchTerm?: string) => {
		try {
			setLoading(true);
			
			const qb = RequestQueryBuilder.create();

			if(searchTerm){
				qb.search({
					$or: [
						{
							firstName: {
								$contL: searchTerm
							},
						},
						{
							lastName: {
								$contL: searchTerm
							},
						},
						{
							email: {
								$contL: searchTerm
							},
						},
						{
							phone: {
								$contL: searchTerm
							},
						},
					]
				});
			}

			qb.sortBy({ field: "createdAt", order: "DESC" });

			const res = await apiInstance.get(`/patients?${qb.query()}`);

			if (res.data) {
				setPatients(res.data);
			}
		} catch (error) {
			dispatchNotification({ type: APINotificationActionTypes.SET_NOTIFICATION, payload: { message: "Error trying to get patients.", severity: Severity.ERROR } });
		} finally {
			setLoading(false);
		}
	}, [dispatchNotification]);

	useEffect(() => {
		fetchPatients();
	}, [dispatchNotification, fetchPatients]);

	return {
		patients,
		loading,
		setPatients,
		fetchPatients
	}
}