import { useContext, useState } from "react";
import { apiInstance } from "../../../common/api";
import { GlobalStateContext } from "../../../common/providers/global-state-provider/global-state.context";

export default function useLogin() {
  const { dispatchToGlobal } = useContext(GlobalStateContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [loginSuccess, setLoginSuccess] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);

  const attemptLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    const values = new FormData(event.currentTarget);
    const username = values.get("username");
    const password = values.get("password");

    /* 
			1 sec timeout to give a change for existing
			error messages to clear and new errors to
			re-appear
		*/
    setTimeout(async () => {
      try {
        const res = await apiInstance.post("/auth/login", {
          username,
          password,
          type: "user",
        });

        if (res.status === 200) {
          sessionStorage.setItem("auth", new Date().getTime().toString());
          setLoginSuccess(true);
        }
      } catch (error: any) {
        console.log(error);
        // Handle errors
        switch (error.response.data.message) {
          case "Incorrect credentials":
            setError(
              "Incorrect email or password combination. Your account will be locked after 5 incorrect attempts."
            );
            break;
          case "Invalid CSRF token":
            setError(
              "Expired log in token. Please refresh the page and try again."
            );
            break;
          case "Your account is locked":
            setError("Your account is locked. Please contact support.");
            break;
          default:
            setError("Unexpected server error. Please try again.");
        }
      } finally {
        setLoading(false);
      }
    }, 1000);
  };

  return {
    attemptLogin,
    error,
    loading,
    loginSuccess,
  };
}
