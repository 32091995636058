import React from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  Button,
  Box,
} from "@material-ui/core";
import LoggedInTemplate from "../templates/LoggedIn";
import { Link as RouterLink } from "react-router-dom";
import ClinicsTable from "./Clinics-table";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topbar: {
      display: "flex",
      alignItems: "flex-start",
      marginBottom: 15,
    },
  })
);

const Users = () => {
  const css = useStyles();

  return (
    <LoggedInTemplate title="Clinics">
      <div className={css.topbar}>
        <Box mr={2}>
          <Button
            variant="contained"
            color="primary"
            component={RouterLink}
            to="/clinics/new"
          >
            Add new clinic
          </Button>
        </Box>
      </div>
      <ClinicsTable />
    </LoggedInTemplate>
  );
};

export default Users;
