import React from "react";
import { Patient } from "../../../models";
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { usePatientsTableStyles } from "./patients-table.css";
import { Order } from "./interfaces/order.type.";

interface Props {
  classes: ReturnType<typeof usePatientsTableStyles>;
  columns: {
    label: string;
    property: keyof Patient | "actions";
  }[];
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Patient
  ) => void;
  order: Order;
  orderBy: string;
}

const PatientsTableHead = ({
  classes,
  columns,
  order,
  orderBy,
  onRequestSort,
}: Props) => {
  const createSortHandler =
    (property: keyof Patient) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {columns.map((column, index) => (
          <TableCell
            key={index}
            sortDirection={orderBy === column.property ? order : false}
          >
            {column.property === "actions" ? (
              column.label
            ) : (
              <TableSortLabel
                active={orderBy === column.property}
                direction={orderBy === column.property ? order : "asc"}
                onClick={createSortHandler(column.property)}
              >
                {column.label}
                {orderBy === column.property ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default PatientsTableHead;
