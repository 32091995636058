import { Box, Typography } from "@material-ui/core";
import React from "react";
import Signature from "../Signature/Signature";
import useSignatures from "./hooks";

interface Props {
  consentFormStatus: string;
}

const Signatures = ({ consentFormStatus }: Props) => {
  const { getSignatureByType } = useSignatures();

  return (
    <Box component="section">
      <Typography component="h2" variant="h2">
        Signatures
      </Typography>
      <Box fontSize={16}>
        <ul>
          <li style={{ marginBottom: 8 }}>
            I understand that no guarantee is given that a particular person
            will perform my procedure. However, the person performing my
            procedure will be appropriately qualified, experienced and
            supervised where necessary.
          </li>
          <li style={{ marginBottom: 8 }}>
            I understand that I will have the opportunity to discuss the details
            of anaesthesia with an anaesthetist before the procedure, unless the
            urgency of my situation prevents this. (This only applies to
            patients having general or regional anaesthesia).
          </li>
          <li style={{ marginBottom: 8 }}>
            I understand that any procedure in addition to those described above
            will only be carried out if it is necessary to save my life or to
            prevent serious harm to my health. I have been told about additional
            procedures which may become necessary during my treatment and have
            indicated above in the considerations those things I do or do not
            wish to happen and any other relevant health issues or allergies.
          </li>
          <li>I agree to the procedure or course of treatment above.</li>
        </ul>
      </Box>

      <Signature
        existingSignature={getSignatureByType("Patient")}
        name="Patient signature"
        type="Patient"
        desc="Please electronically sign below to confirm you understand all the information you have been previously presented."
      />

      <Signature
        existingSignature={getSignatureByType("Witness")}
        name="Witness signature (optional)"
        type="Witness"
        desc="A witness should sign below if the patient is unable to sign but has indicated his or her consent. Young people/children may also like a parent to sign here. I agree that the patient has understood and indicated their consent to the procedure or course of treatment described above."
      />
      <Signature
        existingSignature={getSignatureByType("Interpreter")}
        name="Interpreter signature (optional)"
        type="Interpreter"
        desc="If an interpreter was required we need them to confirm the following: I have interpreted the information contained in this App to the patient to the best of my ability and in a way in which I believe he or she can understand."
      />
      <Signature
        existingSignature={getSignatureByType("Health Professional")}
        name="Health professional's declaration"
        type="Health Professional"
        desc="I confirm the patient has understood what is required of them to make an informed decision and
				has completed the online App in which I have addressed all questions and concerns that have been
				raised noting our discussions. I confirm the patient has expressed no other concerns and wishes to
				proceed with the treatment."
        consentFormStatus={consentFormStatus}
      />
    </Box>
  );
};

export default Signatures;
