import { Grid } from "@material-ui/core";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { GlobalStateContext } from "../../common/providers/global-state-provider/global-state.context";
import PopConfirm from "../PopConfirm";
import LoggedInTemplate from "../templates/LoggedIn";
import useEditUser from "../users/edit-user/hooks";
import UserForm from "../users/user-form";

const MyProfile = () => {
	const { globalState } = useContext(GlobalStateContext);
	const { push } = useHistory();
	const { abort, setAbort } = useEditUser();

	return (
		<LoggedInTemplate title="My Profile">
			<PopConfirm
				title="Are you sure?"
				message="Any unsaved data you have entered will be lost."
				show={abort}
				handleClose={() => setAbort(false)}
				continueAction={() => push("/users")}
			/>

			<Grid container spacing={3}>
				<Grid item lg={12}>
					<UserForm
						editingSelf
						currentUser={globalState.user}
						setAbort={(v: boolean) => setAbort(v)}
						userId={globalState?.user?.id || ""}
					/>
				</Grid>
			</Grid>
		</LoggedInTemplate>
	)
}

export default MyProfile;