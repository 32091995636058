import React from "react";
import { Box, Chip } from "@material-ui/core";
import MaterialTable from "material-table";
import { useHistory } from "react-router-dom";
import { Clinic } from "../../../models";
import useClinicsTable from "./hooks/use-clinics-table";
import { stringToColour } from "../../../lib";
import Avatar from "../../Avatar";
import { Height } from "@material-ui/icons";

const ClinicsTable = () => {
  const { push } = useHistory();
  const { data, loading } = useClinicsTable();

  return (
    <MaterialTable
      // style={{
      //   boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      //   borderRadius: "10px",
      // }}
      data={data}
      isLoading={false}
      options={{
        search: false,
        showTitle: false,
        toolbar: false,
        actionsColumnIndex: -1,
        pageSize: 5,
      }}
      columns={[
        {
          title: "Name",
          field: "name",
        },
        {
          title: "Email",
          field: "email",
        },
      ]}
      actions={[
        {
          icon: "edit",
          tooltip: "Edit Clinic",
          onClick: (e, { id }: any): void => {
            push(`clinics/${id}/edit`);
          },
        },
      ]}
    />
  );
};

export default ClinicsTable;
