import React, { Fragment } from "react";
import { createStyles, List, makeStyles, Theme, Typography } from "@material-ui/core";
import TopicItem from "../TopicItem";
import { Topic } from "../../../models";
import { TopicType } from "../../../common/enums/topic-type.enum";

const useStyles = makeStyles((theme: Theme) => createStyles({
	section: {
		display: "block",
		padding: 0,
		marginBottom: 75,
		marginTop: 25,
		paddingBottom: 25,
		"&:last-of-type": {
			marginBottom: 0
		}
	}
}));

interface Props {
  type: TopicType;
  items: Topic[];
}

const Topics = ({ items, type }: Props) => {
  
  const css = useStyles();

	return (
    <Fragment>
      <Typography variant="h3" component="h3" display="block">{type}</Typography>
      <List className={css.section}>
        {
          items.sort((a, b) => a.order-b.order).map(item => <TopicItem key={item.id} topic={item} type={type} />)
        }
      </List>
    </Fragment>
	)
}

export default Topics;