import { useContext, useEffect, useState } from "react";
import { apiInstance } from "../../../common/api";
import { GlobalStateContext } from "../../../common/providers/global-state-provider/global-state.context";
import { Severity } from "../../../common/enums/severity.enum";
import useAPINotification from "../../../common/hooks/useAPINotification";
import { APINotificationActionTypes } from "../../../common/providers/APINotification/enums/APINotificationActionType.enum";
import { User } from "../../../models";
import { RoleTypes } from "../../../common/enums/roleTypes.enum";

export default function useFetchConsultants(withHighlights: boolean = false) {
  const { globalState } = useContext(GlobalStateContext);
  const { dispatchNotification } = useAPINotification();
  const [clinicConsultants, setClinicConsultants] = useState<User[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchConsultants = async () => {
      try {
        setLoading(true);
        const endpoint = withHighlights
          ? "/users/getManyConsultantsByClinicId?highlights=true"
          : "/users/getManyConsultantsByClinicId";
        const response = await apiInstance.get(endpoint, {
          params: { id: globalState.user.clinicId },
        });
        setClinicConsultants(response.data.data);
      } catch (error) {
        console.log(error);
        dispatchNotification({
          type: APINotificationActionTypes.SET_NOTIFICATION,
          payload: {
            message: "Error trying to get clinic consultants",
            severity: Severity.ERROR,
          },
        });
      } finally {
        setLoading(false);
      }
    };

    if (
      globalState.user.role === RoleTypes.ADMIN ||
      globalState.user.role === RoleTypes.CLINIC_ADMIN ||
      globalState.user.role === RoleTypes.CONSULTANT
    ) {
      fetchConsultants();
    }
  }, [dispatchNotification, globalState.user]);

  return {
    clinicConsultants,
    isLoading,
  };
}
