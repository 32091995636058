import React from "react";
import { Box, Chip } from "@material-ui/core";
import MaterialTable from "material-table";
import { useHistory } from "react-router-dom";
import { User } from "../../../models";
import useUsersTable from "./hooks/use-users-table";
import { stringToColour } from "../../../lib";
import Avatar from "../../Avatar";


const UsersTable = () => {
	const { push } = useHistory();
	const { data, loading } = useUsersTable();

	return (
		<MaterialTable
			data={data}
			isLoading={loading}
			options={{
				search: false,
				showTitle: false,
				toolbar: false,
				actionsColumnIndex: -1,
				pageSize: 10,
			}}
			columns={[
				{
					title: "Name",
					field: "firstName",
					render: (rowData: User) => {
						const { avatar, firstName, lastName, title, email } = rowData;

						return (
							<Box alignItems="center" display="flex">
								<Avatar firstName={firstName} lastName={lastName} email={email} avatarImg={avatar ? avatar : null} />{" "}
								<Box ml={1} fontSize={14} component="p">{title} {firstName} {lastName}</Box>
							</Box>
						);
					}
				},
				{ title: "Email", field: "email" },
				{ title: "Job Title", field: "jobTitle" },
				{
					title: "Role",
					field: "role",
					render: (rowData: User) => {
						return <Chip size="small" style={{ background: stringToColour(rowData.role), color: "#fff" }} label={rowData.role} />
					}
				},
				{ title: "Speciality", field: "speciality.name" },
				{ title: "Sub Speciality", field: "subSpeciality.name" },
				{ title: "Clinic", field: "clinic.name" },
			]}
			actions={[
				{
					icon: 'edit',
					tooltip: 'Edit User',
					// TODO: Type
					onClick: (e, { id }: any): void => {
						push(`users/${id}/edit`)
					}
				}
			]}
		/>
	);
}

export default UsersTable;