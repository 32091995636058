import { Resource, Speciality, SubSpeciality } from ".";
import { RoleTypes } from "../common/enums/roleTypes.enum";
import { Clinic } from "./Clinic";
import { Procedure } from "./Procedure";

export interface User {
	id: string;
	title: string;
	firstName: string;
	jobTitle: string;
	lastName: string;
	dateOfBirth: string;
	phone: string;
	email: string;
	createdAt: string;
	updatedAt: string;
	sex: string;
	addressLine1: string;
	addressLine2: string;
	city: string;
	county: string;
	postcode: string;
	avatar: string;
	clinicId: string;
	role: RoleTypes;
	roles?: string[];

	// Relations
	speciality?: Speciality;
	subspeciality?: SubSpeciality;
	procedures?: Procedure[];
	resources?: Resource[];
	clinic?: Clinic;
}

export const initialUser:User = {
	id: "",
	title: "",
	firstName: "",
	jobTitle: "",
	lastName: "",
	dateOfBirth: "",
	phone: "",
	email: "",
	createdAt: "",
	updatedAt: "",
	sex: "",
	addressLine1: "",
	addressLine2: "",
	city: "",
	county: "",
	postcode: "",
	avatar: "",
	clinicId: "",
	role: RoleTypes.NONE,
}
