import React from "react";
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
  Box,
  Grow,
} from "@material-ui/core";

import Alert from "@material-ui/lab/Alert";
import { Clinic } from "../../../models";
import useClinicForm from "./hooks/use-clinic-form";
import { Redirect } from "react-router-dom";

interface Props {
  setAbort: (v: boolean) => void;
  clinicId?: string;
  editingClinic?: boolean;
}

const AddClinicForm = ({ setAbort, clinicId, editingClinic }: Props) => {
  const { error, values, onFieldChange, handleSubmit, loading, success } =
    useClinicForm(clinicId);

  if (success && !loading) {
    return (
      <Redirect to={{ pathname: "/clinics", state: { persistAlert: true } }} />
    );
  }

  if (loading) {
    return <span>loading</span>;
  }
  return (
    <form onSubmit={(event) => handleSubmit(event, clinicId)}>
      <Grid container spacing={3}>
        <Grid item lg={8}>
          <Grid container spacing={3}>
            <Grid item lg={6}>
              <FormControl fullWidth margin="normal">
                <TextField
                  autoFocus
                  required
                  onChange={onFieldChange}
                  name="name"
                  label="Name"
                  value={values.name}
                />
              </FormControl>
            </Grid>
            <Grid item lg={6}>
              <FormControl fullWidth margin="normal">
                <TextField
                  onChange={onFieldChange}
                  required
                  name="email"
                  label="Email"
                  value={values.email}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Button
        style={{ marginTop: 25, marginRight: 25 }}
        onClick={() => setAbort(true)}
        type="button"
        variant="contained"
        color="default"
      >
        Back
      </Button>
      <Button
        style={{ marginTop: 25 }}
        type="submit"
        disabled={loading}
        variant="contained"
        color="primary"
      >
        {clinicId ? "Update" : "Create"} Clinic{" "}
        {loading && <CircularProgress style={{ marginLeft: 15 }} size={15} />}
      </Button>
      {error && (
        <Box mt={5}>
          <Grow in={true} timeout={1000}>
            <Alert
              style={{ margin: "15px 0" }}
              variant="filled"
              severity="error"
            >
              {error}
            </Alert>
          </Grow>
        </Box>
      )}
    </form>
  );
};

export default AddClinicForm;
