import { ReactChild } from "react";
import { Highlight, initialUser, User } from "../../../../models";

export interface GlobalState {
	user: User;
	alert: {
		content: ReactChild;
		severity: "error" | "warning" | "info" | "success"
	};
	params_currentProcedure: string;
	params_currentFreq: "Very Rare" | "Rare" | "Uncommon" | "Common" | "Very Common" | "Other";
	applicationsHighlights: Highlight[];
	inactiveTimeOut: string;
}

export const initialState: GlobalState = {
	user: initialUser,
	alert: null as any,
	params_currentProcedure: "",
	params_currentFreq: "" as any,
	applicationsHighlights: [],
	inactiveTimeOut: "",
};