import React, { useContext } from "react";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { APINotificationContext } from "../../common/providers/APINotification/APINotification.context";
import { APINotificationActionTypes } from "../../common/providers/APINotification/enums/APINotificationActionType.enum";
import { useGlobalAlertStyles } from './global-alert.css';

const GlobalAlert = () => {

	const { dispatchNotification, notification } = useContext(APINotificationContext);
  const classes = useGlobalAlertStyles();

  return notification
    ? (
        <Snackbar
          className={classes.notification}
          open={notification !== null}
          autoHideDuration={3000}
          onClose={() => dispatchNotification({ type: APINotificationActionTypes.REMOVE_NOTIFICATION })}>
          <Alert variant="filled" severity={notification.severity}>
            {notification.message}
          </Alert>
        </Snackbar>
      )
    : null;
}

export default GlobalAlert;