import React from "react";
import { Box, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import useInteractions from "./hooks";
import InteractionItem from "../InteractionItem";

interface Props {
    answerId: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({        
        avatarContainer: {
            minWidth: '35px'
        },
        reviewClick: {
            cursor: 'pointer'
        },
        section: {
            padding: 15,
            // border: '1px solid #97c6ff',
            // backgroundColor: '#f4f9ff'
        },
        sectionTitle:{
            paddingTop: 15,
            marginBottom: 0
        },
        small: {
            width: theme.spacing(3),
            height: theme.spacing(3),
            fontSize: '0.8rem'
        }
	}),
);

const Interactions = ({ answerId }: Props) => {

    const css = useStyles();    
    const { interactions } = useInteractions(answerId);
    
	return (
        <Box component="section" className={css.section} flex={1}>            
            {
                interactions.length > 0 
                ? (<Typography variant="h6" component="p" className={css.sectionTitle} style={{fontSize: 16}}>Patient questions:</Typography>)
                : null
            }
            {
                interactions.map( interaction => <InteractionItem key={interaction.id} interaction={interaction} />)
            }
        </Box>
    );
}

export default Interactions;