import React from "react";
import { Button, CircularProgress, createStyles, Grid, makeStyles, TextField, Theme } from "@material-ui/core";
import AceEditor from "react-ace";
import "ace-builds/webpack-resolver";
import "ace-builds/src-min-noconflict/ext-language_tools";
import "ace-builds/src-min-noconflict/mode-html";
import "ace-builds/src-min-noconflict/theme-monokai";
import useBenefitForm from "./hooks";

/* 
	Individual Risk
*/
interface iProps {
  cancelAction: () => void,
  handleSubmit: (event: React.FormEvent<HTMLFormElement>, data:any) => Promise<void>,
  initialValues?: {
    name: string    
    benefitHtml: string    
  },
  loading: boolean
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  button:{ 
    marginTop: 25, 
    marginRight: 25 
  },
	cardBody: {
		display: "flex",
		top: 0
	},
	cArea: {
		flex: "1",
		paddingTop: 0,
		position: "relative"
	},
	cAreaContent: {
		position: "sticky",
		top: 15
	},
	form: {
    width: '100%',
		position: "sticky",
		top: 15
  },
  name:{
    width: "100%"
  },
	titleBar: {
		cursor: "pointer",
	}
}));

const BenefitForm = ({ cancelAction, handleSubmit, initialValues, loading }: iProps) => {

	const { benefitHtml, name, setBenefitHtml, setName } = useBenefitForm(initialValues);
  const css = useStyles();

	return (    
    <Grid container>
      <form onSubmit={(event:any) => handleSubmit(event, {name, content: benefitHtml})} className={css.form}>
        <Grid item xs={12}>
          <TextField
            className={css.name}
            disabled={loading}
            size="medium"
            label="Name"
            value={name}
            onChange={(event: any) => setName(event.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <AceEditor
            wrapEnabled={true}
            readOnly={loading}
            style={{ margin: "15px 0" }}
            name="benefit"
            mode="html"
            width="100%"
            height="300px"
            placeholder="Enter benefit HTML content..."
            theme="monokai"
            onChange={(value: string) => setBenefitHtml(value)}
            value={benefitHtml}
            fontSize={14}
            showPrintMargin={false}
            showGutter={true}
            highlightActiveLine={true}
            setOptions={{
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              enableSnippets: true,
              showLineNumbers: true,
              tabSize: 2
            }}
          />
        </Grid>
        <Grid container item xs={6}>
          <Button
            disabled={loading}
            className={css.button}
            onClick={cancelAction}
            type="button"
            variant="contained"
            color="default">
            Cancel
          </Button>

          <Button 
            disabled={loading}
            className={css.button}
            type="submit"
            color="primary"
            variant="contained"
          >
            Save Benefit {loading && <CircularProgress style={{ marginLeft: 15 }} size={15} />}
          </Button>
        </Grid>
      </form>
    </Grid>
	);
}

export default BenefitForm;