import React, { useState } from "react";
import LoggedInTemplate from "../templates/LoggedIn";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import PopConfirm from "../PopConfirm";
import { PatientForm } from ".";

const PatientAdd = () => {
	const history = useHistory();
	const [abort, setAbort] = useState<boolean>(false);

	return (
		<LoggedInTemplate title="Add Patient">
			<PopConfirm
				title="Are you sure?"
				message="Any unsaved patient data you have entered will be lost."
				show={abort}
				handleClose={() => setAbort(false)}
				continueAction={() => history.push("/patients")}
			/>

			<Grid container spacing={3}>
				<Grid item lg={6}>
					<PatientForm setAbort={(v: boolean) => setAbort(v)} />
				</Grid>
			</Grid>
		</LoggedInTemplate>
	);
}

export default PatientAdd;