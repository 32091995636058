import React, { useCallback, useState } from "react";
import { Button, createStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles, Slider, Theme, Typography } from "@material-ui/core";
import getCroppedImg from "./createCroppedImage";
import Cropper from "react-easy-crop";
import { apiInstance } from "../../../common/api";
import useAPINotification from "../../../common/hooks/useAPINotification";
import { APINotificationActionTypes } from "../../../common/providers/APINotification/enums/APINotificationActionType.enum";
import { Severity } from "../../../common/enums/severity.enum";

const useStyles = makeStyles((theme: Theme) => createStyles({
	cropContainer: {
		position: "relative",
		width: 400,
		height: 400,
	},
	cropArea: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: "80px"
	},
	controls: {
		margin: "0 auto",
		width: "300px",
		bottom: "-320px"
	},
	slider: {
		padding: "22px 0px"
	},
	sliderLabel: {
		position: "relative",
		bottom: "-330px",
		fontSize: "14px",
		width: "100%",
		textAlign: "center",
	}
}));

interface Props {
	show: boolean;
	closeModal: () => void;
	img: string;
	setImg: (img: string) => void;
	setAvatar: (blob: any) => void;
	userId: string;
}

const CropImage = ({ show, closeModal, img, setImg, setAvatar, userId }: Props) => {
	const css = useStyles();

	const { dispatchNotification } = useAPINotification();
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState<any>(1);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

	const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels(croppedAreaPixels);
	}, []);

	const getCroppedImage = useCallback(async () => {
		try {
			const processedImage = await getCroppedImg(img, croppedAreaPixels, 0);
			closeModal();

			processedImage.toBlob(async (file: any) => {
				setImg(URL.createObjectURL(file));

				const formData = new FormData();
				formData.append("file", file as any, `avatar-${new Date().getTime()}`);
				formData.append("type", "avatar");
				formData.append("folder", "avatars");
				formData.append("isPublic", "1");
				formData.append("userId", userId);


				const res = await apiInstance.post("/resources", formData);

				setAvatar({ src: res.data.src, userId });
			});

		} catch (e) {
			console.error(e)
			dispatchNotification({ type: APINotificationActionTypes.SET_NOTIFICATION, payload: { message: "Error trying to create resource", severity: Severity.ERROR } });
		}
	}, [closeModal, croppedAreaPixels, dispatchNotification, img, setAvatar, setImg, userId]);

	return (
		<Dialog
			maxWidth="xs"
			open={show}
			onClose={closeModal}
			aria-labelledby="Image Crop"
			aria-describedby="Crop your profile picture">
			<DialogTitle id="Image Crop">
				Upload Profile Picture
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="Crop your profile picture">
					This image is used within patient communications.
				</DialogContentText>
				<div className={css.cropContainer}>
					<div className={css.cropArea}>
						<Cropper
							image={img}
							crop={crop}
							zoom={zoom}
							aspect={8 / 8}
							cropShape="round"
							showGrid={true}
							onCropChange={setCrop}
							onCropComplete={onCropComplete}
							onZoomChange={setZoom}
						/>
						<div className={css.controls}>
							<Typography className={css.sliderLabel} gutterBottom>
								Zoom
							</Typography>
							<Slider
								value={zoom}
								min={1}
								max={3}
								step={0.1}
								aria-labelledby="Zoom"
								onChange={(e, zoom) => setZoom(zoom)}
								classes={{ root: css.controls }}
							/>
						</div>
					</div>
				</div>
			</DialogContent>
			<DialogActions>
				<Button onClick={closeModal} color="primary">
					Cancel
				</Button>
				<Button onClick={getCroppedImage} variant="contained" color="primary" autoFocus>
					Upload
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default CropImage;