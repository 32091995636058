import { Grid } from "@material-ui/core";
import React from "react";
import { useParams } from "react-router-dom";
import LoggedInTemplate from "../templates/LoggedIn";
import NotFound from "../NotFound";
import { useFetchPatient } from "./hooks";
import { PatientOverview, PatientApplications } from ".";

interface Params {
	patientId: string;
}

const PatientView = () => {
	const { patientId } = useParams<Params>();
	const { patient, patientNotFound } = useFetchPatient(patientId);

	if (patientNotFound) {
		return <NotFound />;
	}

	return (
		<LoggedInTemplate title="Patient Overview">
			<Grid container spacing={3}>
				<Grid item lg={3}>
					<PatientOverview patient={patient} />
				</Grid>
				<Grid item lg={9}>
					<PatientApplications patient={patient} />
				</Grid>
			</Grid>
		</LoggedInTemplate>
	);
}

export default PatientView;