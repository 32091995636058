import React, { useState, ChangeEvent, ReactNode } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Paper, CircularProgress, Link } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import EmptyTemplate from "../../components/templates/Empty";

import useFetchCSRF from "../../common/hooks/useFetchCSRF";
import { Link as RouterLink, useLocation } from "react-router-dom";
import queryString from "query-string";
import { apiInstance } from "../../common/api";

const useStyles = makeStyles((theme) => ({
  bg: {
    height: "100vh",
    boxSizing: "border-box",
    paddingTop: 50,
    background: `linear-gradient(90deg, rgba(25,103,113,1) 0%, rgba(80,169,180,1) 100%)`,
    fallbacks: {
      background: theme.palette.primary.main,
    },
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 25,
    boxSizing: "border-box",
  },
  form: {
    width: "100%",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  alert: {
    alignSelf: "normal",
  },
}));

const NewPassword = () => {
  const classes = useStyles();
  const location = useLocation();
  const { CSRF } = useFetchCSRF();

  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<null | string | ReactNode>(null);
  const [values, setValues] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  function onFieldChange(event: ChangeEvent<any>) {
    const { name, value }: { name: string; value: string } = event.target;
    setValues((values) => ({ ...values, [name]: value }));
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    const { newPassword, confirmPassword } = values;
    const { token } = queryString.parse(location.search);

    try {
      // Min 8 length, 1 lowercase, 1 uppercase, 1 digit, allows special chars
      const strongPwRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

      if (newPassword !== confirmPassword) {
        throw new Error("Passwords do not match");
      }

      if (!newPassword.match(strongPwRegex)) {
        throw new Error("Password doesn't meet criteria");
      }

      const res = await apiInstance.patch("/auth/reset-password", {
        newPassword,
        token,
        type: "user",
      });
      if (res.status === 200 || res.status === 201) {
        setSuccess(true);
      }
    } catch (error: any) {
      console.log(error);
      // Handle errors
      const err =
        (error.response && error.response.data.message) || error.message;
      switch (err) {
        case "Passwords do not match":
          setError("Passwords do not match.");
          break;
        case "Password doesn't meet criteria":
          setError(
            "Password must contain at least 8 characters, 1 lowercase letter, 1 uppercase letter and 1 digit."
          );
          break;
        case "Password reset token is invalid or has expired.":
          setError(
            <span>
              This password reset token has expired. Please request a{" "}
              <Link component={RouterLink} to="/reset-password">
                new password reset
              </Link>
              .
            </span>
          );
          break;
        case "Invalid CSRF token":
          setError("Expired token. Please refresh the page and try again.");
          break;
        default:
          setError("Unexpected server error. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  const isLoading = loading || CSRF.loading;

  return (
    <EmptyTemplate title="New password" className={classes.bg}>
      <Container component="div" maxWidth="sm">
        <Paper className={classes.paper}>
          <img
            src="/images/Medical_Consent_Logo_Light.png"
            alt="Medical Consent"
            width={140}
          />
          <Box m={2}>
            <Typography component="h1" variant="h5">
              New password
            </Typography>
          </Box>

          {!success && (
            <Box mt={1} mb={1} color="text.secondary">
              <Typography variant="body2">
                Please enter a password in the inputs below to set a new
                password, it should contain at least 8 characters, 1 lowercase
                letter, 1 uppercase letter and 1 digit.
              </Typography>
            </Box>
          )}

          {!success && (
            <form className={classes.form} onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                inputProps={{ minLength: 8 }}
                name="newPassword"
                label="New password"
                type="password"
                id="password"
                autoComplete="New password"
                value={values.newPassword}
                onChange={onFieldChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                inputProps={{ minLength: 8 }}
                name="confirmPassword"
                label="Confirm password"
                type="password"
                id="confirmPassword"
                autoComplete="Confirm password"
                value={values.confirmPassword}
                onChange={onFieldChange}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isLoading}
                className={classes.submit}
              >
                {isLoading ? (
                  <CircularProgress size={25} color="primary" />
                ) : (
                  "Reset Password"
                )}
              </Button>
            </form>
          )}

          {success && (
            <Alert className={classes.alert} severity="success">
              Your password has been successfully reset. You may now{" "}
              <Link component={RouterLink} to="/login" variant="body2">
                Login
              </Link>
            </Alert>
          )}

          {error && (
            <Alert className={classes.alert} severity="error">
              {error}
            </Alert>
          )}
        </Paper>
      </Container>
    </EmptyTemplate>
  );
};

export default NewPassword;
