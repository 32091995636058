import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import EmptyTemplate from './templates/Empty';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	bg: {
		height: "100vh",
		boxSizing: "border-box",
		display: "flex",
		background: `linear-gradient(90deg, rgba(25,103,113,1) 0%, rgba(80,169,180,1) 100%)`,
		fallbacks: {
			background: theme.palette.primary.main,
		}
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: 25,
		boxSizing: "border-box",
		margin: "auto"
	}
}));

const LoadingSplashScreen = () => {
	const classes = useStyles();

	return (
		<EmptyTemplate title="Login" className={classes.bg}>
			<div className={classes.container}>
				<CircularProgress style={{ marginBottom: 15, color: "#fff" }} />
				<Typography style={{ color: "#fff", fontWeight: 400 }}>Loading... one moment</Typography>
			</div>
		</EmptyTemplate>
	);
}

export default LoadingSplashScreen;