import { ChangeEvent, useEffect, useState } from "react";
import { Speciality } from "../../../../models";

export default function useSpecialityForm(speciality: Speciality | undefined) {

  const [name, setName] = useState<string>("");

  useEffect(() => {

    if(speciality){

      setName(speciality.name);
    }
  }, [speciality]);

	function onFieldChange(event: ChangeEvent<HTMLInputElement>) {
		setName(event.target.value);
	}

	return {    
    name,    
    onFieldChange
	}
}