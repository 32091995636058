import React, { ReactNode } from "react";
import useGlobalStateProvider from "./hooks/use-global-state-provider";
import { GlobalStateContext } from './global-state.context';

interface Props {	
	children: ReactNode;
}

const GlobalStateProvider = ({ children }: Props) => {

	const { globalState, dispatchToGlobal } = useGlobalStateProvider();

	return (
		<GlobalStateContext.Provider value={{ globalState, dispatchToGlobal }}>
			{children}
		</GlobalStateContext.Provider>
	);
}

export default GlobalStateProvider;