import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { drawerWidth } from "../../constants";

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
    announcement: {
      color: 'khaki'
    },
		drawer: {
			width: drawerWidth,
			flexShrink: 0,
		},
		drawerPaper: {
			width: drawerWidth,
		},
		iconRoot: {
			color: "rgb(255 255 255 / 50%)"
		},
		menuItem: {
			color: "#fff",
			primary: {
				'&$selected': {
					backgroundColor: `${theme.palette.secondary.main}`
				}
			},
		},
		menuText: {
			fontWeight: 500,
		},
		nested: {
			paddingLeft: theme.spacing(4),
		},
		logo: {
			width: 225,
			padding: "4px 15px",
			margin: 8
		},
	}),
);
