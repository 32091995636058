import React, { useContext, useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core";
import ReactDOM from "react-dom";
import Frequency from "./Frequency";
import { GlobalStateContext } from "../../common/providers/global-state-provider/global-state.context";

const useStyles = makeStyles((theme: Theme) => createStyles({
	riskContent: {
		background: theme.palette.background.default,
		padding: 10,
		boxSizing: "border-box",
	},
}));

interface Props {
	html: string;
	riskId: string;
}

const Content = ({ html, riskId }: Props) => {
	const { globalState } = useContext(GlobalStateContext);

	const css = useStyles();

	useEffect(() => {
		// Once the page has rendered with the container
		// insert the Frequency component in place where
		// the frequency should be within the HTML content
		const paramContainer = document.getElementById('frequency-' + riskId);
		if (paramContainer) {
			ReactDOM.render(<Frequency showPatientInfo={true} frequency={globalState.params_currentFreq} />, paramContainer);
		}
	}, [riskId, globalState.params_currentFreq]);

	const renderHTML = (html: string) => {
		return (html = html.replace(
			"{frequency}",
			`<span id="frequency-${riskId}"></span>` // Container the Frequency component renders into
		));
	};

	return (
		<article className={css.riskContent} dangerouslySetInnerHTML={{ __html: renderHTML(html) }} />
	);
}

export default Content;