import React from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Divider, Typography } from "@material-ui/core";
import { Topic } from "../../../models";
import useTopicItem from "./hooks";
import Interactions from "../Interactions";
import TopicQuestions from "../TopicQuestions";
import FlagForm from "../FlagForm";
import { TopicType } from "../../../common/enums/topic-type.enum";

interface Props {
  topic: Topic
  type: TopicType;
}

const TopicItem = ({ topic, type }: Props) => {
  
  const { answer, hideThisItem, modified } = useTopicItem(topic);
  
  return hideThisItem 
    ? null
    : (
        <Alert severity={ !answer || modified() ? "error" : (answer.understood ? "success" : "warning")} style={{ margin: "10px 0" }}>
          <AlertTitle>{topic.name}</AlertTitle>      
          <Typography variant="body2">
            {
              answer 
              ? answer.understood
                ? "The patient has indicated that they fully understand this topic"
                : "The patient has indicated that they do not yet fully understand this topic"
              : "The patient has not answered yet"
            }
          </Typography>
          {
            modified()
            ? <Typography variant="body2">
                <br/>
                This topic has been modified after the patient answered it. The patient should review the new content in order to continue the consenting process.
              </Typography>
            : null
          }
          <Divider light style={{ margin: "15px 0" }} />
          {
            answer
              ? <Interactions answerId={answer.id} />
              : null
          }
          <TopicQuestions topicId={topic.id} />
          <FlagForm topicId={topic.id} topicType={type} />
        </Alert>
      );
}

export default TopicItem;