import {
  Button,
  CircularProgress,
  createStyles,
  FormControl,
  Grid,
  Input,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
  useTheme,
} from "@material-ui/core";
import clsx from "clsx";
import React, { useContext } from "react";
import { ProceduresContext } from "../hooks/procedures.context";
import useProcedureForm from "./hooks";

interface iProps {
  edition?: boolean;
  cancelAction: () => void;
  handleSubmit: (
    event: React.FormEvent<HTMLFormElement>,
    data: any
  ) => Promise<void>;
  initialValues?: {
    category: string;
    benefits: string[];
    considerations: string[];
    name: string;
    risks: string[];
    specialityId: string;
    subSpecialityId: string;
    subProcedureText: string;
  };
  loading: boolean;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(id: string, selectedItems: string[], theme: Theme) {
  return {
    fontWeight: selectedItems.includes(id)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      position: "sticky",
      top: 15,
    },
    hide: {
      display: "none",
    },
    name: {
      marginRight: 24,
    },
    subProcedureText: {
      width: "100%"
    }
  })
);

const ProcedureForm = ({
  cancelAction,
  edition,
  handleSubmit,
  initialValues,
  loading,
}: iProps) => {
  const { state } = useContext(ProceduresContext);
  const {
    category,
    name,
    selectedBenefits,
    selectedConsiderations,
    selectedRisks,
    selectedSpeciality,
    selectedSubSpeciality,
    subProcedureText,
    handleChange,
  } = useProcedureForm(initialValues);

  const subSpecialities = state.specialities.find(
    (speciality) => speciality.id === selectedSpeciality
  )?.subSpecialities;

  const css = useStyles();
  const theme = useTheme();

  return (
    <form
      onSubmit={(event) =>
        handleSubmit(event, {
          name,
          procedureCategoryId: category,
          benefits: selectedBenefits.map((benefitId: string) => ({
            id: benefitId,
          })),
          considerations: selectedConsiderations.map(
            (considerationId: string) => ({ id: considerationId })
          ),
          risks: selectedRisks.map((riskId: string) => ({ id: riskId })),
          specialityId: selectedSpeciality,
          subSpecialityId: selectedSubSpeciality,
          subProcedureText: subProcedureText !== "" ? subProcedureText : null
        })
      }
      className={css.root}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} className={clsx(!edition && css.hide)}>
          <FormControl
            disabled={loading}
            className={clsx(css.name, !edition && css.hide)}
          >
            <TextField
              disabled={loading}
              autoFocus
              onChange={handleChange}
              name="name"
              label="Name"
              value={name}
            />
          </FormControl>
          <FormControl
            disabled={loading || !edition}
            className={clsx(!edition && css.hide)}
          >
            <InputLabel id="speciality-selector-label">Category</InputLabel>
            <Select
              labelId="category-label"
              id="category"
              name="category"
              value={category}
              onChange={handleChange}
              MenuProps={MenuProps}
            >
              {state.procedureCategories.map((procedureCategory) => (
                <MenuItem
                  key={procedureCategory.name}
                  value={procedureCategory.id}
                >
                  {procedureCategory.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl disabled={loading || !edition} fullWidth>
            <InputLabel id="speciality-selector-label">Speciality</InputLabel>
            <Select
              labelId="speciality-label"
              id="speciality"
              name="speciality"
              value={selectedSpeciality}
              onChange={handleChange}
              MenuProps={MenuProps}
            >
              {state.specialities.map((speciality) => (
                <MenuItem key={speciality.name} value={speciality.id}>
                  {speciality.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl disabled={loading || !edition} required fullWidth>
            <InputLabel id="sub-speciality-selector-label">
              Sub-Speciality
            </InputLabel>
            <Select
              labelId="sub-speciality"
              id="sub-speciality"
              name="sub-speciality"
              value={selectedSubSpeciality}
              onChange={handleChange}
              MenuProps={MenuProps}
            >
              {subSpecialities &&
                subSpecialities.map((subSpeciality) => (
                  <MenuItem key={subSpeciality.name} value={subSpeciality.id}>
                    {subSpeciality.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl disabled={loading || !edition} required fullWidth>
            <InputLabel id="risks-selector-label">Risks</InputLabel>
            <Select
              labelId="risks-label"
              id="risks"
              multiple
              name="risks"
              value={selectedRisks}
              onChange={handleChange}
              input={<Input />}
              MenuProps={MenuProps}
            >
              {state.risks.map((risk) => (
                <MenuItem
                  key={risk.name}
                  value={risk.id}
                  style={getStyles(risk.id, selectedRisks, theme)}
                >
                  {risk.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl required fullWidth disabled={loading || !edition}>
            <InputLabel id="benefits-selector-label">Benefits</InputLabel>
            <Select
              labelId="benefits-label"
              id="benefits"
              multiple
              name="benefits"
              value={selectedBenefits}
              onChange={handleChange}
              input={<Input />}
              MenuProps={MenuProps}
            >
              {state.benefits.map((benefit) => (
                <MenuItem
                  key={benefit.name}
                  value={benefit.id}
                  style={getStyles(benefit.id, selectedBenefits, theme)}
                >
                  {benefit.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl required fullWidth disabled={loading || !edition}>
            <InputLabel id="benefits-selector-label">Considerations</InputLabel>
            <Select
              labelId="considerations-label"
              id="considerations"
              multiple
              name="considerations"
              value={selectedConsiderations}
              onChange={handleChange}
              input={<Input />}
              MenuProps={MenuProps}
            >
              {state.considerations.map((consideration) => (
                <MenuItem
                  key={consideration.name}
                  value={consideration.id}
                  style={getStyles(
                    consideration.id,
                    selectedConsiderations,
                    theme
                  )}
                >
                  {consideration.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl
            className={clsx(css.subProcedureText)}
          >
            <TextField
              disabled={loading || !edition}
              autoFocus
              onChange={handleChange}
              name="subProcedureText"
              label="Text as sub-procedure"
              value={subProcedureText}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} className={clsx(!edition && css.hide)}>
          <Button
            disabled={loading}
            style={{ marginTop: 25, marginRight: 25 }}
            onClick={cancelAction}
            type="button"
            variant="contained"
            color="default"
          >
            Cancel
          </Button>

          <Button
            disabled={loading}
            style={{ marginTop: 25 }}
            type="submit"
            variant="contained"
            color="primary"
          >
            Save Procedure
            {loading && (
              <CircularProgress style={{ marginLeft: 15 }} size={15} />
            )}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ProcedureForm;
