import { useCallback, useContext, useEffect, useState } from "react"
import { apiInstance } from "../../../../common/api";
import { Severity } from "../../../../common/enums/severity.enum";
import { APINotificationContext } from "../../../../common/providers/APINotification/APINotification.context";
import { APINotificationActionTypes } from "../../../../common/providers/APINotification/enums/APINotificationActionType.enum";

import { QualityAssurance } from "../../../../models";

export default function useQualityAssurances() {
  
  const [ qualityAssurances, setQualityAssurances ] = useState<QualityAssurance[]>([]);  
  const { dispatchNotification } = useContext(APINotificationContext);

  const fetchQualityAssurances = useCallback(async () =>{

    try{

      const res = await apiInstance.get(`/quality-assurances`);

      if(res.status === 200){
        setQualityAssurances(res.data);
      }
    } catch (err){
      dispatchNotification({type: APINotificationActionTypes.SET_NOTIFICATION, payload: { message: "Error trying to get Quality Assurances", severity: Severity.ERROR }})
    }
  },[dispatchNotification]);

  useEffect(() => {
    
    fetchQualityAssurances();    
  }, [fetchQualityAssurances]);

  return {
    qualityAssurances,
  }
}