import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useLoginStyles = makeStyles((theme: Theme) =>
  createStyles({
    bg: {
      height: "100vh",
      boxSizing: "border-box",
      paddingTop: 50,
      background: `linear-gradient(90deg, rgba(25,103,113,1) 0%, rgba(80,169,180,1) 100%)`,
      fallbacks: {
        background: theme.palette.primary.main,
      }
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: 25,
      boxSizing: "border-box",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%'
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }),
);