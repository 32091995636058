import { useEffect, useState } from "react";
import { apiInstance } from "../../../common/api";
import { Severity } from "../../../common/enums/severity.enum";
import useAPINotification from "../../../common/hooks/useAPINotification";
import { APINotificationActionTypes } from "../../../common/providers/APINotification/enums/APINotificationActionType.enum";

import { Risk } from "../../../models";

export default function useRisksReview() {
	const { dispatchNotification } = useAPINotification()
	const [loading, setLoading] = useState<boolean>(false);
	const [risks, setRisks] = useState<Risk[] | null>(null);


	useEffect(() => {
		const fetchRisks = async () => {
			try {
				setLoading(true);
				const res = await apiInstance.get("/risks?join=riskFrequencies&join=procedures&join=procedures.procedureCategory");
				if (res.data) {
					setRisks(res.data);
				}
			} catch (error) {
				dispatchNotification({ type: APINotificationActionTypes.SET_NOTIFICATION, payload: { message: "Error trying to get risks.", severity: Severity.ERROR } });
			} finally {
				setLoading(false);
			}
		}

		fetchRisks();
	}, [dispatchNotification]);

	return {
		risks,
		loading
	}
}