import { MenuItem, Select } from "@material-ui/core";
import React, { useState } from "react";
import { ConsentFormStatus } from "../../../common/enums/consent-form-status.enum";
import { useFetchConsultants } from "../../patients/hooks";

interface TableFilterProps {
  field: string;
  handleFilter: (filterBy: { field: string; value: string[] }) => void;
  selectedStatusVals: string[];
  setSelectedStatusVals: React.Dispatch<React.SetStateAction<string[]>>;
  selectedUserVals: string[];
  setSelectedUserVals: React.Dispatch<React.SetStateAction<string[]>>;
}

const TableFilter = ({
  field,
  handleFilter,
  selectedStatusVals,
  setSelectedStatusVals,
  selectedUserVals,
  setSelectedUserVals,
}: TableFilterProps) => {
  const { clinicConsultants } = useFetchConsultants();

  //Handles changes for the status dropdown list
  async function handleStatusChange(e: any) {
    let newStatusVals = e.target.value;
    let shouldExecuteAdd: boolean = true;
    const recentAdded: string = newStatusVals[newStatusVals.length - 1];
    //If user selects "All" when statuses are already added or If the last status is removed
    if (
      (!selectedStatusVals.includes("All") && newStatusVals.includes("All")) ||
      (selectedStatusVals.length === 1 && newStatusVals.length === 0)
    ) {
      setSelectedStatusVals(["All"]);
      newStatusVals = ["All"];
      shouldExecuteAdd = false;
    }

    //Remove All from the filter
    if (selectedStatusVals.includes("All") && recentAdded !== "All") {
      newStatusVals = newStatusVals.filter((v: string) => v !== "All");
      setSelectedStatusVals(newStatusVals);
      shouldExecuteAdd = false;
    }

    //Add value
    if (shouldExecuteAdd) {
      setSelectedStatusVals(newStatusVals);
    }

    handleFilter({ field, value: newStatusVals });
  }

  async function handleUserChange(e: any) {
    let newStatusVals = e.target.value;
    let shouldExecuteAdd: boolean = true;
    const recentAdded: string = newStatusVals[newStatusVals.length - 1];
    //If user selects "All" when statuses are already added or If the last status is removed
    if (
      (!selectedUserVals.includes("All") && newStatusVals.includes("All")) ||
      (selectedUserVals.length === 1 && newStatusVals.length === 0)
    ) {
      setSelectedUserVals(["All"]);
      newStatusVals = ["All"];
      shouldExecuteAdd = false;
    }

    //Remove All from the filter
    if (selectedUserVals.includes("All") && recentAdded !== "All") {
      newStatusVals = newStatusVals.filter((v: string) => v !== "All");
      setSelectedUserVals(newStatusVals);
      shouldExecuteAdd = false;
    }

    //Add value
    if (shouldExecuteAdd) {
      setSelectedUserVals(newStatusVals);
    }

    handleFilter({ field, value: newStatusVals });
  }

  const options =
    field === "status" ? (
      <Select
        multiple
        value={selectedStatusVals}
        onChange={handleStatusChange}
        style={{ width: "8rem" }}
      >
        <MenuItem value={"All"} disabled={selectedStatusVals.includes("All")}>
          All
        </MenuItem>
        <MenuItem value={ConsentFormStatus.COMPLETE}>
          {ConsentFormStatus.COMPLETE}
        </MenuItem>
        <MenuItem value={ConsentFormStatus.IN_PROGRESS}>
          {ConsentFormStatus.IN_PROGRESS}
        </MenuItem>
        <MenuItem value={ConsentFormStatus.NOT_STARTED}>
          {ConsentFormStatus.NOT_STARTED}
        </MenuItem>
        <MenuItem value={ConsentFormStatus.PENDING_CONSULTANT_SIGNATURE}>
          {ConsentFormStatus.PENDING_CONSULTANT_SIGNATURE}
        </MenuItem>
        <MenuItem value={ConsentFormStatus.PENDING_CONSULTANT_SUBMISSION}>
          {ConsentFormStatus.PENDING_CONSULTANT_SUBMISSION}
        </MenuItem>
        <MenuItem value={ConsentFormStatus.PENDING_PATIENT_SIGNATURE}>
          {ConsentFormStatus.PENDING_PATIENT_SIGNATURE}
        </MenuItem>
        <MenuItem value={ConsentFormStatus.PENDING_PATIENT_SUBMISSION}>
          {ConsentFormStatus.PENDING_PATIENT_SUBMISSION}
        </MenuItem>
        <MenuItem value={ConsentFormStatus.PENDING_QUERIES}>
          {ConsentFormStatus.PENDING_QUERIES}
        </MenuItem>
        <MenuItem value={ConsentFormStatus.QA_NOT_UNDERSTOOD}>
          {ConsentFormStatus.QA_NOT_UNDERSTOOD}
        </MenuItem>
      </Select>
    ) : (
      <Select
        multiple
        value={selectedUserVals}
        onChange={handleUserChange}
        style={{ width: "8rem" }}
      >
        <MenuItem value={"All"} disabled={selectedUserVals.includes("All")}>
          All
        </MenuItem>
        {clinicConsultants.map((consultant) => (
          <MenuItem key={consultant.id} value={consultant.id}>
            {`${consultant.title} ${consultant.firstName} ${consultant.lastName}`}
          </MenuItem>
        ))}
      </Select>
    );

  return <>{options}</>;
};

export default TableFilter;
