import { Button, CircularProgress, createStyles, FormControl, Grid, makeStyles, TextField, Theme } from "@material-ui/core";
import React, {  } from "react";
import { Speciality } from "../../../models";
import useSpecialityForm from "./hooks";

interface Props {	
  data?: Speciality;
	cancelAction: () => void;
	deleteAction?:  () => void;
	handleSubmit: (event: React.FormEvent<HTMLFormElement>, data:any) => Promise<void>;
	loading: boolean;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  form: {
		width: "100%"
	}
}));

const SpecialityForm = ({ data, cancelAction, deleteAction, handleSubmit, loading }: Props) => {

	const { name, onFieldChange } = useSpecialityForm(data);
	const css = useStyles();
  
	return (
		<form onSubmit={(event) => handleSubmit(event, {name})} className={css.form} >
			<Grid container spacing={3}>
				<Grid item lg={8}>
          <FormControl fullWidth margin="normal">
            <TextField
              autoFocus
              required
              onChange={onFieldChange}
              name="name"
              label="Name"
              value={name}
            />
          </FormControl>
				</Grid>

				<Grid item lg={4}>

					{
						deleteAction
						? (
							<Button
								style={{ marginTop: 25, marginRight: 25 }}
								type="button"
								onClick={deleteAction}
								disabled={loading}
								variant="contained"
								color="secondary">
								Delete
								{loading && <CircularProgress style={{ marginLeft: 15 }} size={15} />}
							</Button>
						)
						: null
					}

					<Button
						style={{ marginTop: 25, marginRight: 25 }}
						onClick={cancelAction}
						type="button"
						variant="contained"
						color="default">
						Cancel
					</Button>

					<Button
						style={{ marginTop: 25 }}
						type="submit"
						disabled={loading}
						variant="contained"
						color="primary">
						Save
            {loading && <CircularProgress style={{ marginLeft: 15 }} size={15} />}
					</Button>

				</Grid>
			</Grid>
		</form>
	);
}

export default SpecialityForm;
