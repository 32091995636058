import { RequestQueryBuilder } from "@nestjsx/crud-request";
import { useContext, useEffect, useState } from "react"
import { apiInstance } from "../../../../common/api";
import { Severity } from "../../../../common/enums/severity.enum";
import useAPINotification from "../../../../common/hooks/useAPINotification";
import { APINotificationActionTypes } from "../../../../common/providers/APINotification/enums/APINotificationActionType.enum";
import { ConsentFormContext } from "../../../../common/providers/ConsentFormProvider/ConsentForm.context";
import { ConsentFormProviderActionType } from "../../../../common/providers/ConsentFormProvider/enums/ConsentFormProviderActionType.enum";
import { Answer, Question } from "../../../../models";

interface Props {
  question: Question;
}

export default function useTopicQuestionItem({ question }: Props) {
  
  const { dispatchNotification } = useAPINotification();
  const { consentForm, dispatch } = useContext(ConsentFormContext);

  const [ answer, setAnswer ] = useState<Answer>();

  useEffect(() => {

    const fetchAnswer = async () => {
      try {

        const qb = RequestQueryBuilder.create();
				qb.search({
					$and:[
						{consentFormId: consentForm.id},
						{questionId: question.id}
					] 
				})
				.query();

				const res = await apiInstance.get(`/answers?${qb.queryString}`);
				
				if (res.status === 200 && res.data.length > 0) {
					
					dispatch({ type: ConsentFormProviderActionType.ADD_ANSWER, payload:res.data[0] })
				}
      } catch (err) {

        console.log(err);
        dispatchNotification({ type: APINotificationActionTypes.SET_NOTIFICATION, payload: { message: "Error trying to get the answer", severity: Severity.ERROR }});  
      }
    }

    if(consentForm.id !== "" && question.id !== ""){

			const answer = consentForm.answers.find(answer => answer.questionId === question.id);

			answer ? setAnswer(answer) : fetchAnswer();
		}		
  }, [consentForm.id, consentForm.answers, dispatch, dispatchNotification, question.id]);

	return {
    answer
	}
}