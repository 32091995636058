import { Box, Fade } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

const Loading = () => {
	return (
		<Fade in={true} timeout={1500}>
			<Box mt={2}>
				<Skeleton animation="wave" width="100%" height={35} />
				<Skeleton animation="wave" width="100%" height={35} />
				<Skeleton animation="wave" width="100%" height={35} />
				<Skeleton animation="wave" width="100%" height={35} />
				<Skeleton animation="wave" width="100%" height={35} />
			</Box>
		</Fade>
	);
}

export default Loading;