import React, { useContext, useState } from "react";
import { FormControl, makeStyles, createStyles, Theme, Select, MenuItem, InputLabel } from "@material-ui/core";
import Frequency from "./Frequency";

import { Procedure, RiskFrequency } from "../../models";
import { frequencyMapping } from "./frequency-mapping";
import { GlobalStateContext } from "../../common/providers/global-state-provider/global-state.context";
import { GlobalStateProviderActionType } from "../../common/providers/global-state-provider/enums/global-state-provider-action-type.enum";

const useStyles = makeStyles((theme: Theme) => createStyles({
	formControl: {
		minWidth: 250,
		margin: 4
	},
	select: {
		fontSize: 14,
		display: "flex",
		alignItems: "center"
	},
	menuItem: {
		fontSize: 14,
		display: "flex",
		alignItems: "center"
	},
	label: {
		fontSize: 14,
	},
}));

/* 
	Select list to choose risk frequency
*/
interface Props {
	procedure: Procedure;
	defaultValue?: RiskFrequency;
	paramValues: RiskFrequency[];
	setParamValues: (v: any) => void;
	riskId: string;
}

const SelectFrequency = ({ procedure, defaultValue, paramValues, setParamValues, riskId }: Props) => {
	const { dispatchToGlobal } = useContext(GlobalStateContext);
	const css = useStyles();

	const [val, setVal] = useState<string>((defaultValue && defaultValue.text) || "");

	const handleChange = (event: React.ChangeEvent<{ value: any }>) => {
		dispatchToGlobal({ type: GlobalStateProviderActionType.SET_PARAMS_CURRENT_PROCEDURE, payload: procedure.name });
		dispatchToGlobal({ type: GlobalStateProviderActionType.SET_PARAMS_CURRENT_FREQ, payload: event.target.value });

		const val = event.target.value as string;
		setVal(val);

		const newFreqVal = frequencyMapping[val] as any;

		const currentValues = paramValues;
		const paramToUpdate = currentValues.findIndex(param => param.procedureId === procedure.id);

		if (paramToUpdate !== -1) {
			currentValues[paramToUpdate].text = newFreqVal.text;
			currentValues[paramToUpdate].value = newFreqVal.value;
			currentValues[paramToUpdate].verbal = newFreqVal.verbal;
			setParamValues(currentValues);
		} else {
			const newVal = {
				value: newFreqVal.value,
				text: newFreqVal.text,
				verbal: newFreqVal.verbal,
				procedureId: procedure.id,
				riskId: riskId
			}

			setParamValues((value: any) => {
				return [...value, { ...newVal }];
			});
		}
	};

	return (
		<FormControl className={css.formControl}>
			{!val && <InputLabel margin="dense" className={css.label} id="risk-freq">Set Risk Frequency</InputLabel>}
			<Select
				value={val}
				name="frequency"
				onChange={handleChange}
				required
				classes={{ select: css.select }}
				labelId="risk-freq"
				id="risk-freq">
				<MenuItem aria-label="None" value="">Set Risk Frequency</MenuItem>
				<MenuItem className={css.menuItem} value="Very Rare"><Frequency showPatientInfo={false} frequency="Very Rare" /></MenuItem>
				<MenuItem className={css.menuItem} value="Rare"><Frequency showPatientInfo={false} frequency="Rare" /></MenuItem>
				<MenuItem className={css.menuItem} value="Uncommon"><Frequency showPatientInfo={false} frequency="Uncommon" /></MenuItem>
				<MenuItem className={css.menuItem} value="Common"><Frequency showPatientInfo={false} frequency="Common" /></MenuItem>
				<MenuItem className={css.menuItem} value="Very Common"><Frequency showPatientInfo={false} frequency="Very Common" /></MenuItem>				
			</Select>
		</FormControl>
	)
}

export default SelectFrequency;