import React from "react";
import { createStyles, IconButton, makeStyles, Theme, Typography } from "@material-ui/core";
import { CheckCircleOutline, ExpandLess, ExpandMore, Warning } from "@material-ui/icons";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => createStyles({
	titleBar: {
		cursor: "pointer",
	},
	title: {
		fontSize: 20,
		fontWeight: 500,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between"
	},
	titleArea: {
		display: "flex",
		alignItems: "center"
  },
  progressIcon: {
		marginRight: 10
	}
}));
/* 
	Risk Title 
*/
interface iTitle {
	text: string;
	completed?: boolean;
	collapsed: boolean;
}

const CardTitle = ({ text, completed, collapsed }: iTitle) => {
	const css = useStyles();

	return (    
    <Typography variant="h6" component="h2" className={css.title}>
      <div className={css.titleArea}>
        {
          completed !== undefined 
            ? completed 
              ? <CheckCircleOutline className={clsx(css.progressIcon, "risk-complete")} /> : <Warning className={clsx(css.progressIcon, "risk-warn")} />
            : null
        }
        {text}
        <IconButton>
          {collapsed ? <ExpandLess style={{ color: "rgba(0, 0, 0, 0.54)" }} /> : <ExpandMore style={{ color: "rgba(0, 0, 0, 0.54)" }} />}
        </IconButton>
      </div>
    </Typography>
	);
}

export default CardTitle;