import React from "react";
import { Button, Card, CardContent, CircularProgress, Collapse, createStyles, Grid, IconButton, List, ListItem, ListSubheader, makeStyles, Theme, Typography } from "@material-ui/core";
import { Speciality, SubSpeciality } from "../../../models";
import useSpecialityItem from "./hooks";
import clsx from "clsx";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import SubSpecialityForm from "../sub-speciality-form";
import SubSpecialityItem from "../sub-speciality-item";
import SpecialityForm from "../speciality-form";

interface iProps {
	data: Speciality;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  approveBtn: {
		background: theme.palette.primary.main,
		display: "block",
		margin: "25px 0 15px auto",
		'&:hover': {
			background: theme.palette.primary.dark
		}
	},
	buttons:{
		opacity: 0,
	},
  root: {
		borderTop: "2px solid",
		borderColor: "#c7c7c7",
		marginBottom: 15,
		overflow: "visible",
		'&:hover $buttons': {			
			opacity: 1,
		}
	},
  title: {
		borderColor: theme.palette.info.light,
		'& .risk-complete': {
			color: theme.palette.info.light
		}
  },
  titleBar: {
		cursor: "pointer",
  },
  titleArea: {
		display: "flex",
		alignItems: "center"
  },
  progressIcon: {
		marginRight: 10
  },
  cardBody: {
		display: "flex",
		top: 0
	},
	cArea: {
		flex: "1",
		paddingTop: 0,
		position: "relative"
	},
	cAreaContent: {
		position: "sticky",
		top: 15
	},
}));
const SpecialityItem = ({ data }: iProps) => {

	const { addNew, collapsed, editing, handleSubmit, handleSubSpecialitySubmit, isDeleted, isLoading, name, remove, subSpecialities, toggleCollapse, toggleAddNew, toggleEdition } = useSpecialityItem(data);
	const css = useStyles();

	if(isDeleted()){
		return null;
	}

	return (
    <Card className={clsx(css.root, css.title)}>
			<CardContent >
				<Grid container >
					<Grid item xs={10} >
						{
							editing()
								? (
									<SpecialityForm data={data} loading={isLoading()} handleSubmit={handleSubmit} cancelAction={toggleEdition} />
								)
								: (
									<Typography variant="h3" component="h3" className={clsx(css.title, css.titleBar)} onClick={()=> toggleCollapse() }>
										<div className={css.titleArea}>
											{name}
											<IconButton>
												{collapsed() ? <ExpandLess style={{ color: "rgba(0, 0, 0, 0.54)" }} /> : <ExpandMore style={{ color: "rgba(0, 0, 0, 0.54)" }} />}
											</IconButton>
										</div>
									</Typography>
								)
						}
					</Grid>
					<Grid item xs={2} className={css.buttons}>
						<Button
							style={{ marginRight: 25 }}
							type="button"
							onClick={remove}
							disabled={isLoading()}
							variant="contained"
							color="secondary">
							Delete
							{isLoading() && <CircularProgress style={{ marginLeft: 15 }} size={15} />}
						</Button>
						<Button
							type="button"
							onClick={toggleEdition}
							disabled={isLoading()}
							variant="contained"
							color="primary">
							Edit
						</Button>
					</Grid>
				</Grid>				

			</CardContent>
			<Collapse in={!collapsed()} timeout="auto" unmountOnExit>
				<div className={css.cardBody}>
					<CardContent className={css.cArea}>
						<List
							subheader={
								<ListSubheader disableSticky component="div" id="nested-list-subheader">
									Sub-Specialities
								</ListSubheader>
							}
						>
              {subSpecialities.map((subSpeciality: SubSpeciality) => (
								<ListItem key={subSpeciality.id}>									
									<Grid item xs={12}>
										<SubSpecialityItem data={subSpeciality} />
									</Grid>
								</ListItem>
							))}
            </List>
						{
							addNew() 
								? <SubSpecialityForm cancelAction={toggleAddNew} loading={isLoading()} handleSubmit={handleSubSpecialitySubmit} />
								: <Button fullWidth className={css.approveBtn} onClick={() => toggleAddNew()} color="primary" variant="contained">Add SubSpeciality</Button>
						}
					</CardContent>					
				</div>
			</Collapse>
		</Card>
	);
}

export default SpecialityItem;