import React, { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";
import LoggedInTemplate from "../../templates/LoggedIn";
import PopConfirm from "../../PopConfirm";
import ClinicForm from "../clinic-form";
import { GlobalStateContext } from "../../../common/providers/global-state-provider/global-state.context";
import useEditClinic from "./hooks/use-edit-clinic";

const EditClinic = () => {
  const { globalState } = useContext(GlobalStateContext);
  const { push } = useHistory();
  const { clinicId } = useParams<{ clinicId: string }>();
  const { abort, setAbort } = useEditClinic();

  return (
    <LoggedInTemplate title="Edit Clinic">
      <PopConfirm
        title="Are you sure?"
        message="Any unsaved data you have entered will be lost."
        show={abort}
        handleClose={() => setAbort(false)}
        continueAction={() => push("/clinics")}
      />

      <Grid container spacing={3}>
        <Grid item lg={8}>
          <ClinicForm
            setAbort={(v: boolean) => setAbort(v)}
            clinicId={clinicId}
          />
        </Grid>
      </Grid>
    </LoggedInTemplate>
  );
};

export default EditClinic;
