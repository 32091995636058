import React from "react";
import { format } from "date-fns";
import MaterialTable from "material-table";
import { useHistory } from "react-router-dom";
import { calcAge } from "../../../lib";
import { ConsentForm, Patient } from "../../../models";
import {
  Icon,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import { useApplicationsTableStyles } from "./applications-table.css";
import ApplicationsTableHead from "./ApplicationsTableHead";
import { useApplicationsTable } from "./hooks";
import clsx from "clsx";
import Status from "./Status";

interface UserProps {
  id: string;
  name: string;
}

interface Props {
  applications: ConsentForm[];
  handleFilter: (filterBy: { field: string; value: string[] }) => void;
  loading: boolean;
  searching: boolean;
  selectedStatusVals: string[];
  setSelectedStatusVals: React.Dispatch<React.SetStateAction<string[]>>;
  selectedUserVals: string[];
  setSelectedUserVals: React.Dispatch<React.SetStateAction<string[]>>;
}

const ApplicationsTable = ({
  applications,
  handleFilter,
  loading,
  searching,
  selectedStatusVals,
  setSelectedStatusVals,
  selectedUserVals,
  setSelectedUserVals,
}: Props) => {
  const history = useHistory();
  const classes = useApplicationsTableStyles();
  const {
    getComparator,
    handleChangePage,
    handleChangeRowsPerPage,
    handleRequestSort,
    order,
    orderBy,
    page,
    rowsPerPage,
    tableSort,
  } = useApplicationsTable();

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <ApplicationsTableHead
          classes={classes}
          columns={[
            { label: "Patient", property: "patient" },
            { label: "Procedure", property: "procedure" },
            { label: "Created at", property: "createdAt" },
            { label: "Consultant", property: "user" },
            { label: "Last updated", property: "updatedAt" },
            { label: "Status", property: "status" },
            { label: "Actions", property: "actions" },
          ]}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          onRequestFilter={handleFilter}
          selectedStatusVals={selectedStatusVals}
          setSelectedStatusVals={setSelectedStatusVals}
          selectedUserVals={selectedUserVals}
          setSelectedUserVals={setSelectedUserVals}
        />
        <TableBody>
          {tableSort(applications, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((application, index) => {
              return (
                <TableRow hover tabIndex={-1} key={application.id}>
                  <TableCell
                    className={classes.cell}
                    component="th"
                    scope="row"
                  >{`${application.patient.title} ${application.patient.firstName} ${application.patient.lastName}`}</TableCell>
                  <TableCell className={classes.cell}>
                    {application.procedure.name}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {format(new Date(application.createdAt), "dd/MM/yyyy")}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {`${application.user.title} ${application.user.firstName} ${application.user.lastName}`}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {format(new Date(application.updatedAt), "dd/MM/yyyy")}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    <Status text={application.status} />
                  </TableCell>
                  <TableCell
                    className={clsx(classes.cell, classes.actionsCell)}
                  >
                    <Visibility
                      className={classes.actionIcon}
                      onClick={() =>
                        history.push("/applications/" + application.id)
                      }
                    />
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[15, 25, 50]}
        component="div"
        count={applications.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

export default ApplicationsTable;
