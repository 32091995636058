import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useSubProcedureTextsStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      lineHeight: '25px',
      color: '#2594a2',
      borderBottom: '1px solid rgba(37, 148, 162, 0.5)',
      marginTop: '5px',
      marginBottom: 10,
      fontWeight: 'bold',
    },
    proceduresContainer: {
      padding: '0 15px',
    },
    procedure: {
      marginBottom: 20,
      padding: 10,
      border: '1px solid #bbb',
    }
  }),
);