import { FormEvent, useState } from "react"
import { apiInstance } from "../../../../../common/api";
import { Severity } from "../../../../../common/enums/severity.enum";
import useAPINotification from "../../../../../common/hooks/useAPINotification";
import { APINotificationActionTypes } from "../../../../../common/providers/APINotification/enums/APINotificationActionType.enum";
import { Question } from "../../../../../models";

interface Props {
  question: Question;
}

export default function useQuestionItem({ question }: Props) {
  
  const { dispatchNotification } = useAPINotification();

  const [ state, setState ] = useState<Question>(question);
  const [ showForm, setShowForm ] = useState<boolean>(false);
  const [ deleted, setDeleted ] = useState<boolean>(false);

  async function handleSubmit(event: FormEvent<HTMLFormElement>, data: any) {

    event.preventDefault();

    try{

      if(data.options){
        data.options = data.options.split(',').map((option: string) => option.trim()).join(',');
      }
  
      const res = await apiInstance.patch(`/questions/${question.id}`, data);
      if(res.status === 200){
        
        setState(res.data);
        dispatchNotification({ type: APINotificationActionTypes.SET_NOTIFICATION, payload: { message: "The question has been saved successfully", severity: Severity.SUCCESS }});
      }
    } catch(err) {

      console.log(err);
      dispatchNotification({ type: APINotificationActionTypes.SET_NOTIFICATION, payload: { message: "Error trying to save the question", severity: Severity.ERROR }});
    } finally {
      
      toggleEdition();
    }
  }

  async function handleDelete() {
    try{
  
      const res = await apiInstance.delete(`/questions/${question.id}`);
      if(res.status === 200){
        
        dispatchNotification({ type: APINotificationActionTypes.SET_NOTIFICATION, payload: { message: "The question has been deleted successfully", severity: Severity.SUCCESS }});
        setDeleted(true);
      }
    } catch(err) {

      console.log(err);
      dispatchNotification({ type: APINotificationActionTypes.SET_NOTIFICATION, payload: { message: "Error trying to delete the question", severity: Severity.ERROR }});
    } finally {
      
      toggleEdition();
    }
  }

  function toggleEdition(){

    setShowForm(!showForm);
  }

	return {
    deleted,
    handleSubmit,
    handleDelete,
    showForm,
    state,
    toggleEdition,
	}
}