import { Reducer } from "react";
import { ConsentForm } from "../../../models";
import { ConsentFormProviderActionType } from "./enums/ConsentFormProviderActionType.enum";
import { ConsentFormProviderAction } from "./interfaces/ConsentFormProviderAction.interface";


export const ConsentFormReducer: Reducer<ConsentForm, ConsentFormProviderAction> = (consentForm, action) => {

	const { type, payload } = action;
  switch (type) {

    case ConsentFormProviderActionType.ADD_ANSWER:
      
      return {
        ...consentForm,
        answers: consentForm.answers?.concat(payload)
      }

    case ConsentFormProviderActionType.ADD_FLAG:

      return {
        ...consentForm,
        flags: consentForm.flags.concat(payload)
      };

    case ConsentFormProviderActionType.REMOVE_FLAG:
      
      return {
        ...consentForm,
        flags: consentForm.flags.filter(flag => flag.id !== payload.id)
      };

    case ConsentFormProviderActionType.SET_BENEFITS:

      return {
        ...consentForm,
        procedure: {
          ...consentForm.procedure,
          benefits: payload
        }
      };

    case ConsentFormProviderActionType.SET_CONSENT_FORM:
			
      return {
        ...consentForm,
        ...payload,
      };

    case ConsentFormProviderActionType.SET_CONSIDERATIONS:

      return {
        ...consentForm,
        procedure: {
          ...consentForm.procedure,
          considerations: payload
        }
      };

    case ConsentFormProviderActionType.SET_INTERACTIONS:

      return {
        ...consentForm,
        answers: consentForm.answers?.map( answer => {
          if(answer.id === payload.answerId){
            return {
              ...answer,
              interactions: payload.interactions
            }
          }
          return answer;
        })
      };

    case ConsentFormProviderActionType.SET_PROCEDURE:
  
      return {
        ...consentForm,
        procedure: payload
      };

    case ConsentFormProviderActionType.SET_QUALITY_ASSURANCES:

      return {
        ...consentForm,
        qualityAssurances: payload
      };


    case ConsentFormProviderActionType.SET_RISKS:

      return {
        ...consentForm,
        procedure: {
          ...consentForm.procedure,
          risks: payload
        }
      };

    case ConsentFormProviderActionType.UPDATE_ANSWER:

      return {
        ...consentForm,
        answers: consentForm.answers?.map( answer => {
          if(answer.id === payload.id){
            return {
              ...payload,
              interactions: answer.interactions
            }
          }
          return answer;
        })
      };

    case ConsentFormProviderActionType.UPDATE_FLAG:

      return {
        ...consentForm,
        flags: consentForm.flags.map( flag => {
          if(flag.id === payload.id){
            return payload
          }
          return flag;
        })
      };

    case ConsentFormProviderActionType.UPDATE_CONSENT_FORM:

      return {
        ...consentForm,
        ...payload
      };

    case ConsentFormProviderActionType.UPDATE_INTERACTION:

      return {
        ...consentForm,
        answers: consentForm.answers?.map( answer => {
          if(answer.id === payload.answerId){
            return {
              ...answer,
              interactions: answer.interactions?.map( interaction =>{
                if(interaction.id === payload.id){
                  return payload;
                }
                return interaction;
              })
            }
          }
          return answer;
        })
      };

    default:
      return consentForm;
  }
}