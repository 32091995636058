import { useCallback, useEffect, useState } from "react";
import { differenceInCalendarYears } from "date-fns";

import { User } from "../../../../models";

import { apiInstance } from "../../../../common/api";
import useAPINotification from "../../../../common/hooks/useAPINotification";
import { APINotificationActionTypes } from "../../../../common/providers/APINotification/enums/APINotificationActionType.enum";
import { Severity } from "../../../../common/enums/severity.enum";

export default function useUsersTable() {
	const { dispatchNotification } = useAPINotification();
	const [data, setData] = useState<User[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	const getUsers = useCallback(() => {
		const fetchUsers = async () => {
			try {
				setLoading(true);
				const res = await apiInstance.get("/users?join=speciality&join=subSpeciality&join=clinic");
				if (res.data) {
					setData(res.data);
				}
			} catch (error) {
				dispatchNotification({ type: APINotificationActionTypes.SET_NOTIFICATION, payload: { message: "Error trying to get users", severity: Severity.ERROR } });
			} finally {
				setLoading(false);
			}
		}
		fetchUsers();
	}, [dispatchNotification]);

	useEffect(() => {
		getUsers();
	}, [getUsers]);

	const calcAge = (dob: Date) => differenceInCalendarYears(new Date(), dob);

	return { data, loading, calcAge }
}