import { useContext, useMemo, useState } from "react";
import { apiInstance } from "../../../../common/api";

import { Severity } from "../../../../common/enums/severity.enum";
import { APINotificationContext } from "../../../../common/providers/APINotification/APINotification.context";
import { APINotificationActionTypes } from "../../../../common/providers/APINotification/enums/APINotificationActionType.enum";
import { ConsentFormContext } from "../../../../common/providers/ConsentFormProvider/ConsentForm.context";
import { GlobalStateProviderActionType } from "../../../../common/providers/global-state-provider/enums/global-state-provider-action-type.enum";
import { GlobalStateContext } from "../../../../common/providers/global-state-provider/global-state.context";

export default function useNotifyPatientButton() {
  const { dispatchToGlobal } = useContext(GlobalStateContext);
  const { consentForm } = useContext(ConsentFormContext);
  const { dispatchNotification } = useContext(APINotificationContext);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  function closeModal() {
    setOpen(false);
  }

  function openModal() {
    setOpen(true);
  }

  const pendingQueries = useMemo(() => {
    for(const answer of consentForm.answers) {
      if(answer.interactions && answer.interactions.length > 0){
        for(const interaction of answer.interactions){
          if(!interaction.userComment){
            return true;
          }
        }
      }
    }
    return false;
  }, [consentForm.answers]);  

  async function notifyPatient() {
    try {
      setLoading(true);

      const res = await apiInstance.post(
        `/consent-forms/${consentForm.id}/notify-patient`
      );

      if (res.status === 200) {
        dispatchNotification({
          type: APINotificationActionTypes.SET_NOTIFICATION,
          payload: {
            message: "A notification has been sent to the patient.",
            severity: Severity.SUCCESS,
          },
        });
        dispatchToGlobal({
          type: GlobalStateProviderActionType.UPDATE_APPLICATIONS_HIGHLIGHTS,
          payload: consentForm.status,
        });
        setSuccess(true);
      }
    } catch (err) {
      console.log(err);
      dispatchNotification({
        type: APINotificationActionTypes.SET_NOTIFICATION,
        payload: {
          message: "Sorry, there was an issue communicating with the server.",
          severity: Severity.ERROR,
        },
      });
    } finally {
      setLoading(false);
      setOpen(false);
    }
  }

  return {
    closeModal,
    loading,
    notifyPatient,
    openModal,
    open,
    success,
    pendingQueries,
  };
}
