import { RequestQueryBuilder } from "@nestjsx/crud-request";
import {
  ChangeEvent,
  FormEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import * as htmlToImage from "html-to-image";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";

import { apiInstance } from "../../../../common/api";

import { Boolean } from "../../../../common/enums/boolean.enum";
import { ConsentFormStatus } from "../../../../common/enums/consent-form-status.enum";
import { Severity } from "../../../../common/enums/severity.enum";
import useAPINotification from "../../../../common/hooks/useAPINotification";
import { APINotificationActionTypes } from "../../../../common/providers/APINotification/enums/APINotificationActionType.enum";
import { ConsentFormContext } from "../../../../common/providers/ConsentFormProvider/ConsentForm.context";
import { ConsentFormProviderActionType } from "../../../../common/providers/ConsentFormProvider/enums/ConsentFormProviderActionType.enum";
import { GlobalStateProviderActionType } from "../../../../common/providers/global-state-provider/enums/global-state-provider-action-type.enum";
import { GlobalStateContext } from "../../../../common/providers/global-state-provider/global-state.context";
import { PatientProviderContext } from "../../../../common/providers/PatientProvider/Patient.context";

import { Resource } from "../../../../models";

interface Props {
  existingSignature: undefined | Resource;
  type: string;
}

export default function useSignature({ existingSignature, type }: Props) {
  const { globalState, dispatchToGlobal } = useContext(GlobalStateContext);
  const { patient } = useContext(PatientProviderContext);
  const { consentForm, dispatch } = useContext(ConsentFormContext);
  const { dispatchNotification } = useAPINotification();

  const [edition, setEdition] = useState<Boolean>(Boolean.FALSE);
  const [loading, setLoading] = useState<boolean>(false);
  const [signature, setSignature] = useState<Resource | undefined>();
  const [generatedSignature, setGeneratedSignature] = useState("");
  const [signed, setSigned] = useState<boolean>(false);
  const [meta, setMeta] = useState<{ [key: string]: string }>({
    fullName: "",
    address1: "",
    address2: "",
    city: "",
    county: "",
    postcode: "",
    entity: "signature",
    type,
  }); // if we don't initialize the meta we get an error on console as we use meta to control the form field.

  const sigCanvas = useRef<any>(null);

  const incomingMeta = existingSignature ? existingSignature.meta : "";
  const locked = consentForm.locked;

  useEffect(() => {
    if (existingSignature) {
      setSignature(existingSignature);
    }
  }, [existingSignature]);

  useEffect(() => {
    if (incomingMeta !== "") {
      setMeta({
        ...JSON.parse(incomingMeta),
        type,
      });
    } else {
      if (type === "Health Professional") {
        setMeta({
          fullName: `${globalState.user.firstName} ${globalState.user.lastName}`,
          address1: globalState.user.addressLine1,
          address2: globalState.user.addressLine2,
          city: globalState.user.city,
          county: globalState.user.county,
          postcode: globalState.user.postcode,
          entity: "signature",
          type,
        });
      }

      if (type === "Patient") {
        setMeta({
          fullName: `${patient.firstName} ${patient.lastName}`,
          address1: patient.addressLine1,
          address2: patient.addressLine2,
          city: patient.city,
          county: patient.county,
          postcode: patient.postcode,
          entity: "signature",
          type,
        });
      }
    }
  }, [
    incomingMeta,
    type,
    globalState.user.firstName,
    globalState.user.lastName,
    globalState.user.addressLine1,
    globalState.user.addressLine2,
    globalState.user.city,
    globalState.user.county,
    globalState.user.postcode,
    patient.firstName,
    patient.lastName,
    patient.addressLine1,
    patient.addressLine2,
    patient.city,
    patient.county,
    patient.postcode,
  ]); // A big dependency array but this avoid unnecessary re-renders due to pass referenced objects because we all only passing primitives.

  // Disable canvas while loading
  useEffect(() => {
    if (loading && sigCanvas.current) {
      sigCanvas.current.off();
    }
  }, [loading]);

  const clear = () => {
    setSigned(false);
    setGeneratedSignature("");
    sigCanvas.current.clear();
  };

  const resetMeta = () => {
    if (existingSignature) {
      const metaParsed = JSON.parse(existingSignature.meta);
      setMeta({
        ...meta,
        fullName: metaParsed.fullName,
        address1: metaParsed.address1,
        address2: metaParsed.address2,
        city: metaParsed.city,
        county: metaParsed.county,
        postcode: metaParsed.postcode,
      });
    }
  };

  const onFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMeta({ ...meta, [event.target.name]: event.target.value });
  };

  function createGeneratedSignature() {
    setGeneratedSignature(meta["fullName"]);
    setSigned(true);
  }
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (generatedSignature) {
      var node: any = document.getElementById("generatedSignature");
      htmlToImage.toBlob(node).then(async function (blob) {
        console.log(blob);
        saveSig(blob);
      });
    } else {
      sigCanvas.current.getCanvas().toBlob(async (blob: any) => {
        saveSig(blob);
      }, "image/svg+xml");
    }
  };

  const saveSig = async (blob: any) => {
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("file", blob, `signature-${type}.svg`);
      formData.append("type", "signature");
      formData.append("folder", "signatures");
      formData.append("meta", JSON.stringify(meta));
      formData.append("consentFormId", consentForm.id);

      if (type === "Patient") {
        formData.append("patientId", patient.id);
      }

      if (type === "Health Professional") {
        formData.append("userId", globalState.user.id);
      }

      const res = editing()
        ? await apiInstance.patch(`/resources/${signature!.id}`, formData)
        : await apiInstance.post("/resources", formData);

      if (res.status === 201 || res.status === 200) {
        dispatchToGlobal({
          type: GlobalStateProviderActionType.UPDATE_APPLICATIONS_HIGHLIGHTS,
          payload: consentForm.status,
        });

        if (res.status === 201) {
          const statusRes = await apiInstance.get(
            `/consent-forms/${consentForm.id}?fields=status`
          );

          if (
            statusRes.status === 200 &&
            statusRes.data.status !== consentForm.status
          ) {
            dispatch({
              type: ConsentFormProviderActionType.UPDATE_CONSENT_FORM,
              payload: { status: statusRes.data.status },
            });
          }
        }
        getSignature(res.data.id);
      }
    } catch (error) {
      console.log(error);
      dispatchNotification({
        type: APINotificationActionTypes.SET_NOTIFICATION,
        payload: {
          message:
            "Failed to save signature, please try again. If this issue persists please contact support.",
          severity: Severity.ERROR,
        },
      });
    } finally {
      if (editing()) {
        toggleEdition();
      }
    }
  };

  function disable(): boolean | undefined {
    switch (type) {
      case "Patient":
      case "Witness":
      case "Interpreter":
        return true;

      case "Health Professional":
        //removing the first condition it's ok as we need to enable the edition but the third condition should be commented out because when the form is locked we can not let them to edit.
        return (
          // consentForm.status !==
          //   ConsentFormStatus.PENDING_CONSULTANT_SIGNATURE ||
          signature && !editing()
          // ||
          // locked
        );

      default:
        return true;
    }
  }

  const editing = () => (edition === Boolean.FALSE ? false : true);

  const toggleEdition = () => {
    if (editing()) {
      setEdition(Boolean.FALSE);
    } else {
      setEdition(Boolean.TRUE);
      setSigned(!signed);
      setGeneratedSignature("");
    }
  };

  const getSignature = async (resourceId: string) => {
    setLoading(true);
    try {
      const qb = RequestQueryBuilder.create();
      qb.select(["id", "location", "createdAt", "meta"]).query();

      const res = await apiInstance.get(
        `/resources/${resourceId}?${qb.queryString}`
      );

      if (res.status === 200) {
        setSignature(res.data);
        return res.data;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    clear,
    disable,
    editing,
    handleSubmit,
    loading,
    locked,
    meta,
    onFieldChange,
    resetMeta,
    saveSig,
    setSigned,
    sigCanvas,
    signature,
    signed,
    toggleEdition,
    createGeneratedSignature,
    generatedSignature,
  };
}
