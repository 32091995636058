import { RequestQueryBuilder } from "@nestjsx/crud-request";
import { useCallback, useContext, useEffect, useState } from "react";
import { apiInstance } from "../../../../common/api";
import { Severity } from "../../../../common/enums/severity.enum";
import useAPINotification from "../../../../common/hooks/useAPINotification";
import { APINotificationActionTypes } from "../../../../common/providers/APINotification/enums/APINotificationActionType.enum";
import { ConsentFormContext } from "../../../../common/providers/ConsentFormProvider/ConsentForm.context";
import { Resource } from "../../../../models";

export default function useSignatures() {

	const { dispatchNotification } = useAPINotification();
	const { consentForm } = useContext(ConsentFormContext);

	const [signatures, setSignatures] = useState<Resource[]>([]);

	useEffect(() =>{

		const fetchSignatures = async () =>{
			try {
				const qb = RequestQueryBuilder.create();
				qb.select(["id", "location", "createdAt", "meta"])
					.setFilter({field: "consentFormId", operator: "$eq", value: consentForm.id})
					.setFilter({field: "type", operator: "$eq", value: "signature"})
					.query();
	
				const res = await apiInstance.get(`/resources?${qb.queryString}`);
	
				if (res.status === 200) {
					setSignatures(res.data);
					return res.data;
				}
			} catch (error) {
				console.log(error);
				dispatchNotification({ type: APINotificationActionTypes.SET_NOTIFICATION, payload: { message: "Error trying to get signatures" , severity: Severity.ERROR } });
			}
		}

		if(consentForm.id !== ""){
			fetchSignatures();
		}
		
	}, [consentForm.id, dispatchNotification]);

	const getSignatureByType = useCallback((type: string) => {
		
		const signature = signatures.find(s => {				
			const meta = JSON.parse(s.meta);
			if (meta.type === type) {
				return true;
			}
			return false;
		});

		return signature;		
	},[signatures])
	
	return {
		getSignatureByType,
	}
}