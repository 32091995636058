import React from "react";

import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import useQuestionForm from "./hooks";
import { Question } from "../../../models";
import { TopicType } from "../../../common/enums/topic-type.enum";
import { useQuestionFormStyles } from "./question-form.css";
import { QuestionTypes } from "../../../common/enums/question-types.enum";
import clsx from "clsx";

interface Props {
  cancelAction?: () => void;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>, data:any) => Promise<void>;
  question?: Question;
  topicId?: string;
  topicType?: TopicType;
}

const QuestionForm = ({ handleSubmit, cancelAction, question, topicId, topicType }: Props) => {

  const classes = useQuestionFormStyles();
  const { handleChange, state } = useQuestionForm({ question });
  
	return (
    <form className={classes.form} onSubmit={(event: any) => handleSubmit(event, state)}>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <TextField 
              fullWidth
              required
              label="Title"
              variant="outlined"
              name="title"
              value={state.title}
              onChange={handleChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={8}>
          <FormControl fullWidth>
            <TextField          
              fullWidth
              label="Description"
              multiline
              variant="outlined"
              name="description"
              value={state.description}
              onChange={handleChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="select-type-label">Type</InputLabel>
            <Select
              id="select-type"
              labelId="select-type-label"
              required
              fullWidth
              name="type"
              value={state.type}
              displayEmpty
              onChange={handleChange}
            >
              {
                Object.values(QuestionTypes).map(questionType => (<MenuItem value={questionType}>{questionType}</MenuItem>) )
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={8}>
          {
            state.type === QuestionTypes.RADIO_GROUP || state.type === QuestionTypes.RADIO_GROUP_FREE_TEXT || state.type === QuestionTypes.MULTI_CHOICE || state.type === QuestionTypes.MULTI_CHOICE_FREE_TEXT
            ? (
                <FormControl fullWidth>
                  <TextField
                    required
                    helperText={`Please type all the options you want for this question comma "," separated, i.e. I understand,I don't understand`}
                    label="Options"
                    name="options"
                    onChange={handleChange}
                    value={state.options}
                    variant="outlined"
                  />
                </FormControl>
              )
            : null
          }
        </Grid>
        <Grid item xs={12} className={classes.buttons}>
          <Button
            className={classes.button}
            disabled={ state.title === "" }
            type="submit"
            variant="contained"
            color="primary">
            Save question
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            onClick={cancelAction}>
            Cancel
          </Button>
        </Grid>
      </Grid>
    </form>
	);
};

export default QuestionForm;