import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useQuestionsStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider:{
      width: "100%",
      marginBottom: 20
    },
    listItem: {
      flexDirection: "column"
    },
    questionsTitle: {
      fontSize: '1.6rem',
      fontWeight: 'bold',
    },
    questionsSubTitle: {
      fontSize: '1rem',
    }
  }),
);