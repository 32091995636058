import { FormEvent, useEffect, useState } from "react";
import { apiInstance } from "../../../common/api";
import { Severity } from "../../../common/enums/severity.enum";
import useAPINotification from "../../../common/hooks/useAPINotification";
import { APINotificationActionTypes } from "../../../common/providers/APINotification/enums/APINotificationActionType.enum";

import { Speciality } from "../../../models";

enum Boolean {
  TRUE = "true",
  FALSE = "false"
}

export default function useSpecialities() {

  const { dispatchNotification } = useAPINotification();
  const [ specialities, setSpecialities ] = useState<Speciality[]>([]);
  const [ addNewStatus, setAddNewStatus ] = useState<Boolean>(Boolean.FALSE);
  const [ loading, setLoading ] = useState<Boolean>(Boolean.FALSE);

  useEffect(() =>{
    const fetchSpecialities = async () => {
  
      try{
  
        const res = await apiInstance.get("/specialities?join=subSpecialities");
        setSpecialities(res.data);
      } catch (error) {
        dispatchNotification({ type: APINotificationActionTypes.SET_NOTIFICATION, payload: { message: "Error trying to get specialities", severity: Severity.ERROR } });
      }
    }
    fetchSpecialities();    
  }, [dispatchNotification]);


  function toggleAddNew(){

    addNewStatus === Boolean.FALSE ? setAddNewStatus(Boolean.TRUE) : setAddNewStatus(Boolean.FALSE);
  }

  function addNew(){

    return addNewStatus === Boolean.FALSE ? false : true;
  }

  function isLoading(){

    return loading === Boolean.FALSE ? false : true;
  }

  async function handleSpecialitySubmit(event: FormEvent<HTMLFormElement>, data: any) {
    event.preventDefault();

    setLoading(Boolean.TRUE);    
    try{      
      const res = await apiInstance.post(`/specialities`, data);
        if(res.status === 201){
          
          setSpecialities(specialities.concat(res.data));
        }
    } catch (error) {
      console.log(error);
      dispatchNotification({ type: APINotificationActionTypes.SET_NOTIFICATION, payload: { message: "Error trying to create speciality", severity: Severity.ERROR } });
    } finally {
      setLoading(Boolean.FALSE);
      toggleAddNew();
    }
  }

	return {
    addNew,    
    handleSpecialitySubmit,
    isLoading,
    specialities,
    toggleAddNew,
	}
}