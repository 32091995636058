import { FormEvent, useCallback, useEffect, useState } from "react"
import update from 'immutability-helper'

import { Boolean } from "../../../common/enums/boolean.enum";
import { Consideration } from "../../../models"
import { apiInstance } from "../../../common/api";
import useAPINotification from "../../../common/hooks/useAPINotification";
import { APINotificationActionTypes } from "../../../common/providers/APINotification/enums/APINotificationActionType.enum";
import { Severity } from "../../../common/enums/severity.enum";

export default function useConsiderations() {

	const { dispatchNotification } = useAPINotification();
	const [ considerations, setConsiderations] = useState<Consideration[]>([]);
	const [ addNewStatus, setAddNewStatus ] = useState<Boolean>(Boolean.FALSE);
	const [ loading, setLoading ] = useState<Boolean>(Boolean.FALSE);

	useEffect(() =>{
		const fetchConsiderations = async () => {

			try{
	
				const res = await apiInstance.get("/considerations?sort=order,ASC&sort=name,ASC");
				if(res.status === 200){
					setConsiderations(res.data);
				}
			} catch (error) {
				console.log(error);
				dispatchNotification({ type: APINotificationActionTypes.SET_NOTIFICATION, payload: { message: "Error trying to get considerations.", severity: Severity.ERROR } });
			}
		}

		fetchConsiderations();
	}, [dispatchNotification]);

	function addNew(){

    return addNewStatus === Boolean.FALSE ? false : true;
	}
	
	function toggleAddNew(){

    addNewStatus === Boolean.FALSE ? setAddNewStatus(Boolean.TRUE) : setAddNewStatus(Boolean.FALSE);
	}

	function isLoading(){
    return loading === Boolean.TRUE;
  }
	
	async function handleSubmit(event: FormEvent<HTMLFormElement>, data: any) {
    event.preventDefault();
    
    setLoading(Boolean.TRUE);
    try {
      const res = await apiInstance.post(`/considerations`, data);
      
      if (res.status === 201) {
				considerations ? setConsiderations(considerations.concat(res.data)) : setConsiderations([res.data]);
        setLoading(Boolean.FALSE);
      }
      
    } catch (error) {
      console.log(error);
			dispatchNotification({ type: APINotificationActionTypes.SET_NOTIFICATION, payload: { message: "Error trying to create consideration.", severity: Severity.ERROR } });
    } finally {
      toggleAddNew();
    }
	}

	async function reorderConsiderations() {
    
    setLoading(Boolean.TRUE);
    try {
			const updatedConsiderations = considerations.map((consideration: Consideration, index) => {

				if(consideration.order !== (index + 1)){
					consideration.order = (index + 1);
				}

				return consideration;
			});

      const res = await apiInstance.patch(`/considerations`, updatedConsiderations);
			
      if (res.status === 200) {
        setLoading(Boolean.FALSE);
      }
      
    } catch (error) {
      console.log(error);
			dispatchNotification({ type: APINotificationActionTypes.SET_NOTIFICATION, payload: { message: "Error trying to update considerations.", severity: Severity.ERROR } });
    }
	}
	
	
	const moveConsideration = useCallback(
		(dragIndex: number, hoverIndex: number) => {
			
			const dragConsideration = considerations[dragIndex]
			setConsiderations(
				update(considerations, {
					$splice: [
						[dragIndex, 1],
						[hoverIndex, 0, dragConsideration],
					],
				}),
			)
		},
		[considerations],
	)

	return {
		addNew,
		considerations,
		handleSubmit,
		isLoading,
		moveConsideration,
		reorderConsiderations,
		toggleAddNew
	}
}