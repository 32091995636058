import React, { Fragment } from "react";
import { FiberManualRecord } from "@material-ui/icons";
import { makeStyles, createStyles, Theme, withStyles, Tooltip, Button } from "@material-ui/core";
import { frequencyMapping } from "./frequency-mapping";

const useStyles = makeStyles((theme: Theme) => {
	return createStyles({
		button: {
			textDecoration: "underline",
			textTransform: "initial",
			color: "#1717e6",
			padding: 1,
			minWidth: "unset"
		}
	})
});

/*
	Custom ToolTip
*/
const HtmlTooltip = withStyles((theme: Theme) => ({
	tooltip: {
		backgroundColor: '#f5f5f9',
		color: 'rgba(0, 0, 0, 0.87)',
		maxWidth: 220,
		fontSize: theme.typography.pxToRem(12),
		border: '1px solid #dadde9',
	},
}))(Tooltip);


/*
	Frequency display
	2 different display modes.
	- showPatientInfo true will display frequency within risk content with HTML tool tip
	- showPatientInfo false will show basic frequency display for use in the backoffice
*/
interface Props {
	frequency: "Very Rare" | "Rare" | "Uncommon" | "Common" | "Very Common" | "Other";
	showPatientInfo: boolean;
}

const Frequency = ({ frequency, showPatientInfo }: Props) => {
	const css = useStyles();

	const freq = frequencyMapping[frequency];

	// Used within Select list for admins and parameters
	if (!showPatientInfo) {
		return <Fragment><Circle color={freq.color} /> {freq.text} ({freq.value})</Fragment>;
	}

	if (!freq) {
		return <Button className={css.button}>[Frequency]</Button>;
	}

	// Used in Risk content preview how patient will see it
	return (
		<HtmlTooltip
			placement="top"
			arrow
			title={
				<Fragment>
					<sup>*</sup>Based on an estimated risk frequency of {freq.value} patients affected or {freq.verbal}.
				</Fragment>
			}>
			<Button className={css.button}>{freq.text}<sup>*</sup></Button>
		</HtmlTooltip>
	);
}

/*
	Coloured circle to indicate risk severity (red, orange, green etc...)
*/
interface CircleProps {
	color: string;
}

const Circle = ({ color }: CircleProps) => {
	return <FiberManualRecord style={{ marginRight: 5, width: 20 }} htmlColor={color} />
}

export default Frequency;