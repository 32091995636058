import React, { ReactNode } from "react";
import { PatientProviderContext } from "./Patient.context";
import usePatientProvider from './hooks';

interface Props {
  patientId?: string;
	children: ReactNode;
}

//TODO use React.memo to only re-render provider if patientId changes

const PatientProvider = ({ patientId, children }: Props) => {	
  
  const { patient, patientProviderDispatch } = usePatientProvider(patientId);

  return (
      <PatientProviderContext.Provider value={{ patient, patientProviderDispatch }}>
        {children}
      </PatientProviderContext.Provider>
	  );
}

export default PatientProvider;