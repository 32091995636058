import { RequestQueryBuilder } from "@nestjsx/crud-request";
import { useCallback, useState } from "react";
import { apiInstance } from "../../../common/api";
import { Severity } from "../../../common/enums/severity.enum";
import useAPINotification from "../../../common/hooks/useAPINotification";
import { APINotificationActionTypes } from "../../../common/providers/APINotification/enums/APINotificationActionType.enum";



import { ConsentForm } from "../../../models";

export default function useFetchConsentApplications() {
	const { dispatchNotification } = useAPINotification()
	const [loading, setLoading] = useState<boolean>(false);
	const [consentApps, setConsentApps] = useState<ConsentForm[] | null>(null);

	const fetchConsentForms = useCallback(async (patientId: string | undefined) => {
		if (patientId) {
			try {
				setConsentApps(null);
				setLoading(true);
				const res2 = await apiInstance.get(`/quality-assurances`);

				const qb = RequestQueryBuilder.create();
				qb.select(["id", "patientSubmittedAt", "locked", "status", "updatedAt", "createdAt", "createdBy"])
					.setJoin({ field: "clinic", select: ["id", "name"] })
					.setJoin({ field: "patient" })
					.setJoin({ field: "user" })
					.setJoin({ field: "procedure", select: ["id", "name"] })
					.setJoin({ field: "answers", select: ["id", "understood", "riskId", "considerationId", "benefitId", "qualityAssuranceId", "text", "updatedAt"] })
					.setJoin({ field: "resources", select: ["id", "location", "meta"] })
					.setFilter({ field: "patientId", operator: "$eq", value: patientId })
					.query();
	
				const res = await apiInstance.get(`/consent-forms?${qb.queryString}`);

				if (res.data) {
					if(res.data.length > 0){
						res.data.forEach((application: any) => {
							application.qualityAssurances = res2.data;
						});
	
					}		
					setConsentApps(res.data);
				}
			} catch (error) {
				console.log(error);
				dispatchNotification({ type: APINotificationActionTypes.SET_NOTIFICATION, payload: { message: "Error trying to get applications.", severity: Severity.ERROR } });
			} finally {
				setLoading(false);
			}
		}
	}, [dispatchNotification]);

	return {
		fetchConsentForms,
		consentApps,
		loading
	}
}