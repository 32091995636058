import { Grid, responsiveFontSizes, Typography } from "@material-ui/core";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { ConsentFormStatus } from "../../common/enums/consent-form-status.enum";
import { RoleTypes } from "../../common/enums/roleTypes.enum";
import { GlobalStateContext } from "../../common/providers/global-state-provider/global-state.context";
import { User } from "../../models";
import { useFetchConsultants } from "../patients/hooks";
import LoggedInTemplate from "../templates/LoggedIn";
import { useStyles } from "./css/dashboard.css";
import DashboardCard from "./dashboard-card";
import RequiresAction from "./requires-action";

const Dashboard = () => {
  const classes = useStyles();
  const { globalState } = useContext(GlobalStateContext);
  const { clinicConsultants, isLoading } = useFetchConsultants(true);

  return (
    <LoggedInTemplate title="Dashboard">
      <Grid container className={classes.root}>
        {globalState.user.role === RoleTypes.CONSULTANT ? (
          <Grid item xs={12} sm={6} key={globalState.user.id}>
            <DashboardCard
              title="Requires Action"
              link
              to={`/applications?userId=${
                globalState.user
                  ? encodeURIComponent(globalState.user.id)
                  : null
              }`}
            >
              <RequiresAction user={globalState.user} />
            </DashboardCard>
          </Grid>
        ) : null}

        {globalState.user.role === RoleTypes.CLINIC_ADMIN ? (
          <Grid item xs={12}>
            <Typography variant="h2">Requires Action</Typography>
            {clinicConsultants.length === 0 ? (
              <Typography variant="h4">
                Congratulations - no consent forms require any action.
              </Typography>
            ) : (
              <Grid container spacing={2}>
                {clinicConsultants.map((consultant: User) => (
                  <Grid item xs={12} sm={6} key={consultant.id}>
                    <DashboardCard
                      title={`${consultant.title} ${consultant.firstName} ${consultant.lastName}`}
                      link
                      to={`/applications?userId=${encodeURIComponent(
                        consultant.id
                      )}`}
                    >
                      <RequiresAction user={consultant} />
                    </DashboardCard>
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        ) : null}
      </Grid>
    </LoggedInTemplate>
  );
};

export default Dashboard;
