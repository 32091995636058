import React from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from "@material-ui/core";
import useFlagForm from "./hooks";
import { TopicType } from "../../../common/enums/topic-type.enum";
import { useFlagFormStyles } from "./flag-form.css";
import clsx from "clsx";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";

interface Props {
  topicId: string;
  topicType: TopicType;
}

const FlagForm = ({ topicId, topicType }: Props) => {
  const classes = useFlagFormStyles();
  const {
    disable,
    handleChange,
    handleSubmit,
    modified,
    state,
    toggleModified,
  } = useFlagForm({ topicId, topicType });

  return (
    <Grid
      container
      className={clsx(
        classes.flagContainer,
        state.important && state.important ? classes.flaggedImportant : ""
      )}
    >
      <Grid item xs={12}>
        <Grid container>
          <form onSubmit={handleSubmit} className={classes.form}>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.important}
                    onChange={handleChange}
                    name="important"
                    color="primary"
                  />
                }
                label="Flag this as important"
              />
              {state.important ? (
                <span style={{ paddingLeft: "100px" }}>
                  <WarningRoundedIcon />
                </span>
              ) : null}
            </Grid>

            {state.important ? (
              <Grid item xs={12}>
                <TextField
                  className={classes.textField}
                  fullWidth
                  label="Reason"
                  required
                  disabled={disable}
                  multiline
                  onChange={handleChange}
                  rows={4}
                  variant="outlined"
                  value={state.comment}
                />
              </Grid>
            ) : null}
            {modified ? (
              <Grid item xs={12} className={classes.buttons}>
                <Button
                  className={classes.button}
                  disabled={disable}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Save
                </Button>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="secondary"
                  onClick={toggleModified}
                >
                  Cancel
                </Button>
              </Grid>
            ) : null}
          </form>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FlagForm;
