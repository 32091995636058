import { useEffect, useReducer } from "react";

import { apiInstance } from "../../../api";

import useAPINotification from "../../../hooks/useAPINotification";
import { APINotificationActionTypes } from "../../APINotification/enums/APINotificationActionType.enum";
import { Severity } from "../../../enums/severity.enum";
import { ProcedureProviderActionType } from "../enums/ProcedureProviderActionType.enum";
import { ProcedureProviderReducer } from "../ProcedureProvider.reducer";
import { RequestQueryBuilder } from "@nestjsx/crud-request";

const initialState = {
	id: "",
	createdAt: "",
	createdBy: "",
	name: "",
	procedureCategoryId: "",
	specialityId: "",
	subSpecialityId: "",
	updatedAt: "",
	updatedBy: "",
	benefits: [],
	considerations: [],
	risks: [],
	procedureCategory: null as any
};


export default function useProcedureProvider(procedureId?: string) {
  
  const [ procedure, procedureProviderDispatch ] = useReducer(ProcedureProviderReducer, initialState);
	const { dispatchNotification } = useAPINotification();

	useEffect(() => {
		const fetchConsentForm = async () => {

			const qb = RequestQueryBuilder.create();
      qb.select([ "id", "name", ])
          .setJoin({ field: "risks", select: ["id", "name", "content", "order", "updatedAt"],  })
          .setJoin({ field: "benefits", select: ["id", "name", "content", "order", "updatedAt"] })
          .setJoin({ field: "considerations", select: ["id", "name", "content", "order", "updatedAt"] })
          .setFilter({ field: "risks.archive", operator: "$eq", value: false })
          .query();
  
      try{
        const res = await apiInstance.get(`/procedures/${procedureId}?${qb.queryString}`);
	
				if (res.status === 200) {
					procedureProviderDispatch({ type: ProcedureProviderActionType.SET_PROCEDURE, payload:res.data });
				}
			} catch (error) {
				console.log(error);
				dispatchNotification({ type: APINotificationActionTypes.SET_NOTIFICATION, payload: { message: "Error trying to get the procedure", severity: Severity.ERROR } });
			}
    };
    
    if(procedureId){

      fetchConsentForm();
    }
  }, [procedureId, dispatchNotification]);
  
	return {
		procedure,
		procedureProviderDispatch,
	};
}