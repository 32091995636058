import { useEffect, useState } from "react";
import { apiInstance } from "../../../common/api";
import { Severity } from "../../../common/enums/severity.enum";
import useAPINotification from "../../../common/hooks/useAPINotification";
import { APINotificationActionTypes } from "../../../common/providers/APINotification/enums/APINotificationActionType.enum";

import { ProcedureCategory } from "../../../models";

export default function useFetchUserProcedures(execute: boolean) {
	const { dispatchNotification } = useAPINotification()
	const [loading, setLoading] = useState<boolean>(false);
	const [procedures, setProcedures] = useState<ProcedureCategory[] | null>(null);

	useEffect(() => {
		const fetchProcedures = async () => {
			try {
				setLoading(true);
				const res = await apiInstance.get("/me/procedures");

				if (res.data) {
					const categoriesFlattened = res.data.flatMap((cat: ProcedureCategory) => [cat, ...cat.procedures!]);
					setProcedures(categoriesFlattened);
				}
			} catch (error) {
				console.log(error);
				dispatchNotification({ type: APINotificationActionTypes.SET_NOTIFICATION, payload: { message: "Error trying to get procedures.", severity: Severity.ERROR } });
			} finally {
				setLoading(false);
			}
		}

		if (execute) {
			fetchProcedures();
		}
	}, [dispatchNotification, execute]);

	return {
		procedures,
		loading
	}
}