import { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react";
import { apiInstance } from "../../../../common/api";
import { Severity } from "../../../../common/enums/severity.enum";
import { TopicType } from "../../../../common/enums/topic-type.enum";
import useAPINotification from "../../../../common/hooks/useAPINotification";
import { APINotificationActionTypes } from "../../../../common/providers/APINotification/enums/APINotificationActionType.enum";
import { ConsentFormContext } from "../../../../common/providers/ConsentFormProvider/ConsentForm.context";
import { ConsentFormProviderActionType } from "../../../../common/providers/ConsentFormProvider/enums/ConsentFormProviderActionType.enum";
import { Flag } from "../../../../models";


interface Props {
	topicId: string;
  topicType: TopicType;
}

interface iState {
  important: boolean;
  comment: string;
  benefitId: string | null;
  considerationId: string | null;
  riskId: string | null;
  consentFormId: string;
}

export default function useFlagForm({ topicId, topicType }: Props) {

  const { dispatchNotification } = useAPINotification();
  const { consentForm, dispatch } = useContext(ConsentFormContext);

  const initialState: iState = {
    important: false,
    comment: "",
    benefitId: topicType === TopicType.BENEFITS ? topicId : null,
    considerationId: topicType === TopicType.CONSIDERATIONS ? topicId : null,
    riskId: topicType === TopicType.RISKS ? topicId : null,
    consentFormId: consentForm.id,
  }
  
  const [ modified, setModified ] = useState<boolean>(false);
  const [ loading, setLoading ] = useState(false);
  const [ state, setState ] = useState<iState>(initialState)
  const [ flag, setFlag ] = useState<Flag>()

	const disable = consentForm.locked || loading;

  useEffect(() => {

    
    const existingFlag = consentForm.flags.find(flag => flag.benefitId === topicId || flag.considerationId === topicId || flag.riskId === topicId);
    
    if(existingFlag){
      setState(prevState => ({ ...prevState, ...existingFlag }));
      setFlag(existingFlag);
    }

    if(!existingFlag && flag){
      setFlag(undefined);
    }
    
  }, [flag, topicId, consentForm.flags]);

	const handleChange = async (event: ChangeEvent<any>) => {

    const {name, value} = event.target;
    
    name === "important" ? setState({ ...state, important: !state.important }) : setState({ ...state, comment: value });

    if(!modified || name === "important"){

      toggleModified();
    }
	};

  
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    
    try {
      setLoading(true);
      
      const res = !flag 
        ? await apiInstance.post('/flags', state) 
        : state.important 
          ? await apiInstance.patch(`/flags/${flag.id}`, state)
          : await apiInstance.delete(`/flags/${flag.id}`);
      
      if (res.status === 201 || res.status === 200) {
        
        state.important ? setState(prevState => ({ ...prevState, ...res.data })) : setState(prevState => (initialState));
        if(modified){
          
          setModified(false);
        }
        res.status === 200 ? dispatch(state.important ? { type: ConsentFormProviderActionType.UPDATE_FLAG, payload: res.data } : { type: ConsentFormProviderActionType.REMOVE_FLAG, payload: flag }) : dispatch({ type: ConsentFormProviderActionType.ADD_FLAG, payload:res.data });
      }
    } catch (error) {
      
      console.log(error);
      dispatchNotification({ type: APINotificationActionTypes.SET_NOTIFICATION, payload: { message: "Error trying to save your flag", severity: Severity.ERROR } })
    } finally {
      setLoading(false);
    }
	};

  function resetState(){
    flag ? setState({ ...state, ...flag, important: flag.important }) : setState(initialState);
  }
  
  function toggleModified(){

    if(modified){
      resetState();
    }
    setModified(!modified);
  }

	return {
    disable,
    handleChange,
    handleSubmit,
    modified,
    state,
    toggleModified,
	}
}