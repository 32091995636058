import React from "react";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";

import LoggedInTemplate from "../templates/LoggedIn";

import useSubProcedureTexts from "./hooks";
import { useSubProcedureTextsStyles } from "./sub-procedure-texts.css";
import SubProcedureTextForm from "./sub-procedure-text-form";

const SubProcedureTexts = () => {

  const { procedures, subProcedureTexts } = useSubProcedureTexts();
	const classes = useSubProcedureTextsStyles();

  return (
    <LoggedInTemplate title="Sub-Procedure Texts">
      { procedures.map((category, index) => {
          
          return (
            <Card key={category.id}>
              <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  {category.name}
                </Typography>
                  <Grid container className={classes.proceduresContainer}>
                    { category.procedures && category.procedures.map(procedure => {
                        const text = subProcedureTexts.find(subprocedure => subprocedure.procedureId === procedure.id);
                        return (
                          <Grid container className={classes.procedure}>
                            <Grid item xs={12}>
                              {procedure.name}
                            </Grid>
                            <SubProcedureTextForm 
                              procedureId={ text ? undefined : procedure.id }
                              text={ text ? text : undefined }
                            />
                          </Grid>
                        )
                      })                  
                    }
                  </Grid>
              </CardContent>
            </Card>
          );
        })
      }
    </LoggedInTemplate>
  );
}

export default SubProcedureTexts;
