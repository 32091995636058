import React from "react";
import { Button, createStyles, FormControl, Grid, List, makeStyles, TextField, Theme } from "@material-ui/core";
import LoggedInTemplate from "../templates/LoggedIn";
import useProcedures from "./hooks";
import ProcCategory from "./proc-category";
import { ProcedureCategory } from "../../models";
import { Loading } from "../patients/PatientOverview";
import { ProceduresContext } from "./hooks/procedures.context";

const useStyles = makeStyles((theme: Theme) => createStyles({
  approveBtn: {
		background: theme.palette.primary.main,
		display: "block",
		margin: "25px 0 15px 0",
		'&:hover': {
			background: theme.palette.primary.dark
		}
  },
  form:{
		width: '100%'
	},
  topbar: {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: 15
  }
}));
const Procedures = () => {

  const { addNew, handleSubmit, name, onFieldChange, state, dispatch, toggleAddNew } = useProcedures();
	const css = useStyles();

  return (
    <LoggedInTemplate title="Procedures">
      {
        !state.procedureCategories 
        ? <Loading /> 
        : (
          <ProceduresContext.Provider value={{ state, dispatch }}>
            <Button className={css.approveBtn} onClick={toggleAddNew} color="primary" variant="contained">Add Category</Button>
            {
							addNew() 
							? (
								// TODO this should be a new component called ProcCategoryForm
								<form onSubmit={(event) => handleSubmit(event)} className={css.form}>
									<Grid container spacing={3}>
										<Grid item xs={7}>
											<FormControl fullWidth margin="normal">
												<TextField
													autoFocus
													required
													onChange={onFieldChange}
													name="name"
													label="Name"
													value={name}
												/>
											</FormControl>
										</Grid>
										<Grid item xs={5}>
											<Button
												style={{ marginTop: 25, marginRight: 25 }}
												onClick={toggleAddNew}
												type="button"
												variant="contained"
												color="default">
												Cancel
											</Button>

											<Button
												style={{ marginTop: 25 }}
												type="submit"
												variant="contained"
												color="primary">
												Save Category
											</Button>
										</Grid>
									</Grid>
								</form>
							) 
							: null }
            <List>
              {state.procedureCategories.map((category: ProcedureCategory) => <ProcCategory data={category} key={category.id} />)}
            </List>
          </ProceduresContext.Provider>
			  )
      }
    </LoggedInTemplate>
  );
}

export default Procedures;
