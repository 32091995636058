import { FormEvent, useEffect, useState } from "react";
import { apiInstance } from "../../../../common/api";

import { Boolean } from "../../../../common/enums/boolean.enum";
import { Severity } from "../../../../common/enums/severity.enum";
import useAPINotification from "../../../../common/hooks/useAPINotification";
import { APINotificationActionTypes } from "../../../../common/providers/APINotification/enums/APINotificationActionType.enum";
import { SubSpeciality } from "../../../../models";

export default function useSubSpecialityItem(subSpeciality: SubSpeciality){
  
  const { dispatchNotification } = useAPINotification();
  const [ edition, setEdition ] = useState<Boolean>(Boolean.FALSE);
  const [ loading, setLoading ] = useState<Boolean>(Boolean.FALSE);
  const [ deleted, setDeleted ] = useState<Boolean>(Boolean.FALSE);
  const [ name, setName ] = useState<string>("");

  useEffect(() => {

    setName(subSpeciality.name);
  }, [subSpeciality]);

  function toggleEdition(){

    editing() ? setEdition(Boolean.FALSE) : setEdition(Boolean.TRUE);
  }

  function editing(){

    return edition === Boolean.FALSE ? false : true;
  }

  function isLoading(){

    return loading === Boolean.FALSE ? false : true;
  }

  function isDeleted(){

    return deleted === Boolean.FALSE ? false : true;
  }

  async function handleSubmit(event: FormEvent<HTMLFormElement>, data: any) {
    event.preventDefault();

    setLoading(Boolean.TRUE);
    try{
      const res = await apiInstance.patch(`/sub-specialities/${subSpeciality.id}`, data);
        if(res.status === 200){
          
          setName(res.data.name);
        }
    } catch (error) {
      console.log(error);
      dispatchNotification({ type: APINotificationActionTypes.SET_NOTIFICATION, payload: { message: "Error trying to update subSpeciality", severity: Severity.ERROR } });
    } finally {
      setLoading(Boolean.FALSE);
      toggleEdition();
    }
  }

  async function remove() {    

    setLoading(Boolean.TRUE);
    try{
      const res = await apiInstance.delete(`/sub-specialities/${subSpeciality.id}`);
        if(res.status === 200){
          
          setDeleted(Boolean.TRUE);
        }
    } catch (error) {
      console.log(error);
      dispatchNotification({ type: APINotificationActionTypes.SET_NOTIFICATION, payload: { message: "Error trying to delete subSspeciality", severity: Severity.ERROR } });
    } finally {
      setLoading(Boolean.FALSE);
      toggleEdition();
    }
  }

  return {
    editing,
    handleSubmit,
    isDeleted,
    isLoading,
    name,
    remove,
    toggleEdition,
  }
}