import { QueryFilter, RequestQueryBuilder } from "@nestjsx/crud-request";
import { filter } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { apiInstance } from "../../../common/api";
import { ConsentFormStatus } from "../../../common/enums/consent-form-status.enum";
import { Severity } from "../../../common/enums/severity.enum";
import useAPINotification from "../../../common/hooks/useAPINotification";
import { useQuery } from "../../../common/hooks/useQuery";
import { APINotificationActionTypes } from "../../../common/providers/APINotification/enums/APINotificationActionType.enum";
import { ConsentForm } from "../../../models";

export default function useApplications() {
  const { dispatchNotification } = useAPINotification();
  const [loading, setLoading] = useState<boolean>(false);
  const [applications, setApplications] = useState<ConsentForm[]>([]);
  const [searchFilter, setSearchFilter] = useState<string>();
  const [selectedStatusVals, setSelectedStatusVals] = useState<string[]>([
    "All",
  ]);
  const [selectedUserVals, setSelectedUserVals] = useState<string[]>(["All"]);
  const [hasRun, setHasRun] = useState<boolean>(false);

  const filterCreatedBy = useQuery().get("createdBy");
  const filterByUserId = useQuery().get("userId");

  const fetchApplications = useCallback(
    async (filterBy?: { field: string; value: string }) => {
      try {
        if (!loading) {
          setLoading(true);
        }

        const qb = RequestQueryBuilder.create();

        qb.setJoin({
          field: "patient",
          select: ["id", "title", "firstName", "lastName"],
        })
          .setJoin({ field: "procedure", select: ["id", "name"] })
          .setJoin({
            field: "user",
            select: ["title", "firstName", "lastName"],
          });

        const searchObj: any = {
          $and: [],
        };

        if (filterByUserId && !hasRun) {
          const baseUrl = window.location.href.split("?")[0];
          window.history.pushState({}, document.title, baseUrl);
          setSelectedUserVals([filterByUserId]);
          setSelectedStatusVals([
            ConsentFormStatus.PENDING_QUERIES,
            ConsentFormStatus.PENDING_CONSULTANT_SIGNATURE,
            ConsentFormStatus.PENDING_CONSULTANT_SUBMISSION,
          ]);
          setHasRun(true);
        }

        if (selectedStatusVals) {
          if (
            selectedStatusVals?.includes("All") ||
            selectedStatusVals?.length === 0
          ) {
            searchObj.$and.push({});
          } else {
            searchObj.$and.push({
              status: {
                $in: selectedStatusVals,
              },
            });
          }
        }
        if (selectedUserVals) {
          if (selectedUserVals?.includes("All")) {
            searchObj.$and.push({});
          } else {
            const consultantIds = selectedUserVals?.join(",");
            searchObj.$and.push({
              userId: {
                $in: consultantIds?.split(","),
              },
            });
          }
        }
        if (searchFilter) {
          const orArr: any[] = [
            {
              "patient.firstName": {
                $contL: searchFilter,
              },
            },
            {
              "patient.lastName": {
                $contL: searchFilter,
              },
            },
            {
              "procedure.name": {
                $contL: searchFilter,
              },
            },
          ];
          if (!filterCreatedBy) {
            orArr.push({
              createdBy: {
                $contL: searchFilter,
              },
            });
          }
          searchObj.$and.push({ $or: orArr });
        }

        if (filterCreatedBy) {
          searchObj.$and.push({
            createdBy: {
              $contL: filterCreatedBy,
            },
          });
        }

        qb.search(searchObj);

        qb.sortBy({ field: "createdAt", order: "DESC" });
        const res = await apiInstance.get(`/consent-forms?${qb.query()}`);

        if (res.data) {
          setApplications(res.data);
        }
      } catch (error) {
        console.log(error);
        dispatchNotification({
          type: APINotificationActionTypes.SET_NOTIFICATION,
          payload: {
            message: "Error trying to get applications.",
            severity: Severity.ERROR,
          },
        });
      } finally {
        setLoading(false);
      }
    },
    [
      dispatchNotification,
      filterCreatedBy,
      filterByUserId,
      searchFilter,
      selectedStatusVals,
      selectedUserVals,
    ]
  );

  useEffect(() => {
    fetchApplications();
  }, [fetchApplications, searchFilter]);

  function handleFilter(filterBy: { field: string; value: string | string[] }) {
    switch (filterBy.field) {
      case "search":
        setSearchFilter(filterBy.value as string);
        break;
      case "status":
        setSelectedStatusVals(
          Array.isArray(filterBy.value) && filterBy.value.length > 0
            ? filterBy.value
            : ["All"]
        );
        break;
      case "user":
        setSelectedUserVals(
          Array.isArray(filterBy.value) && filterBy.value.length > 0
            ? filterBy.value
            : ["All"]
        );
        break;
      default:
        break;
    }
  }
  //TODO this getColor is used in patient applications as well we should create a common Color component
  function getColor(status: string): string {
    switch (status) {
      case ConsentFormStatus.COMPLETE:
        return "green";

      case ConsentFormStatus.IN_PROGRESS:
        return "#d6b815";

      case ConsentFormStatus.PENDING_CONSULTANT_SIGNATURE:
      case ConsentFormStatus.PENDING_PATIENT_SIGNATURE:
        return "darkblue";

      case ConsentFormStatus.PENDING_CONSULTANT_SUBMISSION:
      case ConsentFormStatus.PENDING_PATIENT_SUBMISSION:
        return "#2594a2";

      case ConsentFormStatus.QA_NOT_UNDERSTOOD:
      case ConsentFormStatus.PENDING_QUERIES:
        return "orange";

      default:
        return "red";
    }
  }

  return {
    applications,
    handleFilter,
    getColor,
    loading,
    selectedStatusVals,
    setSelectedStatusVals,
    selectedUserVals,
    setSelectedUserVals,
  };
}
