import { RequestQueryBuilder } from "@nestjsx/crud-request";
import { FormEvent, useCallback, useContext, useEffect, useState } from "react";
import { apiInstance } from "../../../../common/api";

import { Boolean } from "../../../../common/enums/boolean.enum";
import { Severity } from "../../../../common/enums/severity.enum";
import useAPINotification from "../../../../common/hooks/useAPINotification";
import { APINotificationActionTypes } from "../../../../common/providers/APINotification/enums/APINotificationActionType.enum";
import { Procedure } from "../../../../models";
import { SubProcedureText } from "../../../../models/SubProcedureText";
import { ProceduresContext } from "../../hooks/procedures.context";
import { ProceduresActionTypes } from "../../interfaces";

export default function useProcedureItem(procedure: Procedure) {  
  
  const { dispatchNotification } = useAPINotification();
  const { dispatch } = useContext(ProceduresContext);
  const [ edition, setEdition ] = useState<Boolean>(Boolean.FALSE);
  const [ loading, setLoading ] = useState<Boolean>(Boolean.FALSE);
  const [ name, setName ] = useState<string>("");
  const [ openStatus, setOpenStatus ] = useState<Boolean>(Boolean.FALSE);
  const [ risks, setRisks ] = useState<string[]>([]);
  const [ benefits, setBenefits ] = useState<string[]>([]);
  const [ considerations, setConsiderations ] = useState<string[]>([]);
  const [ specialityId, setSpecialityId ] = useState<string>("");
  const [ subSpecialityId, setSubSpecialityId ] = useState<string>("");
  const [ subProcedureText, setSubProcedureText ] = useState<SubProcedureText>();

  const fetchSubProcedureText = useCallback(async () => {
    try{

      const qb = RequestQueryBuilder.create();
      qb.search({
        $and:[
          { userId: { $isnull: true } },
          { clinicId: { $isnull: true } },
          { procedureId: procedure.id }
        ]
      })
      .query();
      const res = await apiInstance.get(`/sub-procedure-texts?${qb.queryString}`);

      if (res.data && res.data[0]) {
				setSubProcedureText(res.data[0]);
			}
    } catch (err) {
      console.log(err);
    }
  }, [procedure.id]);

  useEffect(() =>{
    
    setName(procedure.name);
    setRisks(procedure.risks.map(risk => risk.id));
    setBenefits(procedure.benefits.map(benefit => benefit.id));
    setConsiderations(procedure.considerations.map(consideration => consideration.id));
    setSpecialityId(procedure.specialityId);
    setSubSpecialityId(procedure.subSpecialityId);
    fetchSubProcedureText();
  },[fetchSubProcedureText, procedure]);

  function editing(){
    return edition === Boolean.TRUE;
  }

  function toggleEdition(){
    
    editing() ? setEdition(Boolean.FALSE) : setEdition(Boolean.TRUE);
  }

  function isLoading(){
    return loading === Boolean.TRUE;
  }

  function open(){
    return openStatus === Boolean.TRUE;
  }

  function toggleOpenStatus(){
    
    open() ? setOpenStatus(Boolean.FALSE) : setOpenStatus(Boolean.TRUE);
  }

  async function handleSubmit(event: FormEvent<HTMLFormElement>, data: any) {
    event.preventDefault();
    
    setLoading(Boolean.TRUE);
    try{
      if(data.specialityId === procedure.specialityId){
        delete data.specialityId;
      }

      if(data.subSpecialityId === procedure.subSpecialityId){
        delete data.subSpecialityId;
      }

      if(data.name === procedure.name){
        delete data.name;
      }
      
      const res = await apiInstance.patch(`/procedures/${procedure.id}`, data);
      if(res.status === 200){
        setName(res.data.name);
        setSpecialityId(res.data.specialityId);
        setSubSpecialityId(res.data.subSpecialityId);
        setRisks(data.risks.map((risk: any) => risk.id));
        setBenefits(data.benefits.map((benefit: any) => benefit.id));
        setConsiderations(data.considerations.map((consideration: any) => consideration.id));
        if(res.data.procedureCategoryId !== procedure.procedureCategoryId){
          res.data.risks = risks;
          res.data.benefits = benefits;
          res.data.considerations = considerations;
          dispatch({
            type: ProceduresActionTypes.SWAP_PROCEDURE,
            payload: {
              fromCategory: procedure.procedureCategoryId,
              toCategory: res.data.procedureCategoryId,
              procedure: res.data
            }
          });
        }
      }

      
      if(data.subProcedureText){
        const textRes = subProcedureText ? await apiInstance.patch(`/sub-procedure-texts/${subProcedureText.id}`, { text: data.subProcedureText }) : await apiInstance.post(`/sub-procedure-texts`, { text: data.subProcedureText, procedureId: procedure.id });
        
        if(textRes.status === 200 || textRes.status === 201){      
          setSubProcedureText(textRes.data);
        }
      }
    } catch (error) {
      console.log(error);
      dispatchNotification({ type: APINotificationActionTypes.SET_NOTIFICATION, payload: { message: "Error trying to update procedure.", severity: Severity.ERROR } });
    } finally {
      setLoading(Boolean.FALSE);
      toggleEdition();
    }
  }

	return {    
    editing,
    handleSubmit,
    isLoading,
    name,
    risks,
    benefits,
    considerations,
    open,
    specialityId,
    subProcedureText,
    subSpecialityId,
    toggleEdition,
    toggleOpenStatus
	}
}