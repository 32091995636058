import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useQuestionFormStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      width: '100%',
    },
    button: {
      marginRight: 15
    },
    buttons: {
      marginBottom: 15
    },
    deleteButton: {
      backgroundColor: "#ec1e1e",
      color: "white",
      "&:hover":{
        backgroundColor: "#bb2525"
      }
    }
  }),
);