import { ConsentFormStatus } from "../enums/consent-form-status.enum";
export default function useGetColor() {
  //TODO this getStatus is used in patient applications as well we should create a common Status component
  function getColor(status: string): string {
    switch (status) {
      case ConsentFormStatus.COMPLETE:
        return "green";

      case ConsentFormStatus.IN_PROGRESS:
        return "#d6b815";

      case ConsentFormStatus.PENDING_CONSULTANT_SIGNATURE:
      case ConsentFormStatus.PENDING_PATIENT_SIGNATURE:
        return "darkblue";

      case ConsentFormStatus.PENDING_CONSULTANT_SUBMISSION:
      case ConsentFormStatus.PENDING_PATIENT_SUBMISSION:
        return "#2594a2";

      case ConsentFormStatus.QA_NOT_UNDERSTOOD:
      case ConsentFormStatus.PENDING_QUERIES:
        return "orange";

      default:
        return "red";
    }
  }

  return {
    getColor,
  };
}
