import { Box, Link, Typography } from "@material-ui/core";
import React, { ReactNode } from "react";
import { Helmet } from "react-helmet";

interface Props {
	children: ReactNode;
	title?: string;
	// All other props
	[x: string]: any;
}

const EmptyTemplate = ({ children, title, ...otherProps }: Props) => {
	return (
		<main {...otherProps}>
			<Helmet>
				<title>{title ? `${title} | Medical Consent` : "Medical Consent"} </title>
			</Helmet>
			{children}
			<Box mt={4}>
				<Copyright />
			</Box>
		</main>
	);
}

function Copyright() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{'Copyright © '}
			<Link color="textPrimary" target="_blank" rel="noopener" href="https://medicalconsent.co.uk">
				Medical Consent
      </Link>{' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}

export default EmptyTemplate;