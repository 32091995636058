import React from "react";
import { Theme, createStyles, makeStyles, FormControl, OutlinedInput, InputAdornment, IconButton, CircularProgress } from "@material-ui/core";
import { Clear, Search } from '@material-ui/icons';
import useSearchBox from "./hooks";

interface Props {
	handleSearch: (searchTerm: string) => void;
	loading?: boolean;
	placeHolderText?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexWrap: 'wrap',
		},
		textField: {
			width: "100%",
			maxWidth: "250px",
		},
	}),
);

const SearchBox = ({ handleSearch, loading, placeHolderText }: Props) => {
	const css = useStyles();
	const { clean, setClean, setValue, value } = useSearchBox();

	return (
		<FormControl className={css.textField} variant="filled" size="small">
			<OutlinedInput
				id="search"
				name="search"
				style={{ marginBottom: 16, background: "white" }}
				placeholder={ placeHolderText ? placeHolderText : "Search…" }
				fullWidth
				value={value}
				disabled={loading}
				onChange={(event) => {
					setClean(false);
					setValue(event.target.value);
					handleSearch(event.target.value);
				}}
				endAdornment={
					<InputAdornment position="end">
						<IconButton
							disabled={loading}
							aria-label="Search"
							type="submit"
							edge="end">
							{
								loading 
								? <CircularProgress size={20} /> 
								: clean 
									? <Search /> 
									: <Clear onClick={ ()=> {
											setClean(true);
											setValue("");
											handleSearch("");
										}} /> 
							}
						</IconButton>
					</InputAdornment>
				}
			/>
		</FormControl>
	);
}

export default SearchBox;