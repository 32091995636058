import { FormEvent, useEffect, useState } from "react";
import { apiInstance } from "../../../../common/api";
import { Severity } from "../../../../common/enums/severity.enum";
import useAPINotification from "../../../../common/hooks/useAPINotification";
import { APINotificationActionTypes } from "../../../../common/providers/APINotification/enums/APINotificationActionType.enum";

import { Speciality, SubSpeciality } from "../../../../models";

export enum Boolean {
  TRUE = "true",
  FALSE = "false"
}

export default function useSpecialityItem(speciality: Speciality){

  const { dispatchNotification } = useAPINotification();
  const [ collapseStatus, setCollapseStatus ] = useState<Boolean>(Boolean.TRUE);
  const [ addNewStatus, setAddNewStatus ] = useState<Boolean>(Boolean.FALSE);  
  const [ subSpecialities, setSubSpecialities ] = useState<SubSpeciality[]>([]);
  const [ edition, setEdition ] = useState<Boolean>(Boolean.FALSE);
  const [ loading, setLoading ] = useState<Boolean>(Boolean.FALSE);
  const [ deleted, setDeleted ] = useState<Boolean>(Boolean.FALSE);
  const [ name, setName ] = useState<string>("");

  useEffect(() => {

    setSubSpecialities(speciality.subSpecialities || []);
    setName(speciality.name);
  }, [speciality]);

  function toggleCollapse(){

    collapseStatus === Boolean.FALSE ? setCollapseStatus(Boolean.TRUE) : setCollapseStatus(Boolean.FALSE);
  }

  function collapsed(){

    return collapseStatus === Boolean.FALSE ? false : true;
  }

  function toggleAddNew(){

    addNewStatus === Boolean.FALSE ? setAddNewStatus(Boolean.TRUE) : setAddNewStatus(Boolean.FALSE);
  }

  function addNew(){

    return addNewStatus === Boolean.FALSE ? false : true;
  }

  function toggleEdition(){

    editing() ? setEdition(Boolean.FALSE) : setEdition(Boolean.TRUE);
  }

  function editing(){

    return edition === Boolean.FALSE ? false : true;
  }

  function isLoading(){

    return loading === Boolean.FALSE ? false : true;
  }

  function isDeleted(){

    return deleted === Boolean.FALSE ? false : true;
  }

  async function handleSubmit(event: FormEvent<HTMLFormElement>, data: any) {
    event.preventDefault();

    setLoading(Boolean.TRUE);    
    try{
      data.specialityId = speciality.id;
      const res = await apiInstance.patch(`/specialities/${speciality.id}`, data);
        if(res.status === 200){
          
          setName(res.data.name);
        }
    } catch (error) {
      console.log(error);
      dispatchNotification({ type: APINotificationActionTypes.SET_NOTIFICATION, payload: { message: "Error trying to update speciality", severity: Severity.ERROR } });
    } finally {
      setLoading(Boolean.FALSE);
      toggleEdition();
    }
  }

  async function handleSubSpecialitySubmit(event: FormEvent<HTMLFormElement>, data: any) {
    event.preventDefault();

    setLoading(Boolean.TRUE);    
    try{
      data.specialityId = speciality.id;
      const res = await apiInstance.post(`/sub-specialities`, data);
        if(res.status === 201){
          
          setSubSpecialities(subSpecialities.concat(res.data));
        }
    } catch (error) {
      console.log(error);
      dispatchNotification({ type: APINotificationActionTypes.SET_NOTIFICATION, payload: { message: "Error trying to create subSpeciality", severity: Severity.ERROR } });
    } finally {
      setLoading(Boolean.FALSE);
      toggleEdition();
    }
  }

  async function remove() {    

    setLoading(Boolean.TRUE);
    try{
      const res = await apiInstance.delete(`/specialities/${speciality.id}`);
        if(res.status === 200){
          
          setDeleted(Boolean.TRUE);
        }
    } catch (error) {
      console.log(error);
      dispatchNotification({ type: APINotificationActionTypes.SET_NOTIFICATION, payload: { message: "Error trying to remove speciality", severity: Severity.ERROR } });
    } finally {
      setLoading(Boolean.FALSE);
    }
  }

  return {
    addNew,
    collapsed,
    editing,
    handleSubmit,
    handleSubSpecialitySubmit,
    isDeleted,
    isLoading,
    name,
    remove,
    toggleCollapse,    
    toggleAddNew,
    toggleEdition,
    subSpecialities,
  }
}