import { RequestQueryBuilder } from "@nestjsx/crud-request";
import { FormEvent, useEffect, useState } from "react";
import { apiInstance } from "../../../../common/api";
import { Severity } from "../../../../common/enums/severity.enum";
import { TopicType } from "../../../../common/enums/topic-type.enum";
import useAPINotification from "../../../../common/hooks/useAPINotification";
import { APINotificationActionTypes } from "../../../../common/providers/APINotification/enums/APINotificationActionType.enum";
import { Question } from "../../../../models/Question";


export default function useTopicQuestions(topicId: string, topicType?: TopicType) {
    
    const { dispatchNotification } = useAPINotification();
    
    const [ questions, setQuestions] = useState<Question[]>([]);
    const [ showForm, setShowForm] = useState<boolean>(false);

    useEffect(() => {

      const fetchQuestions = async (topicId: string) => {

        try{
          const qb = RequestQueryBuilder.create();          
            qb.setOr([{ field: "considerationId", operator: "$eq", value: topicId }, { field: "benefitId", operator: "$eq", value: topicId }, { field: "riskId", operator: "$eq", value: topicId }])
            .sortBy({field: "createdAt", order: "ASC"})
            .query();
              
          const res = await apiInstance.get(`/questions?${qb.queryString}`);

          if(res.status === 200){
              setQuestions(res.data);
              //dispatch({ type: ConsentFormActionTypes.SET_INTERACTIONS, payload:{ answerId: answerId, interactions: res.data }})
          }
        } catch(err) {

          console.log(err);
          dispatchNotification({ type: APINotificationActionTypes.SET_NOTIFICATION, payload: { message: "Error trying to get notifications", severity: Severity.ERROR } })
        }      
      }

      fetchQuestions(topicId);

    }, [topicId, dispatchNotification]);

    async function handleSubmit(event: FormEvent<HTMLFormElement>, data: any) {

      event.preventDefault();
      try{

        switch (topicType) {
          case TopicType.CONSIDERATIONS:
            data.considerationId = topicId;
            break;
  
          case TopicType.BENEFITS:
            data.benefitId = topicId;
            break;
        
          default:
            data.riskId = topicId;
        }

        if(data.options){
          data.options = data.options.split(',').map((option: string) => option.trim()).join(',');
        }
    
        const res = await apiInstance.post(`/questions`, data);
        if(res.status === 201){
          
          setQuestions(questions.concat(res.data));
          dispatchNotification({ type: APINotificationActionTypes.SET_NOTIFICATION, payload: { message: "The question has been saved successfully", severity: Severity.SUCCESS }});
        }
      } catch(err) {
  
        console.log(err);
        dispatchNotification({ type: APINotificationActionTypes.SET_NOTIFICATION, payload: { message: "Error trying to save the question", severity: Severity.ERROR }});
      } finally {
        toggleShowForm();
      }
    }

    function toggleShowForm() {

      setShowForm(!showForm);
    } 

	return {
    handleSubmit,
    questions,
    showForm,
    toggleShowForm,
	}
}