import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useSubProcedureTextFormStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      position: "sticky",
      top: 15,
    },
    hide: {
      display: "none",
    },
    subProcedureText: {
      width: "100%"
    }  
  }),
);