import { useState, ChangeEvent, FormEvent, useContext, useEffect } from "react";

import { appConfig } from "../../../../constants";
import { fakeUser } from "../../../../lib";
import {
  Clinic,
  Procedure,
  ProcedureCategory,
  Speciality,
  SubSpeciality,
  User,
} from "../../../../models";

import { RoleTypes } from "../../../../common/enums/roleTypes.enum";
import { apiInstance } from "../../../../common/api";
import { GlobalStateContext } from "../../../../common/providers/global-state-provider/global-state.context";
import { GlobalStateProviderActionType } from "../../../../common/providers/global-state-provider/enums/global-state-provider-action-type.enum";
import useAPINotification from "../../../../common/hooks/useAPINotification";
import { APINotificationActionTypes } from "../../../../common/providers/APINotification/enums/APINotificationActionType.enum";
import { Severity } from "../../../../common/enums/severity.enum";

//TODO check if user is needed as we have a createdBy/updatedBy middleware in the backend.
export default function useUserForm(
  user: User,
  userId: string | undefined,
  editingSelf: boolean | undefined
) {
  const { dispatchToGlobal } = useContext(GlobalStateContext);
  const { dispatchNotification } = useAPINotification();
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [abort, setAbort] = useState<boolean>(false);
  const { globalState } = useContext(GlobalStateContext);

  const useFakeData = !userId && appConfig.FAKER;

  const fakeEmail = fakeUser().email;

  const [values, setValues] = useState({
    avatar: "",
    title: useFakeData ? fakeUser().title : "",
    firstName: useFakeData ? fakeUser().firstName : "",
    lastName: useFakeData ? fakeUser().lastName : "",
    email: useFakeData ? fakeEmail : "",
    confirmEmail: useFakeData ? fakeEmail : "",
    addressLine1: useFakeData ? fakeUser().addressLine1 : "",
    addressLine2: useFakeData ? fakeUser().addressLine2 : "",
    city: useFakeData ? fakeUser().city : "",
    county: useFakeData ? fakeUser().county : "",
    postcode: useFakeData ? fakeUser().postcode : "",
    jobTitle: useFakeData ? fakeUser().jobTitle : "",
    role: useFakeData ? fakeUser().role : "",
    clinicId: "",
    clinic: "",
    specialityId: null,
    speciality: "",
    subSpecialityId: null,
    subSpeciality: "",
    procedures: [] as any[],
    createdBy: user.email,
    updatedBy: user.email,
  });

  const [clinics, setClinics] = useState<Clinic[]>([] as Clinic[]);
  const [specialities, setSpecialities] = useState([] as Speciality[]);
  const [subSpecialities, setSubSpecialities] = useState([] as SubSpeciality[]);
  const [procedures] = useState([] as Procedure[]);
  const [procedureCategories, setProcCategories] = useState(
    [] as ProcedureCategory[]
  );
  const [emailsMatch, setEmailsMatch] = useState(true);
  const [showConfirmEmail, setShowConfirmEmail] = useState(false);
  const [roles] = useState([RoleTypes.CONSULTANT, RoleTypes.CLINIC_ADMIN]);
  const [error, setError] = useState<string | null>(null);

  const dropdownVals = {
    clinics,
    specialities,
    subSpecialities,
    procedures,
    procedureCategories,
    roles,
  };
  useEffect(() => {
    if (userId) {
      fetchUser(userId);
    }
    fetchSpecialities();
    if (!editingSelf) {
      fetchClinics();
    }

    // eslint-disable-next-line
  }, [userId]);

  async function fetchClinics() {
    try {
      const response = await apiInstance.get(`/clinics`);

      if (response.status === 200) {
        const clinics: Clinic[] = response.data;

        setClinics(clinics);
      }
    } catch (error) {
      console.log(error);
      dispatchNotification({
        type: APINotificationActionTypes.SET_NOTIFICATION,
        payload: {
          message: "Error trying to get clinics",
          severity: Severity.ERROR,
        },
      });
    }
  }

  async function fetchSpecialities() {
    try {
      const specialitiesResponse = await apiInstance.get(
        `/specialities?join=subSpecialities`
      );
      if (specialitiesResponse.status === 200) {
        const specialities: Speciality[] = specialitiesResponse.data.sort(
          (a: any, b: any) => a.name.localeCompare(b.name)
        );

        setSpecialities(specialities);
        if (values.specialityId) {
          specialitiesResponse.data.forEach((speciality: Speciality) => {
            if (speciality.id === values.specialityId) {
              setSubSpecialities(
                speciality.subSpecialities
                  ? speciality.subSpecialities.sort((a: any, b: any) =>
                      a.name.localeCompare(b.name)
                    )
                  : []
              );
            }
          });
        }
      }
    } catch (error) {
      console.log(error);
      dispatchNotification({
        type: APINotificationActionTypes.SET_NOTIFICATION,
        payload: {
          message: "Error trying to get specialities",
          severity: Severity.ERROR,
        },
      });
    }
  }

  async function fetchUser(userId: string) {
    try {
      const userResponse = await apiInstance.get(
        `/users/${userId}?join=procedures`
      );

      if (userResponse.status === 200) {
        userResponse.data.procedures = userResponse.data.procedures.map(
          (procedure: Procedure) => procedure.id
        );
        setValues(userResponse.data);
        if (userResponse.data.specialityId) {
          getSubSpecialities(userResponse.data.specialityId);
          getProcedureCategories(userResponse.data.subSpecialityId);
        }
      }
    } catch (error) {
      console.log(error);
      dispatchNotification({
        type: APINotificationActionTypes.SET_NOTIFICATION,
        payload: {
          message: "Error trying to get user",
          severity: Severity.ERROR,
        },
      });
    }
  }

  async function getSubSpecialities(specialityId: string) {
    const response = await apiInstance.get(
      `/sub-specialities?s={"specialityId":"${specialityId}"}`
    );
    setSubSpecialities(
      response.data.sort((a: any, b: any) => a.name.localeCompare(b.name))
    );
  }

  async function getProcedureCategories(subSpecialityId: string) {
    try {
      //TODO we need to filter this by subspecialityId
      const response = await apiInstance.get(
        `/procedure-categories?join=procedures`
      );

      const categories = response.data;
      const categoriesFlattened = categories.flatMap(
        (cat: ProcedureCategory) => [cat, ...cat.procedures!]
      );
      setProcCategories(categoriesFlattened);
    } catch (error) {
      console.log(error);
      dispatchNotification({
        type: APINotificationActionTypes.SET_NOTIFICATION,
        payload: {
          message: "Error trying to get procedure categories",
          severity: Severity.ERROR,
        },
      });
    }
  }

  function onFieldChange(event: ChangeEvent<any>) {
    const { name, value }: { name: string; value: string } = event.target;

    switch (name) {
      case "speciality":
        setValues((values) => ({
          ...values,
          [`subSpecialities`]: "",
          [`subSpecialitiesId`]: "",
        })); // Remove subSpeciality
        dropdownVals.specialities.map(
          (s) =>
            s.id === value &&
            setValues((values) => ({
              ...values,
              [`${name}`]: s.name,
              [`${name}Id`]: s.id,
            }))
        );
        getSubSpecialities(value);
        break;

      case "subSpeciality":
        dropdownVals.subSpecialities.map(
          (s) =>
            s.id === value &&
            setValues((values) => ({
              ...values,
              [`${name}`]: s.name,
              [`${name}Id`]: s.id,
            }))
        );
        getProcedureCategories(value);
        break;

      case "email":
        if (!showConfirmEmail) {
          setShowConfirmEmail(true);
        }
        setValues((values) => ({ ...values, [name]: value }));
        break;

      case "confirmEmail":
        if (value !== values.email && emailsMatch) {
          setEmailsMatch(false);
        }

        if (value === values.email && !emailsMatch) {
          setEmailsMatch(true);
        }
        setValues((values) => ({ ...values, [name]: value }));
        break;

      default:
        setValues((values) => ({ ...values, [name]: value }));
        break;
    }
  }

  const setAvatar = (src: string) => {
    setValues((values) => ({ ...values, avatar: src }));
  };
  const handleSubmit = async (
    event: FormEvent<HTMLFormElement>,
    userId: string | undefined
  ) => {
    event.preventDefault();

    try {
      setLoading(true);
      let payload = {};
      //If the current user logged in is an admin we set the clinic ID as the id of the clinic admin
      if (globalState.user.role === RoleTypes.CLINIC_ADMIN) {
        values.clinicId = globalState.user.clinicId;
      }
      //If we are creating a clinic admin
      if (values.role === RoleTypes.CLINIC_ADMIN) {
        //Get all the clinics procedures and assign it to that user
        const procedures = await apiInstance.get(
          "/procedures/getAllClinicProcedures",
          {
            params: {
              id: values.clinicId,
            },
          }
        );
        payload = { ...values, procedures: procedures.data.data };
      } else {
        values.procedures = values.procedures.map((procedure) => ({
          id: procedure,
        }));
        payload = { ...values };
      }
      let res;
      //If we are editing
      if (userId) {
        //If we are editing a clinic admin, we don't need to check if the procedures have changed
        if (values.role !== RoleTypes.CLINIC_ADMIN) {
          //Get prior user data and compare that to the current procedures entered
          const userData = await apiInstance.get(
            `/users/${userId}?join=procedures`
          );
          const oldProcedures = userData.data.procedures;
          //If the old procedures and current procedures are not the same we have to check if the clinic needs updated
          if (oldProcedures !== values.procedures) {
            await apiInstance.put(
              `/clinics/${values.clinicId}/updateClinicProcedures`,
              values.procedures
            );
          }
        }
        //Update user
        res = await apiInstance.patch("/users/" + userId, payload);
      } else {
        //if we are adding a clinic admin we don't have to check if the procedures have changed, otherwise we check
        if (values.role !== RoleTypes.CLINIC_ADMIN) {
          await apiInstance.put(
            `/clinics/${values.clinicId}/updateClinicProcedures`,
            values.procedures
          );
        }
        //Add user
        res = await apiInstance.post("/users", payload);
      }

      if (res.status === 201 || res.status === 200) {
        dispatchToGlobal({
          type: GlobalStateProviderActionType.SET_ALERT,
          payload: {
            content: `User successfully ${
              userId
                ? "updated"
                : "created, they have been sent an email to set up their account."
            }`,
            severity: "success",
          },
        });
        setSuccess(true);
      }
    } catch (error: any) {
      console.log(error);
      switch (error.response.status) {
        case 409:
          setError(
            "A user already exists in this clinic with this email address."
          );
          break;

        default:
          setError("Unexpected error creating user, please try again.");
          break;
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    abort,
    dropdownVals,
    error,
    emailsMatch,
    handleSubmit,
    loading,
    onFieldChange,
    setAbort,
    setAvatar,
    setValues,
    showConfirmEmail,
    success,
    values,
  };
}
