import React from "react";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import { Patient } from "../../../models";
import { Paper, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow } from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import PatientsTableHead from "./PatinetsTableHead";
import { usePatientsTableStyles } from "./patients-table.css";
import usePatientsTable from "./hooks";

interface Props {
	searching: boolean;
	patients: Patient[];
	loading: boolean;
}

const PatientsTable = ({ searching, patients, loading }: Props) => {
	const history = useHistory();
	const classes = usePatientsTableStyles();
	const { getComparator, handleChangePage, handleChangeRowsPerPage, handleRequestSort, order, orderBy, page, rowsPerPage, tableSort } = usePatientsTable();

	return (
		<TableContainer component={Paper}>
      <Table
				size="small"
			>
				<PatientsTableHead
					classes={classes}
					columns={[{label: 'Title', property: 'title'}, {label: 'First name', property: 'firstName'}, {label: 'Last name', property: 'lastName'}, {label: 'Date of birth', property: 'dob'}, {label: 'Email', property: 'email'}, {label: 'Phone', property: 'phone'}, {label: 'Actions', property: 'actions'} ]}
					order={order}
					orderBy={orderBy}
					onRequestSort={handleRequestSort}
				/>
        <TableBody>
				{tableSort(patients, getComparator(order, orderBy))
					.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
					.map((patient, index) => {
						return (
							<TableRow
								hover
								tabIndex={-1}
								key={patient.id}
							>
								<TableCell component="th" scope="row">{patient.title}</TableCell>
								<TableCell>{patient.firstName}</TableCell>
								<TableCell>{patient.lastName}</TableCell>
								<TableCell>{format(new Date(patient.dob), "dd/MM/yyyy")}</TableCell>
								<TableCell>{patient.email}</TableCell>
								<TableCell>{patient.phone}</TableCell>
								<TableCell><Visibility onClick={() => history.push("/patients/" + patient.id)} /></TableCell>
							</TableRow>
						);
					})
				}
        </TableBody>
      </Table>
			<TablePagination
				rowsPerPageOptions={[15, 25, 50]}
				component="div"
				count={patients.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onChangePage={handleChangePage}
				onChangeRowsPerPage={handleChangeRowsPerPage}
			/>
    </TableContainer>
	);

	// return (
	// 	<MaterialTable
	// 		data={patients}
	// 		isLoading={loading || searching}
	// 		options={{
	// 			padding: "dense",
	// 			search: false,
	// 			showTitle: false,
	// 			toolbar: false,
	// 			actionsColumnIndex: -1,
	// 			pageSize: 20,
	// 		}}
	// 		actions={[
	// 			{
	// 				icon: 'visibility',
	// 				tooltip: 'View Patient',
	// 				onClick: (event, rowData: any) => {
	// 					history.push("/patients/" + rowData.id);
	// 				}
	// 			}
	// 		]}
	// 		columns={[
	// 			{ 
	// 				title: "Title",
	// 				field: "title",
	// 				cellStyle: {
	// 					width: 50
	// 				},
	// 				headerStyle: {
	// 					width: 50,
	// 					backgroundColor: '#039be5',
	// 				},
	// 				width: 50
	// 			},
	// 			{ title: "First name", field: "firstName" },
	// 			{ title: "Last name", field: "lastName" },
	// 			{
	// 				title: "Date of birth",
	// 				field: "dateOfBirth",
	// 				dateSetting: { locale: "en-GB" },
	// 				customSort: (a, b) => calcAge(a.dob) - calcAge(b.dob),
	// 				render: (rowData: Patient) => <span>{format(new Date(rowData.dob), "dd/MM/yyyy")} ({calcAge(rowData.dob)})</span>
	// 			},
	// 			{ title: "Email", field: "email" },
	// 			{ title: "Phone", field: "phone" },
	// 			{
	// 				title: "Created at",
	// 				field: "createdAt",
	// 				render: (rowData: Patient) => <span>{format(new Date(rowData.createdAt), "dd/MM/yyyy, HH:mm")}</span>
	// 			},
	// 			{
	// 				title: "Last updated",
	// 				field: "updatedAt",
	// 				render: (rowData: Patient) => <span>{format(new Date(rowData.updatedAt), "dd/MM/yyyy, HH:mm")}</span>
	// 			},
	// 		]}
	// 	/>
	// );
}

export default PatientsTable;