import React from "react";
import LoggedInTemplate from "../../components/templates/LoggedIn";
import useRisksReview from "../../components/risks-review/hooks/use-risks-review";
import RisksList from "../../components/risks-review/RisksList";

const RisksReview = () => {
	const { risks } = useRisksReview();

	//TODO: Refactor to context to stop so much prop drilling
	return (
		<LoggedInTemplate title="Risks">
			<RisksList risks={risks} />
		</LoggedInTemplate>
	);
}

export default RisksReview;