import { useEffect, useState } from "react"
import { Boolean } from "../../../../common/enums/boolean.enum";

export default function useRiskForm( initialValues: { archive: Boolean; name: string; riskHtml: string; } | undefined ) {  
  
  const [ riskHtml, setRiskHtml ] = useState<string>("");
  const [ name, setName ] = useState<string>("");
  const [ archive, setArchive ] = useState<Boolean>(Boolean.FALSE);

  useEffect(() => {    
    if(initialValues){
      setRiskHtml(initialValues.riskHtml);
      setName(initialValues.name);
      setArchive(initialValues.archive);
    }
  }, [initialValues]);

  function toggleArchive() {    
    archive === Boolean.FALSE ? setArchive(Boolean.TRUE) : setArchive(Boolean.FALSE);
  }

  function isArchive(){
    return archive === Boolean.TRUE;
  }
  
	return {
    isArchive,
    name,
    riskHtml,
    setArchive,
    setRiskHtml,
    setName,
    toggleArchive
	}
}