import { FormEvent, useContext, useState } from "react";
import { apiInstance } from "../../../../common/api";
import { Severity } from "../../../../common/enums/severity.enum";
import useAPINotification from "../../../../common/hooks/useAPINotification";
import { APINotificationActionTypes } from "../../../../common/providers/APINotification/enums/APINotificationActionType.enum";
import { GlobalStateContext } from "../../../../common/providers/global-state-provider/global-state.context";
import { ConsentFormProviderActionType } from "../../../../common/providers/ConsentFormProvider/enums/ConsentFormProviderActionType.enum";
import { ConsentFormContext } from "../../../../common/providers/ConsentFormProvider/ConsentForm.context";
import { Interaction } from "../../../../models";
interface iState {
  userComment: string;
  discussed: false;
}

interface Props {
  interactionId: string;
  updateInteraction: (update: Interaction) => void;
}

export default function useInteractionForm({
  interactionId,
  updateInteraction,
}: Props) {
  const { globalState } = useContext(GlobalStateContext);
  const { dispatchNotification } = useAPINotification();
  const { dispatch } = useContext(ConsentFormContext);

  const [state, setState] = useState<iState>({
    userComment: "",
    discussed: false,
  });

  function handleChange(event: any) {
    setState({
      ...state,
      [event.target.name]: event.target.value || event.target.checked,
    });
  }

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const interactionData = {
      id: interactionId,
      userId: globalState.user.id,
      userComment: state.userComment,
    };

    try {
      const interactionRes = await apiInstance.patch(
        `/interactions/${interactionId}`,
        interactionData
      );
      if (interactionRes.status === 200) {
        // TODO review this. If we are dispatching the interaction to the consentFormContext
        // we could use the context to update the interaction on the view, but maybe we are
        // generating unnecessary re-renders, so investigate.
        updateInteraction(interactionRes.data);
        dispatchNotification({
          type: APINotificationActionTypes.SET_NOTIFICATION,
          payload: {
            message: "Your comment has been saved successfully",
            severity: Severity.SUCCESS,
          },
        });
        dispatch({
          type: ConsentFormProviderActionType.UPDATE_INTERACTION,
          payload: interactionRes.data,
        });
      }
    } catch (err) {
      console.log(err);
      dispatchNotification({
        type: APINotificationActionTypes.SET_NOTIFICATION,
        payload: {
          message: "Error trying to save your comment",
          severity: Severity.ERROR,
        },
      });
    }
  }

  return {
    state,
    handleChange,
    handleSubmit,
  };
}
