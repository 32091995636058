import { Box, Divider, Fade, Paper } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

const Loading = () => {
	return (
		<Paper elevation={1} component="section">
			<Fade in={true} timeout={1500}>
				<div style={{ padding: 15 }}>
					<Skeleton animation="wave" width={300} />
					<Box display="flex">
						<Skeleton animation="wave" style={{ marginRight: 5 }} width={100} />
						<Skeleton animation="wave" width={50} />
					</Box>
					<Divider light style={{ margin: "15px 0" }} />
					<Skeleton animation="wave" width={365} />
					<Box display="flex">
						<Skeleton animation="wave" style={{ marginRight: 5 }} width={180} />
						<Skeleton animation="wave" width={180} />
					</Box>
					<br />
					<Skeleton animation="wave" width={365} />
					<Box display="flex">
						<Skeleton animation="wave" style={{ marginRight: 5 }} width={180} />
						<Skeleton animation="wave" width={180} />
					</Box>
					<br />
					<Skeleton animation="wave" width={365} />
					<Skeleton animation="wave" width={365} />
				</div>
			</Fade>
		</Paper>
	);
}

export default Loading;