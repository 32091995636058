import React, { ReactNode } from "react";
import { Route, RouteProps } from "react-router-dom";
import { RoleTypes } from "../../common/enums/roleTypes.enum";
import Unauthorised from "../../pages/unauthorised";
import { Loading } from "../patients/PatientOverview";
import useAdminRoute from "./hooks";

interface AdminRouteProps extends RouteProps {
  children: ReactNode;
}

const AdminRoute = ({ children, ...rest }: AdminRouteProps) => {
  const { unauthorize, userRole } = useAdminRoute();
  return userRole === RoleTypes.NONE && !unauthorize ? (
    <Loading />
  ) : (
    <Route
      {...rest}
      render={() =>
        userRole === RoleTypes.ADMIN ? children : <Unauthorised />
      }
    />
  );
};

export default AdminRoute;
