import { RequestQueryBuilder } from "@nestjsx/crud-request";
import { useContext, useEffect, useState } from "react";
import { apiInstance } from "../../../../common/api";
import { Severity } from "../../../../common/enums/severity.enum";
import useAPINotification from "../../../../common/hooks/useAPINotification";
import { APINotificationActionTypes } from "../../../../common/providers/APINotification/enums/APINotificationActionType.enum";
import { ConsentFormContext } from "../../../../common/providers/ConsentFormProvider/ConsentForm.context";
import { ConsentFormProviderActionType } from "../../../../common/providers/ConsentFormProvider/enums/ConsentFormProviderActionType.enum";
import { Interaction } from "../../../../models";

export default function useInteractions(answerId: string) {
  const { dispatchNotification } = useAPINotification();
  const { dispatch } = useContext(ConsentFormContext);
  const [interactions, setInteractions] = useState<Interaction[]>([]);

  useEffect(() => {
    const fetchInteractions = async (answerId: string) => {
      try {
        const qb = RequestQueryBuilder.create();
        qb.select([
          "id",
          "patientComment",
          "userComment",
          "createdBy",
          "createdAt",
          "updatedBy",
          "updatedAt",
          "answerId",
          "patientId",
          "userId",
        ])
          .setFilter({ field: "answerId", operator: "$eq", value: answerId })
          .sortBy({ field: "createdAt", order: "ASC" })
          .query();

        const res = await apiInstance.get(`/interactions?${qb.queryString}`);

        if (res.status === 200) {
          setInteractions(res.data);
          dispatch({
            type: ConsentFormProviderActionType.SET_INTERACTIONS,
            payload: { answerId: answerId, interactions: res.data },
          });
        }
      } catch (err) {
        console.log(err);
        dispatchNotification({
          type: APINotificationActionTypes.SET_NOTIFICATION,
          payload: {
            message: "Error trying to get notifications",
            severity: Severity.ERROR,
          },
        });
      }
    };

    fetchInteractions(answerId);
  }, [answerId, dispatch, dispatchNotification]);

  return {
    interactions,
  };
}
