import React from "react";
import { Button, Collapse, createStyles, Grid, IconButton, ListItem, makeStyles, Theme, Typography } from "@material-ui/core";
import { Procedure } from "../../../models";
import useProcedureItem from "./hooks";
import ProcedureForm from "../procedureForm";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import clsx from "clsx";

interface iProps {
	data: Procedure;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	procedureName:{
		cursor: "pointer"
	},
	procedure:{
		width: "100%"
	},
	topbar: {
		display: "flex",
		alignItems: "flex-start",
		marginBottom: 15
	},
	risks: {
		maxWidth: "20%"
	},
	select: {
		width: '100%'
	},
	button: {
		display: "block"
	},
	hide:{
		display: "none"
	}
}));


const ProcedureItem = ({ data }: iProps) => {

	const { editing, handleSubmit, isLoading, name, open, risks, benefits, considerations, specialityId, subSpecialityId, subProcedureText, toggleEdition, toggleOpenStatus } = useProcedureItem(data);
	const css = useStyles();

	return (
    <ListItem>
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<Typography variant="h6" className={css.procedureName} >
					{name}
					<IconButton onClick={toggleOpenStatus}>
						{open() ? <ExpandLess style={{ color: "rgba(0, 0, 0, 0.54)" }} /> : <ExpandMore style={{ color: "rgba(0, 0, 0, 0.54)" }} />}
					</IconButton>
				</Typography>
			</Grid>
			<Collapse in={open()} unmountOnExit className={css.procedure} >
				<ProcedureForm 
					edition={editing()}
					cancelAction={toggleEdition} 
					handleSubmit={handleSubmit} 
					loading={isLoading()}
					initialValues={
						{
							category: data.procedureCategoryId,
							name,
							specialityId,
							subSpecialityId,
							benefits,
							considerations,
							risks,
							subProcedureText: subProcedureText ? subProcedureText.text : ""
						}
					} />

					<Button
						className={ clsx(editing() && css.hide) }
            disabled={editing()}
            style={{ marginTop: 25 }}
            variant="contained"
						color="primary"
						onClick={toggleEdition}
					>
            Edit Procedure
          </Button>
			</Collapse>
		</Grid>
		</ListItem>
	);
}

export default ProcedureItem;
