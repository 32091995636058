import React, { useState, ChangeEvent } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Paper, CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import EmptyTemplate from "../../components/templates/Empty";
import useFetchCSRF from "../../common/hooks/useFetchCSRF";
import { apiInstance } from "../../common/api";

const useStyles = makeStyles((theme) => ({
  bg: {
    height: "100vh",
    boxSizing: "border-box",
    paddingTop: 50,
    background: `linear-gradient(90deg, rgba(25,103,113,1) 0%, rgba(80,169,180,1) 100%)`,
    fallbacks: {
      background: theme.palette.primary.main,
    },
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 25,
    boxSizing: "border-box",
  },
  form: {
    width: "100%",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  alert: {
    alignSelf: "normal",
  },
}));

const ResetPassword = () => {
  const classes = useStyles();
  const { CSRF } = useFetchCSRF();

  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);
  const [values, setValues] = useState({ username: "" });

  function onFieldChange(event: ChangeEvent<any>) {
    const { name, value }: { name: string; value: string } = event.target;
    setValues((values) => ({ ...values, [name]: value }));
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    const { username } = values;

    try {
      const res = await apiInstance.post("/auth/forgot-password", {
        username,
        type: "user",
      });
      if (res.status === 200 || res.status === 201) {
        setSuccess(true);
      }
    } catch (error: any) {
      console.log(error);
      // Handle errors
      switch (error.response.data.message) {
        case "User not found":
          setError(
            "We could not find a user account associated to this email address."
          );
          break;
        case "Invalid CSRF token":
          setError("Expired token. Please refresh the page and try again.");
          break;
        default:
          setError("Unexpected server error. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  const isLoading = loading || CSRF.loading;

  return (
    <EmptyTemplate title="Request a new password" className={classes.bg}>
      <Container component="div" maxWidth="sm">
        <Paper className={classes.paper}>
          <img
            src="/images/Medical_Consent_Logo_Light.png"
            alt="Medical Consent"
            width={140}
          />
          <Box m={2}>
            <Typography component="h1" variant="h5">
              Request a new password
            </Typography>
          </Box>

          {!success && (
            <Box mt={1} mb={1} color="text.secondary">
              <Typography variant="body2">
                Please fill in the email that you used to register. You will be
                sent an email with instructions to reset your password.
              </Typography>
            </Box>
          )}

          {!success && (
            <form className={classes.form} onSubmit={handleSubmit}>
              <TextField
                type="email"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="username"
                autoComplete="email"
                autoFocus
                value={values.username}
                onChange={onFieldChange}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isLoading}
                className={classes.submit}
              >
                {isLoading ? (
                  <CircularProgress size={25} color="primary" />
                ) : (
                  "Send Email"
                )}
              </Button>
            </form>
          )}

          {success && (
            <Alert className={classes.alert} severity="success">
              An email has been sent to you with instructions to reset your
              password. If you do not see this email in your inbox within a few
              minutes, please check your spam/junk/clutter.
            </Alert>
          )}

          {error && (
            <Alert className={classes.alert} severity="error">
              {error}
            </Alert>
          )}
        </Paper>
      </Container>
    </EmptyTemplate>
  );
};

export default ResetPassword;
