import React from "react";
import { createStyles, makeStyles, Theme, Button, Box } from "@material-ui/core";
import LoggedInTemplate from "../templates/LoggedIn";
import { Link as RouterLink } from 'react-router-dom';
import UsersTable from "./UsersTable";

const useStyles = makeStyles((theme: Theme) => createStyles({
	topbar: {
		display: "flex",
		alignItems: "flex-start",
		marginBottom: 15
	}
}));

const Users = () => {
	const css = useStyles();

	return (
		<LoggedInTemplate title="Clinic Users">
			<div className={css.topbar}>
				<Box mr={2}>
					<Button variant="contained" color="primary" component={RouterLink} to="/users/new">
						Add new user
					</Button>
				</Box>
			</div>

			<UsersTable />
		</LoggedInTemplate>
	);
}

export default Users;