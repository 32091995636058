import React, { useContext, useEffect } from "react";
import { Accordion, AccordionDetails, AccordionSummary, createStyles, List, makeStyles, Theme, Typography } from "@material-ui/core";
import { Procedure as iProcedure, Procedure, RiskFrequency } from "../../models";
import SelectFrequency from "./SelectFrequency";
import { GlobalStateContext } from "../../common/providers/global-state-provider/global-state.context";
import { GlobalStateProviderActionType } from "../../common/providers/global-state-provider/enums/global-state-provider-action-type.enum";

const useStyles = makeStyles((theme: Theme) => createStyles({
	catTitle: {
		fontSize: 16,
		fontWeight: 500,
	},
	procedureTitle: {
		fontSize: 14
	},
	catTitleArea: {
		padding: 0
	},
	procedureCat: {
		marginBottom: 25,
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		padding: 0,
		boxShadow: "none",
		"&:before": {
			background: "none"
		}
	},
	procedure: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		padding: "5px 25px",
		boxSizing: "border-box",
		"&:nth-child(odd)": {
			background: "#f5f5f5"
		},
		"&:nth-child(even)": {
			background: "#fafafa"
		}
	},
	proceduresArea: {
		padding: 0,
		display: "block"
	},
	catCount: {
		fontSize: 14,
		color: "grey",
		fontWeight: "normal"
	}
}));

/* 
	Loop over procedures associated to the risk
*/
interface Props {
	procedures: iProcedure[];
	riskFrequencies: RiskFrequency[];
	paramValues: RiskFrequency[];
	setParamValues: (v: any) => void;
	riskId: string;
}

const ProceduresList = ({ procedures, riskFrequencies, paramValues, setParamValues, riskId }: Props) => {
	const { dispatchToGlobal } = useContext(GlobalStateContext);
	/*
		This code builds an array of procedure categories
		with the associated procedures inside the object
	*/
	// Start with empty array
	const procedureCategories: ProcedureCategoriesWithCategories[] = [];

	// Loop over procedures and push categories in that do not already exist
	for (const procedure of procedures) {
		if (!procedureCategories.some(cat => cat.id === procedure.procedureCategory.id)) {
			procedureCategories.push({ id: procedure.procedureCategory.id, name: procedure.procedureCategory.name, procedures: [] });
		}
	}

	// Categories are all constructed now
	for (const procedure of procedures) {
		// Loop over procedures again and now push procedures into the revelant procedure categories, procedures array 
		procedureCategories.find((cat) => cat.id === procedure.procedureCategory.id)?.procedures.push(procedure);
	}

	useEffect(() => {
		
		if(riskFrequencies.length > 0){

			dispatchToGlobal({ type: GlobalStateProviderActionType.SET_PARAMS_CURRENT_FREQ, payload: riskFrequencies[riskFrequencies.length - 1].text });
		}
	}, [dispatchToGlobal, riskFrequencies]);

	return (
		<List subheader={<div />}>
			{procedureCategories.map((cat) => {
				return (
					<ProcedureCategory
						key={cat.id}
						riskId={riskId}
						category={cat}
						procedures={cat.procedures}
						riskFrequencies={riskFrequencies}
						paramValues={paramValues}
						setParamValues={(v: any) => setParamValues(v)}
					/>
				);
			})}
		</List>
	);
}

/* 
	Procedure category section that lists associated procedures
*/
interface ProcedureCategoriesWithCategories {
	id: string;
	name: string;
	procedures: Procedure[];
}
interface ProcedureProps {
	procedures: iProcedure[];
	category: ProcedureCategoriesWithCategories;
	riskFrequencies: RiskFrequency[];
	paramValues: RiskFrequency[];
	setParamValues: (v: any) => void;
	riskId: string;
}

const ProcedureCategory = ({ procedures, category, riskFrequencies, paramValues, setParamValues, riskId }: ProcedureProps) => {
	const css = useStyles();

	return (
		<Accordion expanded={true} className={css.procedureCat}>
			<AccordionSummary
				className={css.catTitleArea}
				aria-controls={`panel-${category.name}-content`}
				id="panel1a-header">
				<Typography component="h4" className={css.catTitle}>
					{category.name} <span className={css.catCount}>({procedures.length})</span>
				</Typography>
			</AccordionSummary>
			<AccordionDetails className={css.proceduresArea}>
				{/* List all procedures related to the category */}
				{procedures.map((p) => {
					// If there is a user defined parameter display that otherwise display the default expert opinion frequency
					// Expert opinion frequencies do not contain a clinic id
					const defaultValueExpert = paramValues.find(value => value.procedureId === p.id && value.clinicId === null);
					const defaultValueUser = paramValues.find(value => value.procedureId === p.id && value.clinicId !== null);

					return (
						<div className={css.procedure} key={p.name}>
							<Typography className={css.procedureTitle}>{p.name}</Typography>
							<SelectFrequency
								setParamValues={(v: any) => setParamValues(v)}
								defaultValue={defaultValueUser || defaultValueExpert}
								procedure={p}
								riskId={riskId}
								paramValues={paramValues}
							/>
						</div>
					);
				})}
			</AccordionDetails>
		</Accordion>
	);
}


export default ProceduresList;
