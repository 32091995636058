import React, { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import EmptyTemplate from '../../components/templates/Empty';
import { Paper, CircularProgress, Grow } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Redirect } from 'react-router-dom';
import useFetchCSRF from '../../common/hooks/useFetchCSRF';
import { useLoginStyles } from './login.css';
import useLogin from './hooks';

const Login = () => {
	const classes = useLoginStyles();
	const { CSRF } = useFetchCSRF();
  const { attemptLogin, error, loading, loginSuccess } = useLogin();

	const isLoading = loading || CSRF.loading;

	if (loginSuccess && !isLoading) {
		return <Redirect to="/" />
	}

	return (
		<EmptyTemplate title="Login" className={classes.bg}>
			<Container component="div" maxWidth="sm">
				<Paper className={classes.paper}>
					<img src="/images/Medical_Consent_Logo_Light.png" alt="Medical Consent" width={140} />
					<Typography component="h1" variant="h5" style={{ marginTop: 25 }}>
						Medical Consent Sign In
					</Typography>
					<form className={classes.form} onSubmit={attemptLogin}>
						{!CSRF.error && (
							<Fragment>
								<TextField
									type="email"
									variant="outlined"
									margin="normal"
									required
									fullWidth
									id="email"
									label="Email Address"
									name="username"
									autoComplete="email"
									autoFocus
								/>
								<TextField
									variant="outlined"
									margin="normal"
									required
									fullWidth
									name="password"
									label="Password"
									type="password"
									id="password"
									autoComplete="current-password"
								/>
								<Button
									type="submit"
									fullWidth
									variant="contained"
									color="primary"
									disabled={isLoading}
									className={classes.submit}>
									Login {isLoading && <CircularProgress style={{ marginLeft: 15 }} size={15} />}
								</Button>
							</Fragment>
						)}

						{CSRF.error && (
							<Grow in={true} timeout={1000}>
								<Alert style={{ margin: "15px 0" }} variant="filled" severity="error">{CSRF.error}</Alert>
							</Grow>
						)}

						{error && (
							<Grow in={true} timeout={1000}>
								<Alert style={{ margin: "15px 0" }} variant="filled" severity="error">{error}</Alert>
							</Grow>
						)}

						<Grid container>
							<Grid item xs>
								<Link component={RouterLink} to="/reset-password" variant="body2">Forgot password?</Link>
							</Grid>
							<Grid item>
								<Link target="_blank" rel="noopener" href="https://medicalconsent.co.uk" variant="body2">
									{"Don't have an account? Sign Up"}
								</Link>
							</Grid>
						</Grid>
					</form>
				</Paper>
			</Container>
		</EmptyTemplate>
	);
}

export default Login;