import { Box, List, ListItem, Typography } from "@material-ui/core";
import React from "react";
import { TopicType } from "../../../common/enums/topic-type.enum";
import useTopicQuestions from "./hooks";
import TopicQuestionItem from "../TopicQuestionItem";
import { useTopicQuestionsStyles } from "./topic-questions.css";

/* 
	Individual Risk
*/
interface iProps {
	topicId: string;
	topicType?: TopicType;
}

const TopicQuestions = ({ topicId, topicType }: iProps) => {

	const classes = useTopicQuestionsStyles();
	const { questions } = useTopicQuestions(topicId, topicType);

	return (
    <Box>
			{
				questions.length > 0 
				? (<Typography variant="h6" component="p" style={{fontSize: 16}}>Questions:</Typography>)
				: null
			}
			<List>
				{
					questions.map((question, index) => <ListItem key={question.id} className={classes.listItem}><TopicQuestionItem question={question} /></ListItem>)
				}
			</List>
		</Box>
	);
}

export default TopicQuestions;