import React, { ReactNode } from "react";
import { useParams } from "react-router-dom";
import { ConsentFormContext } from "./ConsentForm.context";
import useConsentFormProvider from "./hooks";

interface Params {
	applicationId: string;
}

interface Props {	
	children: ReactNode;
}

const ConsentFormProvider = ({ children }: Props) => {
	const { applicationId } = useParams<Params>();
	const { consentForm, dispatch } = useConsentFormProvider(applicationId);

	return (
      <ConsentFormContext.Provider value={{ consentForm, dispatch }}>
				{children}
      </ConsentFormContext.Provider>		
	)
}

export default ConsentFormProvider;