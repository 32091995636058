import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: 15,
      borderRadius: 10,
      height: "auto",
      width: "auto",
      transition: "all 0.2s ease-in-out",
      "&:hover": {
        backgroundColor: "#f6f6f6",
        borderRadius: 15,
      },
    },
    title: {
      fontWeight: 500,
    },
  })
);
