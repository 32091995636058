import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Button, ThemeProvider } from "@material-ui/core";
import theme from "./theme";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import GlobalAlert from "./components/global-alert";

// Pages
import NotFound from "./components/NotFound";
import {
  PatientAdd,
  PatientEdit,
  PatientView,
  PatientsList,
} from "./components/patients";

import Users from "./components/users";
import UsersAdd from "./components/users/add-user";
import Risks from "./components/risks";
import EnvNotice from "./components/EnvNotice";
import Clinics from "./components/clinics";
import ClinicsAdd from "./components/clinics/add-clinic";
import EditClinic from "./components/clinics/edit-clinic/edit-clinic";
import Consultants from "./pages/consultants";
import RisksReview from "./pages/risks-review";
import NewPassword from "./pages/new-password";
import ResetPassword from "./pages/reset-password";
import EditUser from "./components/users/edit-user";
import ConsentApplication from "./components/consent-application";
import Procedures from "./components/procedures";
import MyProfile from "./components/MyProfile";
import Specialities from "./components/specialities";
import Benefits from "./components/benefits";
import Considerations from "./components/considerations";
import Applications from "./components/applications";
import APINotificationProvider from "./common/providers/APINotification";
import Dashboard from "./components/dashboard";
import GlobalStateProvider from "./common/providers/global-state-provider";
import AdminRoute from "./components/admin-route";
import Login from "./components/login";
import SubProcedureTexts from "./components/sub-procedure-texts";
//
function App() {
  return (
    <Router>
      <APINotificationProvider>
        <GlobalStateProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />

            <GlobalAlert />
            {process.env.REACT_APP_STAGE !== "production" && <EnvNotice />}

            <Switch>
              <Route exact path="/" children={<Dashboard />} />

              <Route exact path="/login" children={<Login />} />

              <Route exact path="/profile" children={<MyProfile />} />

              <Route
                exact
                path="/reset-password"
                children={<ResetPassword />}
              />
              <Route exact path="/new-password" children={<NewPassword />} />

              <AdminRoute exact path="/benefits" children={<Benefits />} />
              <AdminRoute
                exact
                path="/consultants"
                children={<Consultants />}
              />
              <AdminRoute
                exact
                path="/considerations"
                children={<Considerations />}
              />
              <AdminRoute exact path="/risks" children={<Risks />} />
              <AdminRoute exact path="/procedures" children={<Procedures />} />
              <AdminRoute
                exact
                path="/specialities"
                children={<Specialities />}
              />

              <Route exact path="/patients" children={<PatientsList />} />
              <Route exact path="/patients/new" children={<PatientAdd />} />
              <Route
                exact
                path="/patients/:patientId"
                children={<PatientView />}
              />
              <Route
                exact
                path="/patients/:patientId/edit"
                children={<PatientEdit />}
              />

              <Route exact path="/applications" children={<Applications />} />
              <Route
                exact
                path="/applications/:applicationId"
                children={<ConsentApplication />}
              />

              <Route
                exact
                path="/procedures/sub-procedure-texts"
                children={<SubProcedureTexts />}
              />
              <Route exact path="/users" children={<Users />} />
              <Route exact path="/users/new" children={<UsersAdd />} />
              <Route exact path="/users/:userId/edit" children={<EditUser />} />

              <Route exact path="/clinics" children={<Clinics />} />
              <Route exact path="/clinics/new" children={<ClinicsAdd />} />
              <Route
                exact
                path="/clinics/:clinicId/edit"
                children={<EditClinic />}
              />

              <Route exact path="/risks-review" children={<RisksReview />} />

              <Route children={<NotFound />} />
            </Switch>
          </ThemeProvider>
        </GlobalStateProvider>
      </APINotificationProvider>
    </Router>
  );
}

export default App;
