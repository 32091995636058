import React, { useContext } from "react";
import { Box, createStyles, Divider, List, ListItem, makeStyles, Theme, Typography } from "@material-ui/core";
import PatientDetails from "../PatientDetails";
import { Loading } from "../../patients/PatientOverview";
import Topics from "../Topics";
import { TopicType } from "../../../common/enums/topic-type.enum";
import { ProcedureProviderContext } from "../../../common/providers/ProcedureProvider/ProcedureProvider.context";
import { ConsentFormContext } from "../../../common/providers/ConsentFormProvider/ConsentForm.context";

const useStyles = makeStyles((theme: Theme) => createStyles({
	section: {
		display: "block",
		padding: 0,
		marginBottom: 75,
		marginTop: 25,
		paddingBottom: 25,
		"&:last-of-type": {
			marginBottom: 0
		}
	}
}));

interface Props {
  procedureId?: string;
}

const Procedure = ({ procedureId }: Props) => {

  const { consentForm } = useContext(ConsentFormContext);
  const { procedure } = useContext(ProcedureProviderContext);
  const css = useStyles();

	return procedure.name === "" ? <Loading /> : (
		<Box>
      <Typography variant="h2" component="h2">
        {procedure.name}
      </Typography>
      <Typography variant="h5" component="h5">
        SubProcedures: 
        <List>
          {
            consentForm.subProcedures.map(subProcedure => <ListItem>{subProcedure.name}</ListItem>)
          }
        </List>
      </Typography>

      <Divider style={{ margin: "25px 0" }} />

      <PatientDetails />
      
      <List>
        <ListItem divider={true} className={css.section}>          
          <Topics type={TopicType.CONSIDERATIONS} items={procedure.considerations} />          
        </ListItem>
        <ListItem divider={true} className={css.section}>          
          <Topics type={TopicType.BENEFITS} items={procedure.benefits} />          
        </ListItem>
        <ListItem divider={true} className={css.section}>          
          <Topics type={TopicType.RISKS} items={procedure.risks} />
        </ListItem>
        {
          // showNotificationButton()
          // ? (
          //   <ListItem divider={true} className={css.notification}>
          //     <ListItemText primary="Once you answer all questions you can notify patient:" />
          //     <NotifyPatientButton disabled={disableNotificationButton()} />
          //   </ListItem>
          // )
          // : null
        }
      </List>
    </Box>
	)
}

export default Procedure;