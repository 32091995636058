import { Card, CardContent } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { Fragment } from "react";

const LoadingSkeletonCards = () => {
	const SkeletonCard = () => {
		return (
			<Card style={{ margin: "15px 0" }}>
				<CardContent>
					<Skeleton animation="wave" width={400} />
					<Skeleton animation="wave" width={300} />
					<Skeleton animation="wave" width={250} />
					<Skeleton animation="wave" width={400} />
				</CardContent>
			</Card>
		);
	}

	return (
		<Fragment>
			<SkeletonCard />
			<SkeletonCard />
			<SkeletonCard />
			<SkeletonCard />
			<SkeletonCard />
			<SkeletonCard />
		</Fragment>
	);
}

export default LoadingSkeletonCards;