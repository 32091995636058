import React, { Fragment, useState } from "react";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, Typography } from "@material-ui/core";

import { Alert } from "@material-ui/lab";
import { apiInstance } from "../../../common/api";

interface Props {
	consentFormId: string;
}

interface iStatus {
	success: boolean;
	error: null | string;
	loading: boolean;
}

const ResendApplicationButton = ({ consentFormId }: Props) => {	
	const [open, setOpen] = React.useState(false);
	const [status, setStatus] = useState<iStatus>({
		success: false,
		error: null,
		loading: false
	})

	function closeModal() {
		setOpen(false);
	}

	function openModal() {
		setOpen(true);
	}

	async function resendApplication() {
		try {
			setStatus({ loading: true, error: null, success: false });

			const res = await apiInstance.post(`/consent-forms/${consentFormId}/resend`);

			if (res.status === 200) {
				setStatus((prevState) => ({ ...prevState, success: true }));
			} else {
				setStatus((prevState) => ({ ...prevState, error: "Unexpected error sending consent form", success: false }));
			}
		} catch (error) {
			console.log(error);
			setStatus((prevState) => ({ ...prevState, error: "Unexpected error sending consent form", success: false }));
		} finally {
			setStatus((prevState) => ({ ...prevState, loading: false }));
			setOpen(false);
		}
	}

	const { loading, success, error } = status;

	return (
		<Fragment>
			{/* Confirm dialog */}
			<Dialog
				open={open}
				onClose={closeModal}>
				<DialogTitle disableTypography>
					<Typography style={{ margin: 0 }} variant="h3">Resend Consent Form</Typography>
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						The patient will receive a new email inviting them to complete this consent application.
          </DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={closeModal} color="primary" >
						Cancel
          </Button>
					<Button onClick={resendApplication} color="primary" variant="contained">
						Send {loading && <CircularProgress style={{ marginLeft: 15, color: "white" }} size={15} />}
					</Button>
				</DialogActions>
			</Dialog>

			{error && (
				<Snackbar open={error !== null} autoHideDuration={5000} >
					<Alert severity="error">{error}</Alert>
				</Snackbar>
			)}

			{success && (
				<Snackbar open={success} autoHideDuration={5000} >
					<Alert severity="success">Consent form successfully resent</Alert>
				</Snackbar>
			)}

			<Button variant="outlined" color="secondary" onClick={openModal}>
				Resend
			</Button>
		</Fragment >
	);
}

export default ResendApplicationButton;