import * as faker from "faker";

export const fakePatient = () => {
  return {
    title: faker.name.prefix(),
    firstName: faker.name.firstName(),
    lastName: faker.name.lastName(),
    email: faker.internet.email(),
    sex: faker.random.arrayElement(["Male", "Female"]),
    phone: faker.phone.phoneNumber(),
    dob: faker.date.past(50, "2001-01-01").toDateString(),
    insuranceAuthorisationCode: faker.random.alphaNumeric(8),
    NHSnumber: faker.random.alphaNumeric(8),
    privateInsuranceNumber: faker.random.alphaNumeric(8),
    addressLine1: `${faker.random.number()} ${faker.address.streetName()}`,
    addressLine2: `${faker.address.streetName()} ${faker.address.streetSuffix()}`,
    city: faker.address.city(),
    county: faker.address.county(),
    postcode: faker.address.zipCode(),
  };
};

export const fakeUser = () => {
  return {
    title: faker.name.prefix(),
    firstName: faker.name.firstName(),
    lastName: faker.name.lastName(),
    email: faker.internet.email(),
    jobTitle: faker.name.jobTitle(),
    role: faker.random.arrayElement(["Consultant", "Clinic Manager", "admin"]),
    specialityId: faker.random.uuid(),
    subSpecialityId: faker.random.uuid(),
    clinicId: faker.random.uuid(),
    addressLine1: `${faker.random.number()} ${faker.address.streetName()}`,
    addressLine2: `${faker.address.streetName()} ${faker.address.streetSuffix()}`,
    city: faker.address.city(),
    county: faker.address.county(),
    postcode: faker.address.zipCode(),
  };
};

export const fakeClinic = () => {
  return {
    name: faker.name.prefix(),
    email: faker.internet.email(),
  };
};
