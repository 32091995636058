import React, { Fragment } from "react";
import { Box, Button, createStyles, Dialog, DialogContent, DialogTitle, List, makeStyles, Theme } from "@material-ui/core";
import useBenefits from "./hooks";
import LoadingSkeletonCards from "../common/loading-skeleton/LoadingSkeletonCards";
import BenefitItem from "./benefit-item/indext";
import LoggedInTemplate from "../templates/LoggedIn";
import BenefitForm from "./benefit-form";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const useStyles = makeStyles((theme: Theme) => createStyles({
	
  topbar: {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: 15
	}
}));

const Benefits = () => {
	
	const { benefits, addNew, handleSubmit, isLoading, moveBenefit, reorderBenefits, toggleAddNew } = useBenefits();
	const css = useStyles();
  
	return (
		<LoggedInTemplate title="Benefits">
			{
				!benefits
					? ( <LoadingSkeletonCards /> )
					: (
						<Fragment>
							<div className={css.topbar}>
								<Box mr={2}>
									<Button variant="contained" onClick={toggleAddNew} color="primary" >Add new benefit</Button>
								</Box>
							</div>
							<Dialog
								fullWidth
								maxWidth="lg"
								aria-labelledby="simple-dialog-title"
								open={addNew()}
								onBackdropClick={toggleAddNew}
							>
								<DialogTitle id="simple-dialog-title">Add new benefit</DialogTitle>								
								<DialogContent dividers>
									<BenefitForm cancelAction={toggleAddNew} handleSubmit={handleSubmit} loading={isLoading()} />

								</DialogContent>
							</Dialog>
							<DndProvider backend={HTML5Backend}>
								<List subheader={<div />}>
									{benefits.map((benefit, index) => <BenefitItem key={benefit.id} data={benefit} dropCard={reorderBenefits} index={index} moveCard={moveBenefit} />)}
								</List>
							</DndProvider>
						</Fragment>
					)
  		}
		</LoggedInTemplate>
	);
}

export default Benefits;