import React, { ReactNode, Fragment } from "react";
import { Helmet } from "react-helmet";
import Sidebar from "../sidebar";
import { Typography, Box, makeStyles, createStyles, Theme, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";
import Link from '@material-ui/core/Link';
import { drawerWidth } from "../../constants";
import { Redirect } from "react-router-dom";
import LoadingSplashScreen from "../LoadingSplashScreen";
import Profile from "../Profile";
import Unauthorised from "../../pages/unauthorised";
import useLoggedIn from "./hooks/useLoggedIn";

interface Props {
	children: ReactNode;
	title: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		content: {
			padding: 25,
			marginTop: 0
		},
		mainBody: {
			marginTop: 25
		},
		topBar: {
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
			borderBottom: "1px solid #ececec",
			paddingBottom: 15
		},
		footer: {
			padding: theme.spacing(1, 2),			
			backgroundColor: theme.palette.grey[200],
			position: 'absolute',
			width: '100%',
			bottom: 0,
			zIndex: 9999
		},
		footerTitle:{
			margin: 0,
			fontSize: '0.7rem'
		},
		footerText: {
			fontSize: '0.6rem'
		},
		overflow: {
			minHeight: 152
		}
	}),
);

function Copyright() {
	const classes = useStyles();
	return (
		<Typography className={classes.footerText} align="center" variant="body2" color="textSecondary">
			{'Copyright © '}
			<Link color="inherit" href="https://medicalconsent.co.uk">
				Medical Consent
			</Link>{' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}

const LoggedInTemplate = ({ children, title }: Props) => {

	const { countDown, globalState, handleClose, showSessionExpires } = useLoggedIn()
	const classes = useStyles();

	if (globalState.alert && (globalState.alert.content === "You do not have authorisation to view this resource." || globalState.alert.content === "Request failed with status code 403")) {
		return <Unauthorised />;
	}

	if (!sessionStorage.getItem("auth")) {
		return <Redirect to="/login" />;
	}

	if (globalState.user.id === "") {
		return <LoadingSplashScreen />;
	}	

	return (
		<Fragment>
			<Dialog
				open={showSessionExpires}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">
				<DialogTitle disableTypography id="alert-dialog-title">
					<Typography component="h2" variant="h6">Are you there?</Typography>
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Your session will expire in {`${ Math.floor((countDown % (60 * 60)) / 60)}:${ Math.floor((countDown % 60)).toLocaleString('en-GB', {minimumIntegerDigits: 2})}`}. Do you want to stay signed in?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="default">
						Yes, I'm here
					</Button>
				</DialogActions>
			</Dialog>
			<Box>
				<Helmet>
					<title>{title ? `${title} | Medical Consent` : "Medical Consent"} </title>
				</Helmet>

				<Sidebar />

				<main style={{ paddingLeft: drawerWidth }}>
					<article className={classes.content}>
						<div className={classes.topBar}>
							<Typography component="h1" variant="h1">
								<Box fontWeight={100}>
									{title}
								</Box>
							</Typography>

							<Profile />
						</div>

						<div className={classes.mainBody}>
							{children}
						</div>
					</article>
				</main>
			</Box>
			<footer className={classes.footer}>
				<Typography className={classes.footerTitle} variant="h6" align="center" gutterBottom>
					Medical Consent Ltd
				</Typography>
				<Typography className={classes.footerText} variant="subtitle1" align="center" color="textSecondary" component="address">
					Registered in England and Wales, Company Number: 12892046, VAT Number: 373 7494 59,
					Address: 11 Leadenhall Street,
					Fifth Floor,
					London,
					EC3V 1LP
				</Typography>
				<Copyright />
			</footer>
			<div className={classes.overflow}/>
		</Fragment>
	);
}

export default LoggedInTemplate;