import React from "react";
import { createStyles, makeStyles, Typography } from "@material-ui/core";
import { SubSpeciality } from "../../../models";
import useSubSpecialityItem from "./hooks";
import SubSpecialityForm from "../sub-speciality-form";
interface iProps {
	data: SubSpeciality;
}

const useStyles = makeStyles(() => createStyles({
  name:{
    cursor: "pointer",
  }
}));

const SubSpecialityItem = ({ data }: iProps) => {

	const { editing, handleSubmit, isDeleted, isLoading, name, remove, toggleEdition } = useSubSpecialityItem(data);
  const css = useStyles();
  
  if (isDeleted()){
    return (null);
  }

  if (editing()){
    return (
      <SubSpecialityForm
        handleSubmit={handleSubmit}
        data={data}
        loading={isLoading()}
        deleteAction={remove}
        cancelAction={toggleEdition} />
    );
  }
  return (<Typography variant="h6" className={css.name} onClick={toggleEdition}>{name}</Typography>);
}

export default SubSpecialityItem;