import React, { Fragment } from "react";
import { ConsentForm } from "../../../models";
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { useApplicationsTableStyles } from "./applications-table.css";
import { Order } from "./interfaces/order.type.";
import TableFilter from "./TableFilter";

interface UserProps {
  id: string;
  name: string;
}

interface Props {
  classes: ReturnType<typeof useApplicationsTableStyles>;
  columns: {
    label: string;
    property: keyof ConsentForm | "actions";
  }[];
  onRequestFilter: (filterBy: { field: string; value: string[] }) => void; // Updated type
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof ConsentForm
  ) => void;
  order: Order;
  orderBy: string;
  selectedStatusVals: string[];
  setSelectedStatusVals: React.Dispatch<React.SetStateAction<string[]>>;
  selectedUserVals: string[];
  setSelectedUserVals: React.Dispatch<React.SetStateAction<string[]>>;
}

const ApplicationsTableHead = ({
  classes,
  columns,
  order,
  orderBy,
  onRequestFilter,
  onRequestSort,
  selectedStatusVals,
  setSelectedStatusVals,
  selectedUserVals,
  setSelectedUserVals,
}: Props) => {
  const createSortHandler =
    (property: keyof ConsentForm) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {columns.map((column, index) => (
          <TableCell
            className={classes.cell}
            key={index}
            sortDirection={orderBy === column.property ? order : false}
          >
            {column.property === "actions" ? (
              column.label
            ) : column.property === "status" || column.property === "user" ? (
              <Fragment>
                <div>{column.label}</div>
                <TableFilter
                  field={column.property}
                  handleFilter={onRequestFilter}
                  selectedStatusVals={selectedStatusVals}
                  setSelectedStatusVals={setSelectedStatusVals}
                  selectedUserVals={selectedUserVals}
                  setSelectedUserVals={setSelectedUserVals}
                />
              </Fragment>
            ) : (
              <TableSortLabel
                active={orderBy === column.property}
                direction={orderBy === column.property ? order : "asc"}
                onClick={createSortHandler(column.property)}
              >
                {column.label}
                {orderBy === column.property ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default ApplicationsTableHead;
