import { FormEvent, useEffect, useState } from "react"
import { apiInstance } from "../../../../common/api";

import { Boolean } from "../../../../common/enums/boolean.enum"
import { Severity } from "../../../../common/enums/severity.enum";
import useAPINotification from "../../../../common/hooks/useAPINotification";
import { APINotificationActionTypes } from "../../../../common/providers/APINotification/enums/APINotificationActionType.enum";
import { Risk } from "../../../../models"

export default function useRiskItem(risk: Risk) {
  
  const { dispatchNotification } = useAPINotification();
  const [ archive, setArchive ] = useState<Boolean>(Boolean.FALSE);
  const [ show, setShow ] = useState<Boolean>(Boolean.FALSE);
  const [ edition, setEdition ] = useState<Boolean>(Boolean.FALSE);
  const [ loading, setLoading ] = useState<Boolean>(Boolean.FALSE);
  const [ riskHtml, setRiskHtml ] = useState<string>("");
  const [ name, setName ] = useState<string>("");  
  
  useEffect(() => {

    setArchive(risk.archive ? Boolean.TRUE : Boolean.FALSE);
    setName(risk.name)
    setRiskHtml(risk.content);
  }, [risk]);

  function isArchive(){
    return archive === Boolean.TRUE;
  }

  function toggleShow(){

    show === Boolean.FALSE ? setShow(Boolean.TRUE) : setShow(Boolean.FALSE);
  }

  function toggleEdition(){

    edition === Boolean.FALSE ? setEdition(Boolean.TRUE) : setEdition(Boolean.FALSE);
  }

  function collapsed(){

     return show === Boolean.TRUE;
  }

  function isLoading(){
    return loading === Boolean.TRUE;
  }

  function editing(){
    return edition === Boolean.TRUE;
  }

  async function handleSubmit(event: FormEvent<HTMLFormElement>, data: any) {
    event.preventDefault();
    
    setLoading(Boolean.TRUE);
    try {
      const res = await apiInstance.patch(`/risks/${risk.id}`, data);

      if (res.status === 200) {
        setName(res.data.name);
        setRiskHtml(res.data.content);
        setArchive(res.data.archive ? Boolean.TRUE : Boolean.FALSE);
        setLoading(Boolean.FALSE);
      }
      
    } catch (error) {
      console.log(error);
      dispatchNotification({ type: APINotificationActionTypes.SET_NOTIFICATION, payload: { message: "Error trying to update risk.", severity: Severity.ERROR } });
    } finally {
      toggleEdition();
    }
  }
  
	return {
    archive,
    collapsed,
    editing,
    handleSubmit,
    isArchive,
    isLoading,
    name,
    riskHtml,
    setRiskHtml,
    toggleEdition,
		toggleShow
	}
}