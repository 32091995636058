import React from "react";
import { Button, CircularProgress, FormControl, Grid, TextField } from "@material-ui/core";
import clsx from "clsx";
import { useSubProcedureTextFormStyles } from "./sub-procedure-text-form.css";
import useSubProcedureTextForm from "./hooks";
import { SubProcedureText } from "../../../models/SubProcedureText";

interface iProps {
  procedureId?: string;
  text?: SubProcedureText;  
}

const SubProcedureTextForm = ({ procedureId, text }: iProps) => {

  const classes = useSubProcedureTextFormStyles();
  const { cancelAction, handleChange, handleSubmit, loading, modified, value } = useSubProcedureTextForm({ procedureId, text });

  return (
    <form
      onSubmit={(event) =>
        handleSubmit(event)
      }
      className={classes.root}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl
            className={clsx(classes.subProcedureText)}
          >
            <TextField
              disabled={loading}
              autoFocus
              onChange={handleChange}
              name="text"
              label="Text as sub-procedure"
              value={ value }
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} className={clsx(!modified && classes.hide)}>
          <Button
            disabled={loading}
            style={{ marginRight: 25 }}
            onClick={cancelAction}
            type="button"
            variant="contained"
            color="default"
          >
            Cancel
          </Button>

          <Button
            disabled={loading}
            type="submit"
            variant="contained"
            color="primary"
          >
            Save Text
            {loading && (
              <CircularProgress style={{ marginLeft: 15 }} size={15} />
            )}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default SubProcedureTextForm;
