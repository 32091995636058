import React from "react";
import { Button, CircularProgress, createStyles, FormControlLabel, Grid, makeStyles, Switch, TextField, Theme } from "@material-ui/core";
import AceEditor from "react-ace";
import "ace-builds/webpack-resolver";
import "ace-builds/src-min-noconflict/ext-language_tools";
import "ace-builds/src-min-noconflict/mode-html";
import "ace-builds/src-min-noconflict/theme-monokai";
import useRiskForm from "./hooks";
import { Boolean } from "../../../common/enums/boolean.enum";

/* 
	Individual Risk
*/
interface iProps {
  cancelAction: () => void,
  handleSubmit: (event: React.FormEvent<HTMLFormElement>, data:any) => Promise<void>,
  initialValues?: {
    archive: Boolean,
    name: string,
    riskHtml: string
  },
  loading: boolean
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  button:{ 
    marginTop: 25, 
    marginRight: 25 
  },
	cardBody: {
		display: "flex",
		top: 0
	},
	cArea: {
		flex: "1",
		paddingTop: 0,
		position: "relative"
	},
	cAreaContent: {
		position: "sticky",
		top: 15
	},
	form: {
    width: '100%',
		position: "sticky",
    top: 15,
    '& .MuiFormControl-root': {
      margin: theme.spacing(1),      
    },
  },
  name: {
    width: "50%"
  },
	titleBar: {
		cursor: "pointer",
	}
}));

const RiskForm = ({ cancelAction, handleSubmit, initialValues, loading }: iProps) => {

	const { isArchive, riskHtml, name, setRiskHtml, setName, toggleArchive } = useRiskForm(initialValues);
  const css = useStyles();  
	return (    
    <Grid container>
      <form onSubmit={(event:any) => handleSubmit(event, {archive: isArchive(), name, content: riskHtml})} className={css.form}>
        <Grid item xs={12}>
          <TextField
            className={css.name}
            disabled={loading}
            size="medium"
            label="Name"
            value={name}
            onChange={(event: any) => setName(event.target.value)}
          />
          <FormControlLabel
            value={isArchive()}
            control={ <Switch color="primary" checked={isArchive()} onChange={toggleArchive} /> }
            label="Archive"
            labelPlacement="top"
          />
        </Grid>
        <Grid item xs={12}>
          <AceEditor
            wrapEnabled={true}
            readOnly={loading}
            style={{ margin: "15px 0" }}
            name="risk"
            mode="html"
            width="100%"
            height="300px"
            placeholder="Enter risk HTML content..."
            theme="monokai"
            onChange={(value: string) => setRiskHtml(value)}
            value={riskHtml}
            fontSize={14}
            showPrintMargin={false}
            showGutter={true}
            highlightActiveLine={true}
            setOptions={{
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              enableSnippets: true,
              showLineNumbers: true,
              tabSize: 2
            }}
          />
        </Grid>
        <Grid container item xs={6}>
          <Button
            disabled={loading}
            className={css.button}
            onClick={cancelAction}
            type="button"
            variant="contained"
            color="default">
            Cancel
          </Button>

          <Button 
            disabled={loading}
            className={css.button}
            type="submit"
            color="primary"
            variant="contained"
          >
            Save Risk {loading && <CircularProgress style={{ marginLeft: 15 }} size={15} />}
          </Button>
        </Grid>
      </form>
    </Grid>
	);
}

export default RiskForm;