import { createContext } from "react";
import { Patient } from "../../../models";
import { PatientProviderAction } from "./interfaces/PatientProviderAction.interface";

const defaultPatient = {
	id: "",
	title: "",
	firstName: "",
	lastName: "",
	dob: "",
	insuranceAuthorisationCode: "",
	NHSnumber: "",
	privateInsuranceNumber: "",
	phone: "",
	email: "",
	createdAt: "",
	updatedAt: "",
	sex: "",
	addressLine1: "",
	addressLine2: "",
	city: "",
	county: "",
	postcode: "",
};

export const PatientProviderContext = createContext<{ patient: Patient; patientProviderDispatch: (action: PatientProviderAction) => void; }>({ patient: defaultPatient, patientProviderDispatch: (action: PatientProviderAction) => action.payload});