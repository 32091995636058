import {
  Box,
  Button,
  Chip,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Input,
  InputLabel,
  ListSubheader,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { Fragment, useContext } from "react";
import { RoleTypes } from "../../../common/enums/roleTypes.enum";
import { GlobalStateContext } from "../../../common/providers/global-state-provider/global-state.context";
import { useCreateApplications, useFetchUserProcedures } from "../hooks";

interface Props {
  show: boolean;
  close: () => void;
  patientId: string;
}

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    category: {
      lineHeight: "25px",
      color: "#2594a2",
      borderBottom: "1px solid rgba(37, 148, 162, 0.5)",
      marginTop: "5px",
    },
    select: {
      maxHeight: 228,
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
    proceduresSelectArea: {
      marginTop: 25,
    },
  })
);

const CreateApplication = ({ show, close, patientId }: Props) => {
  const { globalState } = useContext(GlobalStateContext);
  const { procedures } = useFetchUserProcedures(show);

  const {
    addedText,
    loading,
    createApplication,
    handleChange,
    handleClose,
    procedureId,
    clinicConsultants,
    consultantId,
    subProcedures,
    subProceduresText,
  } = useCreateApplications(patientId, close);

  const css = useStyles();
  return (
    <Dialog
      open={show}
      onClose={() => {
        handleClose();
        close();
      }}
      aria-labelledby="consent-application"
    >
      <DialogTitle id="consent-application">
        Create Consenting Application
      </DialogTitle>
      <form onSubmit={createApplication}>
        <DialogContent>
          <DialogContentText id="consent-application-create">
            Select the procedure(s) the patient will undergo. A consenting
            application will be created and emailed to the patient for
            completion.
          </DialogContentText>
          {globalState.user.role === RoleTypes.CLINIC_ADMIN ? (
            <DialogContentText id="consent-application-create">
              Please also select the operating consultant that you are sending
              the consent form for.
            </DialogContentText>
          ) : null}
          <FormControl fullWidth>
            <InputLabel id="pick-procedure-label">Procedure</InputLabel>
            <Select
              className={css.select}
              onChange={handleChange}
              required
              name="procedure"
              labelId="pick-procedure-label"
              id="pick-procedure"
              defaultValue=""
              MenuProps={MenuProps}
            >
              {procedures ? (
                procedures.map((p) =>
                  p.procedures ? (
                    <ListSubheader
                      className={css.category}
                      disableSticky={true}
                      key={p.id}
                    >
                      {p.name}
                    </ListSubheader>
                  ) : (
                    <MenuItem key={p.id} value={p.id}>
                      {p.name}
                    </MenuItem>
                  )
                )
              ) : (
                <MenuItem disabled value="">
                  Loading procedures...
                </MenuItem>
              )}
            </Select>
          </FormControl>
          {globalState.user.role === RoleTypes.CLINIC_ADMIN ? (
            <FormControl fullWidth style={{ marginTop: 10 }}>
              <InputLabel id="pick-consultant-label">
                Select Consultant
              </InputLabel>
              <Select
                className={css.select}
                onChange={handleChange}
                required
                name="consultant"
                labelId="pick-consultant-label"
                id="pick-consultant"
                defaultValue=""
                MenuProps={MenuProps}
              >
                {clinicConsultants ? (
                  clinicConsultants.map((c) => [
                    <MenuItem key={c.id} value={c.id}>
                      {c.title + " " + c.firstName + " " + c.lastName}
                    </MenuItem>,
                  ])
                ) : (
                  <MenuItem disabled value="">
                    Loading consultants...
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          ) : null}
          {procedureId ? (
            <Fragment>
              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="grouped-select">Sub-Procedures</InputLabel>
                <Select
                  labelId="procedures-chip-label"
                  id="procedures-mutiple-chip"
                  name="subProcedures"
                  multiple
                  value={subProcedures}
                  onChange={handleChange}
                  input={<Input id="procedures-multiple-chip" />}
                  renderValue={(selected: any) => (
                    <div className={css.chips}>
                      {selected.map((value: any) =>
                        procedures
                          ? procedures.map(
                              (procedure: any) =>
                                procedure.id === value && (
                                  <Chip
                                    key={procedure.name}
                                    label={procedure.name}
                                    className={css.chip}
                                  />
                                )
                            )
                          : null
                      )}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {procedures ? (
                    procedures.map((p) =>
                      p.procedures ? (
                        <ListSubheader
                          className={css.category}
                          disableSticky={true}
                          key={p.id}
                        >
                          {p.name}
                        </ListSubheader>
                      ) : p.id !== procedureId ? (
                        <MenuItem key={p.id} value={p.id}>
                          {p.name}
                        </MenuItem>
                      ) : null
                    )
                  ) : (
                    <MenuItem disabled value="">
                      Loading procedures...
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Fragment>
          ) : null}
          {subProcedures.length > 0 ? (
            <Fragment>
              <Typography variant="body1">
                <strong>Text added for sub-procedures :</strong>{" "}
                {subProceduresText}
              </Typography>
              <FormControl margin="normal" fullWidth>
                <TextField
                  disabled={loading}
                  autoFocus
                  onChange={handleChange}
                  name="addedText"
                  label="Additional text"
                  value={addedText}
                />
              </FormControl>
            </Fragment>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Box padding={2}>
            <Button
              onClick={() => {
                handleClose();
                close();
              }}
              color="primary"
              style={{ marginRight: 10 }}
            >
              Cancel
            </Button>
            <Button
              disabled={loading || !show}
              type="submit"
              color="primary"
              variant="contained"
            >
              {loading ? (
                <CircularProgress size={25} color="primary" />
              ) : (
                "Create & Send"
              )}
            </Button>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateApplication;
