import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useFlagFormStyles = makeStyles((theme: Theme) =>
  createStyles({
    flagContainer: {},
    flaggedImportant: {
      backgroundColor: "#ffb3b3",
      border: "3px solid #FF0000",
      padding: 16,
    },
    flagThis: {
      paddingTop: 9,
    },
    form: {
      width: "100%",
    },
    textField: {
      backgroundColor: "white",
    },
    radioLabel: {
      backgroundColor: "white",
    },
    button: {
      marginRight: 15,
    },
    buttons: {
      marginTop: 15,
    },
  })
);
