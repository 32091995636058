import { useReducer } from "react";
import { GlobalStateReducer } from "../global-state.reducer";
import { initialState } from "../interfaces/global-state.interface";

export default function useGlobalStateProvider() {

	const [globalState, dispatchToGlobal] = useReducer(GlobalStateReducer, initialState);	

	return {
		globalState,
		dispatchToGlobal,
	};
}