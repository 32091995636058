import React from "react";

import { Box, Grid, List, Typography } from "@material-ui/core";
import { Patient, QualityAssurance } from "../../../models";
import useConsent from "./hooks";
import { Loading } from "../../patients/PatientOverview";
import QualityAssuranceItem from "../QualityAssuranceItem";

interface Props {
  applicationId: string;
  patient: Patient;
  locked: boolean;
  disableQA: boolean;
}

const QualityAssurances = ({ locked, disableQA }: Props) => {
  const { qualityAssurances } = useConsent();

  if (!qualityAssurances) {
    return <Loading />;
  }

  return (
    <Box component="section" mb={5}>
      <Typography component="h2" variant="h2">
        Consent
      </Typography>
      <Grid container>
        <Grid item xs={12}>
          <List>
            {qualityAssurances.map((qualityAssurance: QualityAssurance) => (
              <QualityAssuranceItem
                key={qualityAssurance.id}
                qualityAssurance={qualityAssurance}
                disabled={locked || disableQA}
              />
            ))}
          </List>
        </Grid>
      </Grid>
    </Box>
  );
};

export default QualityAssurances;
