import { useContext, useMemo } from "react";
import { apiInstance } from "../../../../common/api";
import { Severity } from "../../../../common/enums/severity.enum";
import { APINotificationContext } from "../../../../common/providers/APINotification/APINotification.context";
import { APINotificationActionTypes } from "../../../../common/providers/APINotification/enums/APINotificationActionType.enum";
import { ConsentFormContext } from "../../../../common/providers/ConsentFormProvider/ConsentForm.context";
import { ConsentFormProviderActionType } from "../../../../common/providers/ConsentFormProvider/enums/ConsentFormProviderActionType.enum";

import { QualityAssurance } from "../../../../models";

export default function useQualityAssuranceItem(
  qualityAssurance: QualityAssurance
) {
  const { consentForm, dispatch } = useContext(ConsentFormContext);
  const { dispatchNotification } = useContext(APINotificationContext);

  const answer = useMemo(
    () =>
      consentForm.answers.find(
        (answer) => answer.qualityAssuranceId === qualityAssurance.id
      ),
    [consentForm.answers, qualityAssurance.id]
  );

  async function handleRadioChange(event: any) {
    answer
      ? await updateAnswer(JSON.parse(event.target.value))
      : await createAnswer(JSON.parse(event.target.value));
  }

  async function createAnswer(value: boolean) {
    try {
      const data = {
        understood: value,
        consentFormId: consentForm.id,
        qualityAssuranceId: qualityAssurance.id,
      };

      const res = await apiInstance.post(`/answers`, data);

      if (res.status === 201) {
        dispatch({
          type: ConsentFormProviderActionType.ADD_ANSWER,
          payload: res.data,
        });
      }
    } catch (err) {
      console.log(err);
      dispatchNotification({
        type: APINotificationActionTypes.SET_NOTIFICATION,
        payload: {
          message: "Error trying to create answer",
          severity: Severity.ERROR,
        },
      });
    }
  }

  async function updateAnswer(newValue: boolean) {
    try {
      if (!answer) throw Error("Can no update answer: Answer is undefined.");

      const data = {
        understood: newValue,
      };

      const res = await apiInstance.patch(`/answers/${answer.id}`, data);

      if (res.status === 200) {
        dispatch({
          type: ConsentFormProviderActionType.UPDATE_ANSWER,
          payload: res.data,
        });
      }
    } catch (err) {
      console.log(err);
      dispatchNotification({
        type: APINotificationActionTypes.SET_NOTIFICATION,
        payload: {
          message: "Error trying to update answer",
          severity: Severity.ERROR,
        },
      });
    }
  }

  return {
    answer,
    handleRadioChange,
  };
}
