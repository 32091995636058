import { FormEvent, useEffect, useState } from "react"
import { apiInstance } from "../../../../common/api";

import { Boolean } from "../../../../common/enums/boolean.enum"
import { Severity } from "../../../../common/enums/severity.enum";
import useAPINotification from "../../../../common/hooks/useAPINotification";
import { APINotificationActionTypes } from "../../../../common/providers/APINotification/enums/APINotificationActionType.enum";
import { Benefit } from "../../../../models"

export default function useBenefitItem(benefit: Benefit) {

  const { dispatchNotification } = useAPINotification();
  const [ show, setShow ] = useState<Boolean>(Boolean.FALSE);
  const [ edition, setEdition ] = useState<Boolean>(Boolean.FALSE);
  const [ loading, setLoading ] = useState<Boolean>(Boolean.FALSE);
  const [ benefitHtml, setBenefitHtml ] = useState<string>("");
  const [ name, setName ] = useState<string>("");

  useEffect(() => {

    setName(benefit.name)
    setBenefitHtml(benefit.content);
  }, [benefit]);

  function toggleShow(){

    show === Boolean.FALSE ? setShow(Boolean.TRUE) : setShow(Boolean.FALSE);
  }

  function toggleEdition(){

    edition === Boolean.FALSE ? setEdition(Boolean.TRUE) : setEdition(Boolean.FALSE);
  }

  function collapsed(){

     return show === Boolean.TRUE;
  }

  function isLoading(){
    return loading === Boolean.TRUE;
  }

  function editing(){
    return edition === Boolean.TRUE;
  }

  async function handleSubmit(event: FormEvent<HTMLFormElement>, data: any) {
    event.preventDefault();
    
    setLoading(Boolean.TRUE);
    try {
      const res = await apiInstance.patch(`/benefits/${benefit.id}`, data);

      if (res.status === 200) {
        setName(res.data.name);
        setBenefitHtml(res.data.content);
        setLoading(Boolean.FALSE);
      }
      
    } catch (error) {
      console.log(error);
      dispatchNotification({ type: APINotificationActionTypes.SET_NOTIFICATION, payload: { message: "Error trying to update benefit.", severity: Severity.ERROR } });
    } finally {
      toggleEdition();
    }
  }
  
	return {
    collapsed,
    editing,
    handleSubmit,
    isLoading,
    name,
    benefitHtml,
    setBenefitHtml,
    toggleEdition,
		toggleShow
	}
}