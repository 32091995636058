import { useState } from "react";
import { Patient } from "../../../../models";
import { Order } from "../interfaces/order.type.";



export default function usePatientsTable() {

	const [order, setOrder] = useState<Order>('asc');
	const [orderBy, setOrderBy] = useState<keyof Patient>('createdAt');
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(15);

	function handleRequestSort (event: React.MouseEvent<unknown>, property: keyof Patient) {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

	function handleChangePage (event: unknown, newPage: number) {
    setPage(newPage);
  };

	function handleChangeRowsPerPage (event: React.ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

	function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return 0;
	}
	
	function getComparator<Key extends keyof any>(
		order: Order,
		orderBy: Key,
	): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
		return order === 'desc'
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}
	
	function tableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
		const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) return order;
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}

	return {
		getComparator,
		handleChangePage,
		handleChangeRowsPerPage,
		handleRequestSort,		
		order,
		orderBy,
		page,
		rowsPerPage,
		tableSort,
	}
}