export const frequencyMapping: any = {
  "Very Rare": {
    text: "Very Rare",
    value: "Less than 1 in 10,000",
    verbal: "A person in large town",
    color: "green",
  },
  Rare: {
    text: "Rare",
    value: "1 in 1000 to 1 in 10,000",
    verbal: "A person in small town",
    color: "lightgreen",
  },
  Uncommon: {
    text: "Uncommon",
    value: "1 in 100 to 1 in 1000",
    verbal: "A person in village",
    color: "yellow",
  },
  Common: {
    text: "Common",
    value: "1 in 10 to 1 in 100",
    verbal: "A person in street",
    color: "orange",
  },
  "Very Common": {
    text: "Very Common",
    value: "1 in 1 to 1 in 10",
    verbal: "A person in family",
    color: "red",
  },
  Other: {
    text: "Other",
    value: "",
    verbal: "",
    color: "grey",
  },
};
