import React from "react";
import { Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions, Button, IconButton, createStyles, makeStyles, Typography } from "@material-ui/core";
import { Close } from '@material-ui/icons';

interface Props {
	show: boolean;
	handleClose: () => void;
	continueAction: () => void;
	title: string;
	message: string;
}

const useStyles = makeStyles(() =>
	createStyles({
		dialogTitle: {
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center"
		},
	}),
);

const PopConfirm = ({ show, handleClose, continueAction, title, message }: Props) => {
	const css = useStyles();

	return (
		<Dialog
			open={show}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description">
			<DialogTitle disableTypography className={css.dialogTitle} id="alert-dialog-title">
				<Typography component="h2" variant="h6">{title}</Typography>
				<IconButton onClick={handleClose}>
					<Close />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{message}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="default">
					Cancel
        </Button>
				<Button onClick={continueAction} color="primary" autoFocus>
					I understand, proceed
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default PopConfirm;