import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useTopicQuestionItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    '@global':{
      '.Mui-disabled':{
        color: 'initial !important'
      },
      '.Mui-checked.Mui-disabled':{
        color: '#ec681e !important'
      }
    },
    none:{},
    questionContainer:{
      padding: 16,
    },
    lowSeverity: {
      backgroundColor: "#ffffe0",
      borderColor: "#e6e6b5"
    },
    mediumSeverity: {
      backgroundColor: "#fff2e1",
      borderColor: "#f5d4a9"
    },
    highSeverity: {
      backgroundColor: "#fdc1c1",
      borderColor: "#e6afaf"
    },
    options: {
      flexDirection: 'row'
    },
    questionTitle: {
      fontWeight: 500,
    },
    questionDesc: {
      fontSize: '0.9rem'
    },
    flagFormContainer: {
      marginTop: 15
    }
  }),
);