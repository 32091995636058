import { useCallback, useContext, useEffect, useState } from "react";
import { apiInstance } from "../../../common/api";
import { Severity } from "../../../common/enums/severity.enum";
import useAPINotification from "../../../common/hooks/useAPINotification";
import { APINotificationActionTypes } from "../../../common/providers/APINotification/enums/APINotificationActionType.enum";
import { GlobalStateProviderActionType } from "../../../common/providers/global-state-provider/enums/global-state-provider-action-type.enum";
import { GlobalStateContext } from "../../../common/providers/global-state-provider/global-state.context";

export default function useAdminRoute() {

  const { dispatchNotification } = useAPINotification();
  const { globalState, dispatchToGlobal } = useContext(GlobalStateContext);
  const [ unauthorize, setUnauthorize ] = useState<boolean>(false);
  const userRole = globalState.user.role;

  const getUser = useCallback(async () => {
    
    try {
      const res = await apiInstance.get("/me");
      dispatchToGlobal({ type: GlobalStateProviderActionType.SET_USER, payload: res.data });
    } catch (error) {
      console.log(error);
      setUnauthorize(true);
      dispatchNotification({ type: APINotificationActionTypes.SET_NOTIFICATION, payload: { message: "Error trying to get you user", severity: Severity.ERROR } });
    } 
  }, [dispatchToGlobal, dispatchNotification]);

	// When reload the global store is wiped so we need to get again the user in order to let him access to the restricted page
	useEffect(() => {
    
		if (sessionStorage.getItem("auth") && globalState.user.id === "") {
			getUser();
		}
  }, [getUser, globalState.user.id]);

	return {
    unauthorize,
    userRole
	};
}