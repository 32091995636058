import { Box, Button, Divider, List, ListItem, Typography } from "@material-ui/core";
import React from "react";
import { TopicType } from "../../../common/enums/topic-type.enum";
import QuestionForm from "../question-form";
import QuestionItem from "./question-item";
import useQuestions from "./hooks";
import { useQuestionsStyles } from "./questions.css";

/* 
	Individual Risk
*/
interface iProps {
	canCreateNew?: boolean;
	topicId: string;
	topicType?: TopicType;
}

const Questions = ({ canCreateNew, topicId, topicType }: iProps) => {

	const classes = useQuestionsStyles();
	const { handleSubmit, questions, showForm, toggleShowForm } = useQuestions(topicId, topicType);

	return (
    <Box>
			{
				questions.length > 0 
				? (<Typography variant="h6" component="p" style={{fontSize: 16}}>Questions:</Typography>)
				: null
			}
			<List>
				{
					questions.map( (question, index) => <ListItem className={classes.listItem}>{index > 0 ? <Divider className={classes.divider} /> : null}<QuestionItem question={question} canBeEdited={canCreateNew} /></ListItem>)
				}
			</List>
			{
				showForm
				? (
						<QuestionForm cancelAction={toggleShowForm} handleSubmit={handleSubmit} topicId={topicId} topicType={topicType} />
					)
				: null
			}
			{
				canCreateNew
				? (
						<Button
							onClick={toggleShowForm}
							color="primary"
							variant="contained"
						>
							Add Question
						</Button>
					)
				: null
			}
		</Box>
	);
}

export default Questions;