import { Reducer } from "react";
import { Procedure } from "../../../models";
import { ProcedureProviderActionType } from "./enums/ProcedureProviderActionType.enum";
import { ProcedureProviderAction } from "./interfaces/ProcedureProviderAction.interface";

export const ProcedureProviderReducer: Reducer<Procedure, ProcedureProviderAction> = (procedure, action) => {

	const { type, payload } = action;
  switch (type) {

    case ProcedureProviderActionType.SET_PROCEDURE:
			
      return payload;   

    case ProcedureProviderActionType.UPDATE_PROCEDURE:

      return {
        ...procedure,        
        ...payload,
      };

    default:
      return procedure;
  }
}