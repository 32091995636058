import React, { FormEvent, useContext, useState } from "react";
import { Button, Card, CardContent, Collapse, createStyles, Divider, IconButton, List, makeStyles, Theme, Typography } from "@material-ui/core";
import { Risk as iRisk, RiskFrequency } from "../../models";
import RisksLoading from "./RisksLoading";
import { CheckCircleOutline, ExpandLess, ExpandMore, Warning } from "@material-ui/icons";
import clsx from "clsx";
import Content from "./Content";
import ProceduresList from "./ProceduresList";
import { Alert, AlertTitle } from "@material-ui/lab";
import { apiInstance } from "../../common/api";
import { GlobalStateContext } from "../../common/providers/global-state-provider/global-state.context";
import { GlobalStateProviderActionType } from "../../common/providers/global-state-provider/enums/global-state-provider-action-type.enum";
import useAPINotification from "../../common/hooks/useAPINotification";
import { APINotificationActionTypes } from "../../common/providers/APINotification/enums/APINotificationActionType.enum";
import { Severity } from "../../common/enums/severity.enum";

const useStyles = makeStyles((theme: Theme) => createStyles({
	root: {
		borderTop: "2px solid",
		borderColor: "#c7c7c7",
		marginBottom: 15,
		overflow: "visible",
	},
	complete: {
		borderColor: theme.palette.success.main,
		'& .risk-complete': {
			color: theme.palette.success.main
		}
	},
	incomplete: {
		borderColor: theme.palette.warning.main,
		'& .risk-warn': {
			color: theme.palette.warning.main
		}
	},
	titleBar: {
		cursor: "pointer",
	},
	title: {
		fontSize: 20,
		fontWeight: 500,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between"
	},
	titleArea: {
		display: "flex",
		alignItems: "center"
	},
	cardBody: {
		display: "flex",
		top: 0
	},
	cArea: {
		flex: "1",
		paddingTop: 0,
		position: "relative"
	},
	cAreaContent: {
		position: "sticky",
		top: 15
	},
	progressIcon: {
		marginRight: 10
	},
	//TODO: Why is button blue?
	approveBtn: {
		background: theme.palette.primary.main,
		display: "block",
		margin: "25px 0 15px auto",
		'&:hover': {
			background: theme.palette.primary.dark
		}
	},
	activeProcedure: {
		background: "#eaeaea",
		display: "block",
		padding: "5px 10px",
		boxSizing: "border-box"
	},
	//TODO
	form: {
		position: "sticky",
		top: 15,
	},
	alert: {
		marginBottom: 25
	}
}));


/* 
	Loop over Risks
*/
interface Props {
	risks: iRisk[] | null;
}

const RisksList = ({ risks }: Props) => {
	const css = useStyles();
	const [completedCount, setCompletedCount] = useState(0);

	if (!risks) {
		return <RisksLoading />;
	}

	let completedCounter = 0;
	for (const risk of risks) {
		const createdFreqs = risk.riskFrequencies?.filter(p => p.clinicId !== null && risk.procedures?.some(procedure => procedure.id === p.procedureId));
		const done = createdFreqs?.length === risk.procedures?.length;
		
		if (done) {
			completedCounter++;
		}
	}

	const risksLeft = (risks.length - completedCounter) + completedCount;

	return (
		<List subheader={<div />}>

			{risksLeft <= 0 ? (
				<Alert className={css.alert} severity="success">
					<AlertTitle>Great work!</AlertTitle>
					You have no outstanding risks to parameterise at this moment.
				</Alert>
			) : (
					<Alert className={css.alert} severity="warning">
						<AlertTitle>Outstanding risks to be reviewed</AlertTitle>
					You have <strong>{risksLeft}</strong> outstanding risks to parameterise. Please complete the procedure/risk associations below.
					</Alert>
				)}

			{risks.sort((a, b) => a.order - b.order).map((risk, index) => <Risk setCompletedCount={setCompletedCount} key={risk.id} data={risk} />)}
		</List>
	);
}

/* 
	Individual Risk
*/
interface RiskProps {
	data: iRisk;
	setCompletedCount: any;
}

const Risk = ({ data, setCompletedCount }: RiskProps) => {
	const css = useStyles();
	
	const { dispatchNotification } = useAPINotification();
	const { dispatchToGlobal } = useContext(GlobalStateContext);

	const { id, name, procedures, content, riskFrequencies } = data;

	const initialValues = riskFrequencies;
	const [paramValues, setParamValues] = useState<RiskFrequency[]>(initialValues!);

	const createdFreqs = paramValues.filter(p => p.clinicId !== null && procedures?.some(procedure => procedure.id === p.procedureId));

	// Accordion State
	const [completed, setCompleted] = useState<boolean>(createdFreqs.length === procedures?.length);
	const [show, setShow] = useState<boolean>(false);
	const toggleShow = () => setShow(value => !value);

	const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setCompleted(true);
		setShow(false);
		setCompletedCount((cc: any) => cc - 1);
		dispatchToGlobal({ type: GlobalStateProviderActionType.SET_PARAMS_CURRENT_FREQ, payload: null });

		try {
			const res = await apiInstance.post("/risk-frequencies/bulk", { bulk: paramValues });
			if (res.status === 200 || res.status === 201) {
				setParamValues(res.data);
				dispatchNotification({ type: APINotificationActionTypes.SET_NOTIFICATION, payload: { message: "Parameters successfully saved!", severity: Severity.SUCCESS } });
			}
		} catch (error) {

		} finally {

		}
	}


	return (
		<Card key={id} className={clsx(css.root, completed ? css.complete : css.incomplete)}>
			<CardContent className={css.titleBar} onClick={toggleShow}>
				<Title text={name} completed={completed} collapsed={show} />
			</CardContent>
			<Collapse in={show} timeout="auto" unmountOnExit>
				<div className={css.cardBody}>
					<CardContent className={css.cArea}>
						<div className={css.cAreaContent}>
							<Typography className={css.activeProcedure} component="h5">
								Below is the copy your patient will see (relevant frequency will be shown, as set on the right for the specific procedure)
							</Typography>
							<Content html={content} riskId={id} />
						</div>
					</CardContent>
					<CardContent className={css.cArea}>
						<Alert severity="info">
							<AlertTitle>Parameterise Procedures</AlertTitle>
							Review and approve the risk frequencies below for the risk {name}.
						</Alert>
						<Divider style={{ margin: "15px 0" }} />
						<form onSubmit={handleSubmit} className={css.form}>
							<ProceduresList
								riskId={id}
								setParamValues={(v: any) => setParamValues(v)}
								paramValues={paramValues}
								procedures={procedures!}
								riskFrequencies={riskFrequencies!}
							/>
							<Button fullWidth type="submit" className={css.approveBtn} color="primary" variant="contained">Approve Procedures</Button>
						</form>
					</CardContent>
				</div>
			</Collapse>
		</Card>
	);
}

/* 
	Risk Title 
*/
interface iTitle {
	text: string;
	completed: boolean;
	collapsed: boolean;
}

const Title = ({ text, completed, collapsed }: iTitle) => {
	const css = useStyles();

	return (
		<Typography variant="h6" component="h2" className={css.title}>
			<div className={css.titleArea}>
				{completed ? <CheckCircleOutline className={clsx(css.progressIcon, "risk-complete")} /> : <Warning className={clsx(css.progressIcon, "risk-warn")} />}
				{text}
				<IconButton>
					{collapsed ? <ExpandLess style={{ color: "rgba(0, 0, 0, 0.54)" }} /> : <ExpandMore style={{ color: "rgba(0, 0, 0, 0.54)" }} />}
				</IconButton>
			</div>
		</Typography>
	);
}

export default RisksList;