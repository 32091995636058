import React, { ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Grid, Link, Paper, Typography } from "@material-ui/core";
import { useStyles } from "./dashboard-card.css";

interface Props {
  children: ReactNode;
  link?: boolean;
  title: string;
  to?: string;
}

const DashboardCard = ({ children, link, title, to }: Props) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Link
        color="textPrimary"
        component={RouterLink}
        style={{
          pointerEvents: link ? "all" : "none",
          cursor: link ? "pointer" : "none",
        }}
        to={to || "/dashboard"}
        underline="none"
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography className={classes.title} variant="h3" gutterBottom>
              {title}
            </Typography>
          </Grid>
          {children}
        </Grid>
      </Link>
    </Paper>
  );
};

export default DashboardCard;
