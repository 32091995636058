import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    congrats: {
      fontWeight: 100,
      marginTop: 15,
    },
    list: {
      width: "100%",
    },
    listItem: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    listItemCount: {
      fontWeight: "bold",
    },
    listItemText: {
      display: "flex",
      alignItems: "center",
    },
    paper: {
      height: 140,
      width: 100,
    },
  })
);
