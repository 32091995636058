import React, { Fragment, useState } from "react";
import { Avatar, createStyles, makeStyles, Theme } from '@material-ui/core';
import CropImage from "./CropImage";

const useStyles = makeStyles((theme: Theme) => createStyles({
	avatar: {
		width: 200,
		height: 200,
		background: "#fafafa",
		border: "1px solid grey"
	},
	avatarContainer: {
		position: "relative",
		width: "200px",
		height: "200px",
		borderRadius: "50%",
		overflow: "hidden",
	},
	uploadBtn: {
		position: "absolute",
		bottom: 12,
		fontSize: "12px",
		width: "100%",
		padding: 10,
		color: "#fff",
		textAlign: "center",
		cursor: "pointer",
		background: theme.palette.primary.dark,
		"&:hover": {
			background: theme.palette.primary.main,
		}
	}
}));

interface Props {
	existing: string;
	setAvatar: (blob: any) => void;
	userId?: string;
}

const ProfilePic = ({ existing, setAvatar, userId }: Props) => {
	const css = useStyles();

	const [img, setImg] = useState<string>(existing || "/icons/user.svg");

	const [modalOpen, setModalOpen] = useState<boolean>(false);
	const openModal = () => setModalOpen(true);
	const closeModal = () => setModalOpen(false);

	function handleFileupload() {
		const fileUpload: HTMLInputElement | null = document.querySelector("#avatar-upload");
		const reader = new FileReader();

		if (fileUpload?.files && fileUpload.files?.length) {
			const file = fileUpload.files[0];

			reader.addEventListener("load", function () {
				// convert image file to base64 string
				setImg(reader.result as string);
				openModal();
			}, false);

			if (file) {
				reader.readAsDataURL(file);
			}
		}
	}


	return (
		<Fragment>
			<div className={css.avatarContainer}>
				<Avatar className={css.avatar} alt="Placeholder" src={img} />
				<label className={css.uploadBtn} htmlFor="avatar-upload">Upload a photo</label>
				<input
					type="file"
					id="avatar-upload"
					name="avatar"
					accept="image/*"
					onChange={handleFileupload}
					style={{ display: "none" }}
				/>
			</div>

			<CropImage
				userId={userId || ""}
				setAvatar={setAvatar}
				show={modalOpen}
				closeModal={closeModal}
				img={img}
				setImg={(img: string) => setImg(img)}
			/>
		</Fragment>
	);
}

export default ProfilePic;