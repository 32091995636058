import React, { useContext } from "react";
import {
  AssignmentInd,
  People,
  Warning,
  InsertEmoticon,
  LocalHospital,
  AccountTree,
  Hearing,
  LocalHospitalOutlined,
  Assignment,
  Announcement,
  Dashboard,
} from "@material-ui/icons";
import { GlobalStateContext } from "../../../common/providers/global-state-provider/global-state.context";
import { MenuItemInterface } from "../interfaces/menu-item.interface";
import { RoleTypes } from "../../../common/enums/roleTypes.enum";
import { Tooltip } from "@material-ui/core";
import { useStyles } from "../sidebar.css";

export default function useSidebar() {
  const { globalState } = useContext(GlobalStateContext);
  const classes = useStyles();

  const adminItems: MenuItemInterface[] = [
    {
      title: "Clinics",
      icon: <LocalHospitalOutlined />,
      to: "/clinics",
      activeRoutes: ["clinics"],
    },
    {
      title: "Users",
      icon: <People />,
      to: "/users",
      activeRoutes: ["/users"],
    },
    {
      title: "Risks",
      icon: <Warning />,
      to: "/risks",
      activeRoutes: ["/risks"],
    },
    {
      title: "Benefits",
      icon: <InsertEmoticon />,
      to: "/benefits",
      activeRoutes: ["/benefits"],
    },
    {
      title: "Considerations",
      icon: <Hearing />,
      to: "/considerations",
      activeRoutes: ["/considerations"],
    },
    {
      title: "Procedures",
      icon: <LocalHospital />,
      to: "/procedures",
      activeRoutes: ["/procedures"],
    },
    {
      title: "Specialities",
      icon: <AccountTree />,
      to: "/specialities",
      activeRoutes: ["/specialities"],
    },
  ];

  const items: MenuItemInterface[] = [
    {
      title: "Users",
      icon: <People />,
      to: "/users",
      activeRoutes: ["/users"],
    },
    {
      title: "Patients",
      icon: <AssignmentInd />,
      to: "/patients",
      activeRoutes: ["/patients", "/patients/:patientId"],
    },
    {
      title: "Risks",
      icon: <Warning />,
      to: "/risks-review",
      activeRoutes: ["/risks-review"],
    },
    {
      title: "Applications",
      icon: <Assignment />,
      to: "/applications",
      activeRoutes: ["/applications"],
    },
  ];

  const consultantItems: MenuItemInterface[] = [
    { title: "Dashboard", icon: <Dashboard />, to: "/", activeRoutes: ["/"] },
    {
      title: "Patients",
      icon: <AssignmentInd />,
      to: "/patients",
      activeRoutes: ["/patients", "/patients/:patientId"],
    },
    {
      title: "Risks",
      icon: <Warning />,
      to: "/risks-review",
      activeRoutes: ["/risks-review"],
    },
    {
      title: "Applications",
      icon: <Assignment />,
      to: "/applications",
      activeRoutes: ["/applications"],
      notificationIcon: generateApplicationsHighlightIcon(),
    },
    // { title: "SubProcedure Texts", icon: <LocalHospital />, to: "/procedures/sub-procedure-texts", activeRoutes: ["/procedures/sub-procedure-texts"]},
  ];

  //Possibly a new menu for clinic admin
  const clinicAdminItems: MenuItemInterface[] = [
    { title: "Dashboard", icon: <Dashboard />, to: "/", activeRoutes: ["/"] },
    {
      title: "Users",
      icon: <People />,
      to: "/users",
      activeRoutes: ["/users"],
    },
    {
      title: "Patients",
      icon: <AssignmentInd />,
      to: "/patients",
      activeRoutes: ["/patients", "/patients/:patientId"],
    },
    {
      title: "Applications",
      icon: <Assignment />,
      to: "/applications",
      activeRoutes: ["/applications"],
      notificationIcon: generateApplicationsHighlightIcon(),
    },
  ];

  function generateApplicationsHighlightIcon() {
    if (globalState.applicationsHighlights.length > 0) {
      return (
        <Tooltip title="There are some applications that require you to take action">
          <Announcement className={classes.announcement} />
        </Tooltip>
      );
    }
    return null;
  }

  const menuItems =
    globalState.user.role === RoleTypes.ADMIN
      ? adminItems
      : globalState.user.role === RoleTypes.CLINIC_MANAGER
      ? items
      : globalState.user.role === RoleTypes.CLINIC_ADMIN
      ? clinicAdminItems
      : consultantItems;

  return {
    menuItems,
  };
}
