import React from "react";
import LoggedInTemplate from "../templates/LoggedIn";
import useApplications from "./hooks";
import { ApplicationsTable } from "./applications-table";
import SearchBox from "../search-box";
import ActiveFilters from "./applications-table/ActiveFilters";

const Applications = () => {
  const {
    applications,
    handleFilter,
    loading,
    getColor,
    selectedStatusVals,
    setSelectedStatusVals,
    selectedUserVals,
    setSelectedUserVals,
  } = useApplications();

  return (
    <LoggedInTemplate title="Applications">
      <div style={{ display: "flex", alignItems: "center" }}>
        <SearchBox
          placeHolderText="Search applications"
          handleSearch={(searchTerm: string) => {
            if (searchTerm === "" || (!loading && searchTerm.length > 3)) {
              handleFilter({ field: "search", value: searchTerm }); //TODO we are fetching all patients and paginating them on the client side. We should paginated them on server side (research).
            }
          }}
          loading={loading}
        />
        <ActiveFilters
          handleFilter={(filter) => handleFilter(filter)}
          statusFilters={selectedStatusVals}
          userFilters={selectedUserVals}
          getColor={getColor}
        />
      </div>
      <ApplicationsTable
        selectedStatusVals={selectedStatusVals}
        setSelectedStatusVals={setSelectedStatusVals}
        selectedUserVals={selectedUserVals}
        setSelectedUserVals={setSelectedUserVals}
        searching={loading}
        applications={applications}
        loading={loading}
        handleFilter={handleFilter}
      />
    </LoggedInTemplate>
  );
};

export default Applications;
