import { useState } from "react";

export default function useSearchBox() {
  const [ clean, setClean ] = useState<boolean>(true);
  const [ value, setValue ] = useState<string>("");

  return {
    clean,
    setClean,
    setValue,
    value,
  };
}