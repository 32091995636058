import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useQuestionItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    questionContainer:{
      '&:hover $buttons': {			
        opacity: 1,
      }
    },
    button: {
      marginRight: 15,
      marginTop: 5,
    },
    buttons: {
      opacity: 0,
    },
    deleteButton: {
      backgroundColor: "#ec1e1e",
      color: "white",
      "&:hover":{
        backgroundColor: "#bb2525"
      }
    },
    options: {
      flexDirection: 'row'
    },
    questionTitle: {
      fontWeight: 500,
    },
    questionDesc: {
      fontSize: '1rem'
    },
  }),
);