import { useState } from "react";

export default function useAddUser() {

  const [abort, setAbort] = useState<boolean>(false);

  return {
    abort,
    setAbort
  }
}