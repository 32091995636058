import { useEffect, useState } from "react"
import { Boolean } from "../../../../common/enums/boolean.enum"

export default function useBenefitForm( initialValues: { name: string; benefitHtml: string; } | undefined ) {
  
  const [ loading ] = useState<Boolean>(Boolean.FALSE);
  const [ benefitHtml, setBenefitHtml ] = useState<string>("");
  const [ name, setName ] = useState<string>("");

  useEffect(() => {
    if(initialValues){
      setBenefitHtml(initialValues.benefitHtml);
      setName(initialValues.name);
    }
  }, [initialValues]);

  function isLoading(){
    return loading === Boolean.TRUE;
  }
  
	return {
    isLoading,
    benefitHtml,
    name,    
    setBenefitHtml,
    setName,
	}
}