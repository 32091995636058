import { ChangeEvent, Reducer, useEffect, useReducer, useState } from "react";
import { apiInstance } from "../../../common/api";

import { Boolean } from "../../../common/enums/boolean.enum";
import { Severity } from "../../../common/enums/severity.enum";
import useAPINotification from "../../../common/hooks/useAPINotification";
import { APINotificationActionTypes } from "../../../common/providers/APINotification/enums/APINotificationActionType.enum";
import {
  ProceduresAction,
  ProceduresActionTypes,
  ProceduresState,
} from "../interfaces";

const initialState = {
  benefits: null as any,
  considerations: null as any,
  procedureCategories: null as any,
  risks: null as any,
  specialities: null as any,
};

const reducer: Reducer<ProceduresState, ProceduresAction> = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case ProceduresActionTypes.SET_PROC_CATEGORIES:
      return { ...state, procedureCategories: payload };

    case ProceduresActionTypes.SET_BENEFITS:
      return { ...state, benefits: payload };

    case ProceduresActionTypes.SET_CONSIDERATIONS:
      return { ...state, considerations: payload };

    case ProceduresActionTypes.SET_RISKS:
      return { ...state, risks: payload };

    case ProceduresActionTypes.SET_SPECIALITIES:
      return { ...state, specialities: payload };

    case ProceduresActionTypes.SWAP_PROCEDURE:
      let fromIndex = 0;
      let toIndex = 0;
      const newArray = state.procedureCategories;
      const fromCategory = state.procedureCategories.find((category, index) => {
        if (category.id === payload.fromCategory) {
          fromIndex = index;
        }
        return category.id === payload.fromCategory;
      });
      const toCategory = state.procedureCategories.find((category, index) => {
        if (category.id === payload.toCategory) {
          toIndex = index;
        }
        return category.id === payload.toCategory;
      });

      if (fromCategory && toCategory) {
        toCategory.procedures = toCategory.procedures?.concat(
          payload.procedure
        );
        fromCategory.procedures = fromCategory.procedures?.filter(
          (procedure) => procedure.id !== payload.procedure.id
        );

        newArray.splice(fromIndex, 1, fromCategory);
        newArray.splice(toIndex, 1, toCategory);
      }

      return { ...state, procedureCategories: newArray };

    default:
      return state;
  }
};

export default function useProcedures() {
  const { dispatchNotification } = useAPINotification();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [addNewStatus, setAddNewStatus] = useState<Boolean>(Boolean.FALSE);
  const [name, setName] = useState<string>("");

  useEffect(() => {
    const fetchProcCategories = async () => {
      try {
        const res = await apiInstance.get(
          "/procedure-categories?join=procedures&join=procedures.risks&join=procedures.benefits&join=procedures.considerations"
        );

        dispatch({
          type: ProceduresActionTypes.SET_PROC_CATEGORIES,
          payload: res.data,
        });
      } catch (err) {
        console.log(err);
        dispatchNotification({
          type: APINotificationActionTypes.SET_NOTIFICATION,
          payload: {
            message: "Error trying to get procedure categories.",
            severity: Severity.ERROR,
          },
        });
      }
    };
    const fetchRisks = async () => {
      try {
        const res = await apiInstance.get("/risks");

        dispatch({ type: ProceduresActionTypes.SET_RISKS, payload: res.data });
      } catch (err) {
        console.log(err);
        dispatchNotification({
          type: APINotificationActionTypes.SET_NOTIFICATION,
          payload: {
            message: "Error trying to get risks.",
            severity: Severity.ERROR,
          },
        });
      }
    };

    const fetchBenefits = async () => {
      try {
        const res = await apiInstance.get("/benefits");

        dispatch({
          type: ProceduresActionTypes.SET_BENEFITS,
          payload: res.data,
        });
      } catch (err) {
        console.log(err);
        dispatchNotification({
          type: APINotificationActionTypes.SET_NOTIFICATION,
          payload: {
            message: "Error trying to get benefits.",
            severity: Severity.ERROR,
          },
        });
      }
    };

    const fetchConsiderations = async () => {
      try {
        const res = await apiInstance.get("/considerations");

        dispatch({
          type: ProceduresActionTypes.SET_CONSIDERATIONS,
          payload: res.data,
        });
      } catch (err) {
        console.log(err);
        dispatchNotification({
          type: APINotificationActionTypes.SET_NOTIFICATION,
          payload: {
            message: "Error trying to get considerations.",
            severity: Severity.ERROR,
          },
        });
      }
    };

    const fetchSpecialities = async () => {
      try {
        const res = await apiInstance.get("/specialities?join=subSpecialities");

        dispatch({
          type: ProceduresActionTypes.SET_SPECIALITIES,
          payload: res.data,
        });
      } catch (err) {
        console.log(err);
        dispatchNotification({
          type: APINotificationActionTypes.SET_NOTIFICATION,
          payload: {
            message: "Error trying to get specialities.",
            severity: Severity.ERROR,
          },
        });
      }
    };

    fetchProcCategories();
    fetchRisks();
    fetchBenefits();
    fetchConsiderations();
    fetchSpecialities();
  }, [dispatchNotification]);

  function toggleAddNew() {
    addNew() ? setAddNewStatus(Boolean.FALSE) : setAddNewStatus(Boolean.TRUE);
  }

  function addNew() {
    return addNewStatus === Boolean.TRUE;
  }

  function onFieldChange(event: ChangeEvent<HTMLInputElement>) {
    setName(event.target.value);
  }

  async function handleSubmit(event: any) {
    event.preventDefault();

    try {
      const res = await apiInstance.post(`/procedure-categories`, { name });

      if (res.status === 201) {
        dispatch({
          type: ProceduresActionTypes.SET_PROC_CATEGORIES,
          payload: state.procedureCategories.concat(res.data),
        });
      }
    } catch (error) {
      console.log(error);
      dispatchNotification({
        type: APINotificationActionTypes.SET_NOTIFICATION,
        payload: {
          message: "Error trying to category.",
          severity: Severity.ERROR,
        },
      });
    } finally {
      toggleAddNew();
    }
  }

  return {
    addNew,
    dispatch,
    handleSubmit,
    name,
    onFieldChange,
    state,
    toggleAddNew,
  };
}
