import { useEffect, useState } from "react"

export default function useConsiderationForm( initialValues: { name: string; considerationHtml: string; } | undefined) {
  
  const [ considerationHtml, setConsiderationHtml ] = useState<string>("");  
  const [ name, setName ] = useState<string>("");  

  useEffect(() => {    
    if(initialValues){
      setConsiderationHtml(initialValues.considerationHtml);
      setName(initialValues.name);
    }
  }, [initialValues]);  
  
	return {
    considerationHtml,
    name,    
    setConsiderationHtml,
    setName,
	}
}