import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import PopConfirm from "../PopConfirm";
import LoggedInTemplate from "../templates/LoggedIn";
import { PatientForm } from ".";

interface Params {
	patientId: string;
}

const PatientEdit = () => {
	const history = useHistory();
	const { patientId } = useParams<Params>();
	const [abort, setAbort] = useState<boolean>(false);

	return (
		<LoggedInTemplate title="Edit Patient Details">
			<PopConfirm
				title="Are you sure?"
				message="Any unsaved patient data you have entered will be lost."
				show={abort}
				handleClose={() => setAbort(false)}
				continueAction={() => history.push("/patients/" + patientId)}
			/>

			<Grid container spacing={3}>
				<Grid item lg={6}>
					<PatientForm
						setAbort={(v: boolean) => setAbort(v)}
						existingPatientId={patientId}
					/>
				</Grid>
			</Grid>
		</LoggedInTemplate>
	);
}

export default PatientEdit;