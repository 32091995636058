import React from "react";
import * as _ from "lodash";
import { createStyles, makeStyles, Theme, Button, Box } from "@material-ui/core";

import LoggedInTemplate from "../../components/templates/LoggedIn";
import { Link as RouterLink } from 'react-router-dom';
import { useFetchPatients } from "./hooks";
import { PatientsTable } from ".";
import SearchBox from "../search-box";

const useStyles = makeStyles((theme: Theme) => createStyles({
	topbar: {
		display: "flex",
		alignItems: "flex-start",
		marginBottom: 15
	}
}));

const PatientsList = () => {
	const css = useStyles();
	const { patients, loading, fetchPatients } = useFetchPatients();

	return (
		<LoggedInTemplate title="Patients">
			<div className={css.topbar}>
				<Box mr={2}>
					<Button variant="contained" color="primary" component={RouterLink} to="/patients/new">
						Add new patient
					</Button>
				</Box>
				<SearchBox
					placeHolderText="Search patients"
					handleSearch={(searchTerm: string) => {
						if(searchTerm === "" || (!loading && searchTerm.length > 3)) {
							fetchPatients(searchTerm); //TODO we are fetching all patients and paginating them on the client side. We should paginated them on server side (research).
						}
					}}
					loading={loading} />
			</div>

			<PatientsTable searching={loading} patients={patients} loading={loading} />
		</LoggedInTemplate>
	);
}

export default PatientsList;
