import React from "react";
import { Button, CircularProgress, createStyles, FormControl, Grid, makeStyles, TextField, Theme } from "@material-ui/core";
import AceEditor from "react-ace";
import "ace-builds/webpack-resolver";
import "ace-builds/src-min-noconflict/ext-language_tools";
import "ace-builds/src-min-noconflict/mode-html";
import "ace-builds/src-min-noconflict/theme-monokai";
import useConsiderationForm from "./hooks";

/* 
	Individual Risk
*/
interface iProps {
  cancelAction: () => void;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>, data:any) => Promise<void>;
  initialValues?: {
    name: string    
    considerationHtml: string
  },
  loading: boolean;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  button:{ 
    marginTop: 25, 
    marginRight: 25 
  },
	cardBody: {
		display: "flex",
		top: 0
	},
	cArea: {
		flex: "1",
		paddingTop: 0,
		position: "relative"
	},
	cAreaContent: {
		position: "sticky",
		top: 15
	},
	form: {
    width: '100%',
		position: "sticky",
		top: 15
  },
  name:{
    width: "100%"
  },
	titleBar: {
		cursor: "pointer",
	}
}));

const ConsiderationForm = ({ cancelAction, handleSubmit, initialValues, loading }: iProps) => {

	const { considerationHtml, name, setConsiderationHtml, setName } = useConsiderationForm(initialValues);
  const css = useStyles();

	return (    
    <Grid container>
      <form onSubmit={(event:any) => handleSubmit(event, { name, content: considerationHtml })} className={css.form}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormControl margin="normal" fullWidth>
              <TextField
                className={css.name}
                disabled={loading}
                size="medium"
                label="Name"
                value={name}
                onChange={(event: any) => setName(event.target.value)}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <AceEditor
            wrapEnabled={true}
            readOnly={loading}
            style={{ margin: "15px 0" }}
            name="consideration"
            mode="html"
            width="100%"
            height="300px"
            placeholder="Enter consideration HTML content..."
            theme="monokai"
            onChange={(value: string) => setConsiderationHtml(value)}
            value={considerationHtml}
            fontSize={14}
            showPrintMargin={false}
            showGutter={true}
            highlightActiveLine={true}
            setOptions={{
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              enableSnippets: true,
              showLineNumbers: true,
              tabSize: 2
            }}
          />
        </Grid>
        <Grid container item xs={6}>
          <Button
            disabled={loading}
            className={css.button}
            onClick={cancelAction}
            type="button"
            variant="contained"
            color="default">
            Cancel
          </Button>

          <Button 
            disabled={loading}
            className={css.button}
            type="submit"
            color="primary"
            variant="contained"
          >
            Save Consideration {loading && <CircularProgress style={{ marginLeft: 15 }} size={15} />}
          </Button>
        </Grid>
      </form>
    </Grid>
	);
}

export default ConsiderationForm;