import { createContext } from "react";
import { Procedure } from "../../../models";
import { ProcedureProviderAction } from "./interfaces/ProcedureProviderAction.interface";

const defaultProcedure = {
	id: "",
	createdAt: "",
	createdBy: "",
	name: "",
	procedureCategoryId: "",
	specialityId: "",
	subSpecialityId: "",
	updatedAt: "",
	updatedBy: "",
	benefits: [],
	considerations: [],
	risks: [],
	procedureCategory: null as any
};

export const ProcedureProviderContext = createContext<{ procedure: Procedure; procedureProviderDispatch: (action: ProcedureProviderAction) => void; }>({ procedure: defaultProcedure, procedureProviderDispatch: (action: ProcedureProviderAction) => action.payload});