import React, { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";
import useEditUser from './hooks';
import LoggedInTemplate from "../../templates/LoggedIn";
import PopConfirm from "../../PopConfirm";
import UserForm from "../user-form";
import { GlobalStateContext } from "../../../common/providers/global-state-provider/global-state.context";

const EditUser = () => {
	const { globalState } = useContext(GlobalStateContext);
	const { push } = useHistory();
	const { userId } = useParams<{ userId: string }>()
	const { abort, setAbort } = useEditUser();

	return (
		<LoggedInTemplate title="Edit User">
			<PopConfirm
				title="Are you sure?"
				message="Any unsaved user data you have entered will be lost."
				show={abort}
				handleClose={() => setAbort(false)}
				continueAction={() => push("/users")}
			/>

			<Grid container spacing={3}>
				<Grid item lg={8}>
					<UserForm
						currentUser={globalState.user}
						setAbort={(v: boolean) => setAbort(v)}
						userId={userId}
					/>
				</Grid>
			</Grid>
		</LoggedInTemplate>
	);
}

export default EditUser;