import { useContext } from "react";
import { PatientProviderContext } from "../../../../common/providers/PatientProvider/Patient.context";

export default function usePatientDetails() {
  
  const { patient } = useContext(PatientProviderContext);

  return {
    patient
  }
}