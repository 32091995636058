import React, { Fragment, useContext, useEffect } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";

import useNotifyPatientButton from "./hooks";

interface Props {
  disabled: boolean;
  fullWidth?: boolean;
  hasNotified: boolean;
}

const NotifyPatientButton = ({ disabled, fullWidth, hasNotified }: Props) => {
  const {
    closeModal,
    loading,
    notifyPatient,
    openModal,
    open,
    success,
    pendingQueries,
    // checkForPendingQueries,
  } = useNotifyPatientButton();

  return (
    <Fragment>
      <Dialog open={open} onClose={closeModal}>
        <DialogTitle disableTypography>
          <Typography style={{ margin: 0 }} variant="h3">
            Notify Patient
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {pendingQueries
              ? "There are unanswered queries on the form, please review and ensure all questions are answered."
              : "The patient will receive a notification email to let them know that you have answered all the queries."}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={closeModal} color="primary">
            Cancel
          </Button>
          <Button
            onClick={notifyPatient}
            color="primary"
            variant="contained"
            disabled={pendingQueries}
          >
            Send Notification{" "}
            {loading && (
              <CircularProgress
                style={{ marginLeft: 15, color: "white" }}
                size={15}
              />
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <Button
        variant="contained"
        color="secondary"
        onClick={openModal}
        disabled={disabled}
        fullWidth={fullWidth}
      >
        {hasNotified || success
          ? "Resend responses to patient"
          : "Send responses to patient"}
      </Button>
    </Fragment>
  );
};

export default NotifyPatientButton;
