import { ChangeEvent, useEffect, useState } from "react";

export default function useProcedureForm(initialValues: {
  category: string;
  considerations: string[],
  benefits: string[],
  name: string,
  risks: string[], 
  specialityId: string, 
  subProcedureText: string,
  subSpecialityId: string,
}  | undefined) {

  const [name, setName] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [selectedSpeciality, setSelectedSpeciality] = useState<string>("");
  const [selectedSubSpeciality, setSelectedSubSpeciality] = useState<string>("");
  const [selectedRisks, setSelectedRisks] = useState<string[]>([]);
  const [selectedBenefits, setSelectedBenefits] = useState<string[]>([]);
  const [selectedConsiderations, setSelectedConsiderations] = useState<string[]>([]);
  const [subProcedureText, setSubProcedureText] = useState<string>("");

  useEffect(() =>{
    
    if(initialValues){
      setName(initialValues.name);
      setCategory(initialValues.category);
      setSelectedSpeciality(initialValues.specialityId);
      setSelectedSubSpeciality(initialValues.subSpecialityId);      
      setSelectedRisks(initialValues.risks);
      setSelectedBenefits(initialValues.benefits);
      setSelectedConsiderations(initialValues.considerations);
      setSubProcedureText(initialValues.subProcedureText);
    }
  }, [initialValues]);

  function handleChange(event: ChangeEvent<any>){
    
    switch (event.target.name) {
      case "category":

        setCategory(event.target.value);
        break;

      case "name":

        setName(event.target.value);
        break;

      case "speciality":

        setSelectedSpeciality(event.target.value);
        setSelectedSubSpeciality("");
        break;

      case "sub-speciality":

        setSelectedSubSpeciality(event.target.value);
        break;

      case "risks":

        setSelectedRisks(event.target.value);
        break;

      case "benefits":

        setSelectedBenefits(event.target.value);
        break;

      case "considerations":

        setSelectedConsiderations(event.target.value);
        break;

      case "subProcedureText":

        setSubProcedureText(event.target.value);
        break;
    
      default:
        break;
    }
  }

	return {
    category,
    handleChange,
    name,
    selectedSpeciality,
    selectedSubSpeciality,
    selectedRisks,
    selectedBenefits,
    selectedConsiderations,
    subProcedureText,
	}
}