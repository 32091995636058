import { Reducer } from "react";

import { APINotificationActionTypes } from "./enums/APINotificationActionType.enum";
import { APINotification, APINotificationAction } from "./interfaces";

export const APINotificationReducer: Reducer<APINotification | null, APINotificationAction> = (notification, action) => {

	const { type, payload } = action;
    switch (type) {
      case APINotificationActionTypes.SET_NOTIFICATION:
              
        return payload ? payload : notification;

      case APINotificationActionTypes.REMOVE_NOTIFICATION:
              
        return null;
      
      default:
        return notification;
    }
}