import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import useAddUser from './hooks';
import LoggedInTemplate from "../../templates/LoggedIn";
import PopConfirm from "../../PopConfirm";
import UserForm from "../user-form";
import { GlobalStateContext } from "../../../common/providers/global-state-provider/global-state.context";

const UsersAdd = () => {

	const { globalState } = useContext(GlobalStateContext);
	const { push } = useHistory();	
	const { abort, setAbort } = useAddUser();

	return (
		<LoggedInTemplate title="Add User">
			<PopConfirm
				title="Are you sure?"
				message="Any unsaved user data you have entered will be lost."
				show={abort}
				handleClose={() => setAbort(false)}
				continueAction={() => push("/users")}
			/>

			<Grid container spacing={3}>
				<Grid item lg={12}>
					<UserForm
						currentUser={globalState.user}
						setAbort={(v: boolean) => setAbort(v)}
					/>
				</Grid>
			</Grid>
		</LoggedInTemplate>
	);
}

export default UsersAdd;