import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const usePatientsTableStyles = makeStyles((theme: Theme) =>
  createStyles({
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    actionsCell: {
      textAlign: 'center'
    }
  }),
);