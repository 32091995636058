import { useContext, useEffect, useState } from "react";
import { apiInstance } from "../../../common/api";
import { Boolean } from "../../../common/enums/boolean.enum";
import { ConsentFormStatus } from "../../../common/enums/consent-form-status.enum";
import { RoleTypes } from "../../../common/enums/roleTypes.enum";
import { Severity } from "../../../common/enums/severity.enum";
import useAPINotification from "../../../common/hooks/useAPINotification";
import { APINotificationActionTypes } from "../../../common/providers/APINotification/enums/APINotificationActionType.enum";
import { ConsentFormContext } from "../../../common/providers/ConsentFormProvider/ConsentForm.context";
import { ConsentFormProviderActionType } from "../../../common/providers/ConsentFormProvider/enums/ConsentFormProviderActionType.enum";
import { GlobalStateProviderActionType } from "../../../common/providers/global-state-provider/enums/global-state-provider-action-type.enum";
import { GlobalStateContext } from "../../../common/providers/global-state-provider/global-state.context";

interface Message {
  type: "success" | "info" | "warning" | "error" | undefined;
  text: string;
}

export default function useConsentApplication() {
  const { dispatchToGlobal, globalState } = useContext(GlobalStateContext);
  const { consentForm, dispatch } = useContext(ConsentFormContext);
  const { dispatchNotification } = useAPINotification();

  const [message, setMessage] = useState<Message | null>(null);
  const [waitingSubmission, setWaitingSubmission] = useState<Boolean>(
    Boolean.FALSE
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    consentForm.id !== "" ? setLoading(false) : setLoading(true);
  }, [consentForm.id]);

  async function handleSubmission() {
    setWaitingSubmission(Boolean.TRUE);

    try {
      const data = {
        locked: true,
      };

      const res = await apiInstance.post(
        `/consent-forms/${consentForm.id}/submit`,
        data
      );

      if (res.status === 200) {
        setMessage({
          type: "success",
          text: "The application has been submitted successfully. Now this application is locked so it can not be modified. You may download a PDF copy of this consenting application from the download button at the top of this page.",
        });
        dispatch({
          type: ConsentFormProviderActionType.UPDATE_CONSENT_FORM,
          payload: { locked: true },
        });
        dispatchToGlobal({
          type: GlobalStateProviderActionType.UPDATE_APPLICATIONS_HIGHLIGHTS,
          payload: consentForm.status,
        });
      }
    } catch (err) {
      console.log(err);
      dispatchNotification({
        type: APINotificationActionTypes.SET_NOTIFICATION,
        payload: {
          message: "Error submitting the form",
          severity: Severity.ERROR,
        },
      });
    } finally {
      setWaitingSubmission(Boolean.FALSE);
    }
  }

  function isLoading() {
    return waitingSubmission === Boolean.TRUE;
  }

  async function downloadPDF() {
    const res = await apiInstance.get(
      `/consent-forms/${consentForm.id}/download`
    );

    if (res.status === 200) {
      window.open(res.data.url, "_blank");
    }
  }

  async function downloadSummaryPDF() {
    try {
      const res = await apiInstance.get(
        `/consent-forms/${consentForm.id}/download-summary`
      );

      if (res.status === 200) {
        window.open(res.data.url, "_blank");
      }
    } catch (err) {
      console.log(err);
      dispatchNotification({
        type: APINotificationActionTypes.SET_NOTIFICATION,
        payload: {
          message: "Error trying to download the pdf",
          severity: Severity.ERROR,
        },
      });
    }
  }

  async function notifyPatient() {
    const res = await apiInstance.post(
      `/consent-forms/${consentForm.id}/notify-patient`
    );

    if (res.status === 200) {
      setMessage({
        type: "success",
        text: "A notification has been sent to the patient.",
      });
    }
  }

  function showNotificationButton() {
    return (
      consentForm.status === ConsentFormStatus.PENDING_QUERIES ||
      consentForm.status === ConsentFormStatus.PATIENT_NOTIFIED
    );
  }

  function showResendButton() {
    return consentForm.status === ConsentFormStatus.NOT_STARTED;
  }

  function showQA() {
    return (
      consentForm.status !== ConsentFormStatus.NOT_STARTED &&
      consentForm.status !== ConsentFormStatus.IN_PROGRESS &&
      consentForm.status !== ConsentFormStatus.PENDING_QUERIES &&
      consentForm.status !== ConsentFormStatus.PATIENT_NOTIFIED
    );
  }

  function showSignatures() {
    if (globalState.user.role !== RoleTypes.CLINIC_ADMIN) {
      return (
        consentForm.status !== ConsentFormStatus.NOT_STARTED &&
        consentForm.status !== ConsentFormStatus.IN_PROGRESS &&
        consentForm.status !== ConsentFormStatus.PENDING_QUERIES &&
        consentForm.status !== ConsentFormStatus.PATIENT_NOTIFIED &&
        consentForm.status !== ConsentFormStatus.PENDING_QAS &&
        consentForm.status !== ConsentFormStatus.QA_NOT_UNDERSTOOD
      );
    }
  }

  function showFlagAlert() {
    const questionAnswer = consentForm.answers.find(
      (answer) => answer.questionId !== null
    );

    return questionAnswer && !consentForm.locked;
  }

  return {
    consentForm,
    downloadPDF,
    downloadSummaryPDF,
    handleSubmission,
    loading,
    isLoading,
    message,
    notifyPatient,
    setMessage,
    showFlagAlert,
    showNotificationButton,
    showResendButton,
    showQA,
    showSignatures,
  };
}
