import React from "react";
import { Box, Button, createStyles, List, makeStyles, Theme } from "@material-ui/core";
import LoggedInTemplate from "../templates/LoggedIn";
import useSpecialities from "./hooks";
import { Speciality } from "../../models";
import SpecialityItem from "./speciality-item";
import SpecialityForm from "./speciality-form";

const useStyles = makeStyles((theme: Theme) => createStyles({
  topbar: {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: 15
  }
}));

const Specialities = () => {

  const { addNew, handleSpecialitySubmit, isLoading, specialities, toggleAddNew } = useSpecialities();
  const css = useStyles();

	return (
    <LoggedInTemplate title="Specialities">
      <div className={css.topbar}>
        <Box width="100%" mr={2}>
          {
            addNew() 
              ? <SpecialityForm cancelAction={toggleAddNew} handleSubmit={handleSpecialitySubmit} loading={isLoading()} />
              : <Button variant="contained" onClick={() => toggleAddNew()} color="primary" >Add new speciality</Button>
          }
        </Box>
      </div>

      <List>
        {specialities.map((speciality: Speciality) => <SpecialityItem key={speciality.id} data={speciality} />)}
      </List>
    </LoggedInTemplate>
	);
}

export default Specialities;