import { UndoManager } from "ace-builds";
import { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react";
import { apiInstance } from "../../../../common/api";
import { Severity } from "../../../../common/enums/severity.enum";
import useAPINotification from "../../../../common/hooks/useAPINotification";
import { APINotificationActionTypes } from "../../../../common/providers/APINotification/enums/APINotificationActionType.enum";
import { GlobalStateProviderActionType } from "../../../../common/providers/global-state-provider/enums/global-state-provider-action-type.enum";
import { GlobalStateContext } from "../../../../common/providers/global-state-provider/global-state.context";
import { appConfig } from "../../../../constants";
import { fakeClinic } from "../../../../lib";
import { Clinic } from "../../../../models";
import userForm from "../../../users/user-form";

const useClinicForm = (clinicId: string | undefined) => {
  const { dispatchToGlobal } = useContext(GlobalStateContext);
  const { dispatchNotification } = useAPINotification();
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [abort, setAbort] = useState<boolean>(false);
  const { globalState } = useContext(GlobalStateContext);
  const [error, setError] = useState<string | null>(null);

  const useFakeData = !clinicId && appConfig.FAKER;
  const fakeEmail = fakeClinic().email;

  const [values, setValues] = useState({
    name: useFakeData ? fakeClinic.name : "",
    email: useFakeData ? fakeEmail : "",
  });

  useEffect(() => {
    if (clinicId) {
      fetchClinic(clinicId);
    }
  }, [clinicId]);

  async function fetchClinic(clinicId: string) {
    try {
      const clinicResponse = await apiInstance.get(`/clinics/${clinicId}`);

      if (clinicResponse.status === 200) {
        const clinic: Clinic = clinicResponse.data;
        setValues({
          ...values,
          name: clinic.name,
          email: clinic.email,
        });
      }
    } catch (error) {
      console.log(error);
      dispatchNotification({
        type: APINotificationActionTypes.SET_NOTIFICATION,
        payload: {
          message: "Error trying to get user",
          severity: Severity.ERROR,
        },
      });
    }
  }

  const onFieldChange = (event: ChangeEvent<any>) => {
    const { name, value }: { name: string; value: string } = event.target;
    switch (name) {
      case "name":
        setValues((values) => ({
          ...values,
          name: value,
        }));
        break;
      case "email":
        setValues((values) => ({
          ...values,
          email: value,
        }));
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (
    event: FormEvent<HTMLFormElement>,
    clinicId: string | undefined
  ) => {
    event.preventDefault();
    let payload = {};
    let res;

    try {
      setLoading(true);
      if (clinicId) {
        payload = {
          ...values,
          name: values.name,
          email: values.email,
          updatedBy: globalState.user.email,
          updatedAt: new Date(),
        };
        res = await apiInstance.patch("/clinics/" + clinicId, payload);
      } else {
        payload = {
          name: values.name,
          email: values.email,
          createdBy: globalState.user.email,
          createdAt: new Date(),
          updatedBy: globalState.user.email,
          updatedAt: new Date(),
        };
        res = await apiInstance.post("/clinics", payload);
      }

      if (res.status === 201 || res.status === 200) {
        dispatchToGlobal({
          type: GlobalStateProviderActionType.SET_ALERT,
          payload: {
            content: `Clinic successfully ${
              clinicId
                ? "updated"
                : "created, they have been sent an email to set up their account."
            }`,
            severity: "success",
          },
        });
        setSuccess(true);
      }
    } catch (error: any) {
      console.log(error);
      switch (error.response.status) {
        case 409:
          setError("A clinic already exists with this email address.");
          break;

        default:
          setError("Unexpected error creating clinic, please try again.");
          break;
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    abort,
    error,
    handleSubmit,
    onsubmit,
    loading,
    success,
    onFieldChange,
    values,
  };
};

export default useClinicForm;
