import { useContext, useEffect, useState } from "react";
import { apiInstance } from "../../../../common/api";
import { Severity } from "../../../../common/enums/severity.enum";
import useAPINotification from "../../../../common/hooks/useAPINotification";
import { APINotificationActionTypes } from "../../../../common/providers/APINotification/enums/APINotificationActionType.enum";
import { GlobalStateProviderActionType } from "../../../../common/providers/global-state-provider/enums/global-state-provider-action-type.enum";
import { GlobalStateContext } from "../../../../common/providers/global-state-provider/global-state.context";
import { Highlight, User } from "../../../../models";

interface iProps {
  user: User;
}

export default function useRequiresAction({ user }: iProps) {
  const { dispatchNotification } = useAPINotification();
  const { dispatchToGlobal } = useContext(GlobalStateContext);
  const [highlights, setHighlights] = useState<Highlight[]>([]);

  useEffect(() => {
    const fetchConsentFormsHighlights = async () => {
      try {
        const res = await apiInstance.get(
          `/me/consent-forms-highlights?clinicId=${user.clinicId}&role=${user.role}&id=${user.id}`
        );

        if (res.status === 200) {
          setHighlights(res.data);
          dispatchToGlobal({
            type: GlobalStateProviderActionType.SET_APPLICATIONS_HIGHLIGHTS,
            payload: res.data,
          });
        }
      } catch (error) {
        console.log(error);
        dispatchNotification({
          type: APINotificationActionTypes.SET_NOTIFICATION,
          payload: {
            message: "Error trying to get your applications highlights.",
            severity: Severity.ERROR,
          },
        });
      }
    };

    fetchConsentFormsHighlights();
  }, [dispatchToGlobal, dispatchNotification]);

  return {
    highlights,
  };
}
