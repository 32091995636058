import React from "react";
import { Drawer, List, ListItemIcon, ListItemText, MenuItem} from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import clsx from "clsx";
import useSidebar from "./hooks";
import { useStyles } from "./sidebar.css";

const Sidebar = () => {
	const classes = useStyles();
	const location = useLocation();
	const { menuItems } = useSidebar();

	return (
		<aside>
			<Drawer variant="permanent" className={classes.drawer} classes={{ paper: classes.drawerPaper }}>
				<nav>
					<Link to="/" style={{ display: "block", background: "#fafafa" }}>
						<img className={classes.logo} src="/images/Medical_Consent_Logo.svg" alt="Medical Consent" />
					</Link>
					<List>
						{menuItems.map((item) => (
							<MenuItem
								className={clsx(classes.menuItem, /* item.nested && classes.nested */)}
								button
								key={item.title}
								component={Link}
								to={item.to}
								divider
								selected={item.activeRoutes.includes(location.pathname)}>
								<ListItemIcon classes={{ root: classes.iconRoot }}>{item.icon}</ListItemIcon>
								<ListItemText classes={{ primary: classes.menuText }} primary={item.title} />
								{ item.notificationIcon ? <ListItemIcon classes={{ root: classes.iconRoot }}>{item.notificationIcon}</ListItemIcon> : null }
							</MenuItem>
						))}
					</List>
				</nav>
			</Drawer>
		</aside>
	);
}

export default Sidebar;