import { useCallback, useContext, useEffect, useState } from "react";
import { apiInstance } from "../../../common/api";
import { Severity } from "../../../common/enums/severity.enum";
import useAPINotification from "../../../common/hooks/useAPINotification";
import { APINotificationActionTypes } from "../../../common/providers/APINotification/enums/APINotificationActionType.enum";
import { GlobalStateProviderActionType } from "../../../common/providers/global-state-provider/enums/global-state-provider-action-type.enum";
import { GlobalStateContext } from "../../../common/providers/global-state-provider/global-state.context";

export default function useLoggedIn() {
  const { dispatchNotification } = useAPINotification();
  const { globalState, dispatchToGlobal } = useContext(GlobalStateContext);
  const [showSessionExpires, setShowSessionExpires] = useState<boolean>(false);
  const [countDown, setCountDown] = useState(0);

  useEffect(() => {
    if (process.env.REACT_APP_SESSION_TIME) {
      const sessionTO = setTimeout(() => {
        setShowSessionExpires(true);
        setCountDown(120);
      }, parseInt(process.env.REACT_APP_SESSION_TIME));

      return () => {
        clearTimeout(sessionTO);
      };
    }
  });

  useEffect(() => {
    if (countDown > 0) {
      const countDownTO = setTimeout(() => {
        setCountDown(countDown - 1);
      }, 1000);
      return () => {
        clearTimeout(countDownTO);
      };
    }
  }, [countDown]);

  const getUser = useCallback(async () => {
    try {
      const res = await apiInstance.get("/me");
      dispatchToGlobal({
        type: GlobalStateProviderActionType.SET_USER,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
      dispatchNotification({
        type: APINotificationActionTypes.SET_NOTIFICATION,
        payload: {
          message: "Error trying to get your user",
          severity: Severity.ERROR,
        },
      });
    }
  }, [dispatchToGlobal, dispatchNotification]);

  async function handleClose() {
    try {
      const res = await apiInstance.get("");
    } catch (error: any) {
      dispatchNotification({
        type: APINotificationActionTypes.SET_NOTIFICATION,
        payload: {
          message:
            error.response.data.message || "Error trying to renew your session",
          severity: Severity.ERROR,
        },
      });
    } finally {
      setShowSessionExpires(false);
    }
  }

  // Fetch logged in user
  useEffect(() => {
    if (sessionStorage.getItem("auth") && globalState.user.id === "") {
      getUser();
    }
  }, [getUser, globalState.user.id]);

  return {
    countDown,
    globalState,
    handleClose,
    showSessionExpires,
  };
}
