import { useCallback, useEffect, useState } from "react";
import { Clinic } from "../../../../models";

import { apiInstance } from "../../../../common/api";
import useAPINotification from "../../../../common/hooks/useAPINotification";
import { APINotificationActionTypes } from "../../../../common/providers/APINotification/enums/APINotificationActionType.enum";
import { Severity } from "../../../../common/enums/severity.enum";

export default function useClinicsTable() {
  const { dispatchNotification } = useAPINotification();
  const [data, setData] = useState<Clinic[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getClinics = useCallback(() => {
    const fetchClinics = async () => {
      try {
        setLoading(true);
        const res = await apiInstance.get("/clinics");
        if (res.data) {
          setData(res.data);
        }
        console.log(res);
      } catch (error) {
        dispatchNotification({
          type: APINotificationActionTypes.SET_NOTIFICATION,
          payload: {
            message: "Error trying to get clinics",
            severity: Severity.ERROR,
          },
        });
      } finally {
        setLoading(false);
      }
    };
    fetchClinics();
  }, [dispatchNotification]);

  useEffect(() => {
    getClinics();
  }, [getClinics]);

  return { data, loading };
}
