import React from "react";
import { Avatar as MUIAvatar } from "@material-ui/core";
import { stringToColour } from "../lib";

interface Props {
	avatarImg: string | null;
	firstName: string;
	lastName: string;
	email: string;
}

const Avatar = ({ avatarImg, firstName, lastName, email }: Props) => {
	if (avatarImg) {
		return <MUIAvatar alt={`${firstName} ${lastName}`} src={avatarImg} />
	}

	return <MUIAvatar style={{ backgroundColor: stringToColour(email) }}>{firstName.charAt(0)}{lastName.charAt(0)}</MUIAvatar>;
}

export default Avatar;