import { Box, Button, CircularProgress, FormControl, Grid, Grow, InputLabel, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { enGB } from "date-fns/locale";
import { Redirect } from "react-router-dom";
import NotFound from "../../NotFound";
import { usePatientForm } from "../hooks";
import { Loading } from ".";
import { Alert } from "@material-ui/lab";

interface Props {
	setAbort: (v: boolean) => void;
	existingPatientId?: string;
}

const PatientForm = ({ existingPatientId, setAbort }: Props) => {
	const [patientId, setPatientId] = useState<string>(existingPatientId || "");

	const {
		values,
		setValues,
		onFieldChange,
		handleSubmit,
		loading,
		patientLoading,
		patientNotFound,
		success,
		error
	} = usePatientForm(patientId, setPatientId);

	if (patientNotFound) {
		return <NotFound />;
	}

	if (patientLoading) {
		return <Loading />;
	}

	if (success && !loading) {
		return <Redirect to={{ pathname: "/patients/" + patientId, state: { persistAlert: true } }} />
	}

	return (
		<form onSubmit={(event) => handleSubmit(event, patientId)}>
			<Grid container spacing={3}>
				<Grid spacing={0} item lg={12}>
					<Typography style={{ marginBottom: 0 }} component="h2" variant="h2">Personal Information</Typography>
				</Grid>
				<Grid item lg={4}>
					<FormControl fullWidth margin="normal">
						<TextField
							autoFocus
							onChange={onFieldChange}
							name="title"
							label="Title"
							value={values.title}
						/>
					</FormControl>
				</Grid>

				<Grid item lg={4}>
					<FormControl fullWidth margin="normal">
						<TextField
							onChange={onFieldChange}
							required
							name="firstName"
							label="First name"
							value={values.firstName}
						/>
					</FormControl>
				</Grid>

				<Grid item lg={4}>
					<FormControl fullWidth margin="normal">
						<TextField
							onChange={onFieldChange}
							required
							name="lastName"
							label="Last name"
							value={values.lastName}
						/>
					</FormControl>
				</Grid>
			</Grid>

			<Grid container spacing={6}>
				<Grid item lg={6}>
					<FormControl fullWidth>
						<MuiPickersUtilsProvider utils={DateFnsUtils} locale={enGB}>
							<KeyboardDatePicker
								required
								format="dd/MM/yyyy"
								placeholder="dd/mm/yyyy"
								margin="normal"
								id="date-picker-dob"
								label="Date of birth"
								value={values.dob ? values.dob : null}
								onChange={(date: any) => setValues({ ...values, dob: date })}
								KeyboardButtonProps={{ 'aria-label': 'change date' }}
							/>
						</MuiPickersUtilsProvider>
					</FormControl>
				</Grid>

				<Grid item lg={6}>
					<FormControl margin="normal" fullWidth>
						<InputLabel id="Sex">Gender</InputLabel>
						<Select
							onChange={onFieldChange}
							name="sex"
							label="Gender"
							value={values.sex}>
							<MenuItem value="Male">Male</MenuItem>
							<MenuItem value="Female">Female</MenuItem>
							<MenuItem value="Other">Other</MenuItem>
						</Select>
					</FormControl>
				</Grid>
			</Grid>

			<Grid container spacing={3}>
				<Grid item lg={3}>
					<FormControl margin="normal" fullWidth>
						<TextField
							onChange={onFieldChange}
							name="NHSnumber"
							label="NHS #"
							value={values.NHSnumber}
						/>
					</FormControl>
				</Grid>

				<Grid item lg={5}>
					<FormControl margin="normal" fullWidth>
						<TextField
							onChange={onFieldChange}
							name="insuranceAuthorisationCode"
							label="Insurance auth code"
							value={values.insuranceAuthorisationCode}
						/>
					</FormControl>
				</Grid>


				<Grid item lg={4}>
					<FormControl margin="normal" fullWidth>
						<TextField
							onChange={onFieldChange}
							name="privateInsuranceNumber"
							label="Private insurance #"
							value={values.privateInsuranceNumber}
						/>
					</FormControl>
				</Grid>
			</Grid>

			<Grid container spacing={3}>
				<Grid spacing={0} item lg={12}>
					<Typography style={{ marginBottom: 0, marginTop: 50 }} component="h2" variant="h2">Contact Information</Typography>
				</Grid>
				<Grid item lg={4}>
					<FormControl margin="normal" fullWidth>
						<TextField
							required
							type="email"
							onChange={onFieldChange}
							name="email"
							label="Email"
							value={values.email}
						/>
					</FormControl>
				</Grid>

				<Grid item lg={4}>
					<FormControl margin="normal" fullWidth>
						<TextField
							required
							type="email"
							onChange={onFieldChange}
							name="confirmEmail"
							label="Confirm email"
							value={values.confirmEmail}
						/>
					</FormControl>
				</Grid>

				<Grid item lg={4}>
					<FormControl margin="normal" fullWidth>
						<TextField
							required
							onChange={onFieldChange}
							name="phone"
							label="Phone"
							value={values.phone}
						/>
					</FormControl>
				</Grid>

				<Grid item lg={6}>
					<FormControl margin="normal" fullWidth>
						<TextField
							onChange={onFieldChange}
							name="addressLine1"
							label="Address line 1"
							value={values.addressLine1}
						/>
					</FormControl>
				</Grid>

				<Grid item lg={6}>
					<FormControl margin="normal" fullWidth>
						<TextField
							onChange={onFieldChange}
							name="addressLine2"
							label="Address line 2"
							value={values.addressLine2}
						/>
					</FormControl>
				</Grid>

				<Grid item lg={4}>
					<FormControl margin="normal" fullWidth>
						<TextField
							onChange={onFieldChange}
							name="city"
							label="City"
							value={values.city}
						/>
					</FormControl>
				</Grid>

				<Grid item lg={4}>
					<FormControl margin="normal" fullWidth>
						<TextField
							onChange={onFieldChange}
							name="county"
							label="County"
							value={values.county}
						/>
					</FormControl>
				</Grid>

				<Grid item lg={4}>
					<FormControl margin="normal" fullWidth>
						<TextField
							onChange={onFieldChange}
							name="postcode"
							label="Postcode"
							value={values.postcode}
						/>
					</FormControl>
				</Grid>
			</Grid>

			<Button
				style={{ marginTop: 25, marginRight: 25 }}
				onClick={() => setAbort(true)}
				type="button"
				variant="contained"
				color="default">
				Back
			</Button>

			<Button
				style={{ marginTop: 25 }}
				type="submit"
				disabled={loading}
				variant="contained"
				color="primary">
				{patientId ? "Update" : "Create"} Patient {loading && <CircularProgress style={{ marginLeft: 15 }} size={15} />}
			</Button>


			{error && (
				<Box mt={5}>
					<Grow in={true} timeout={1000}>
						<Alert style={{ margin: "15px 0" }} variant="filled" severity="error">{error}</Alert>
					</Grow>
				</Box>
			)}
		</form>
	);
}

export default PatientForm;