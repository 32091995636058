import { useEffect, useState } from "react"
import { QuestionTypes } from "../../../../common/enums/question-types.enum";
import { Question } from "../../../../models";

interface iState {
	title: string;
	description: string | null;
  type: QuestionTypes | null;
  options: string | null;
}

interface Props {
  question?: Question;
}

const initialState = { title: "", description: null, type: null, options: null }

export default function useQuestionForm({ question }: Props) {

  const [state, setState] = useState<iState>(initialState);

  useEffect(() =>{

    if(question){
      setState({
        title: question.title,
        description: question.description,
        type: question.type,
        options: question.options,
      })
    }
  }, [question])

  function handleChange (event: any) {

    if(event.target.name === "type" && event.target.value !== "radio group"){
      setState({ ...state, options: question ? question.options : null });
    }
    setState({ ...state, [event.target.name]: event.target.value });
  }

	return {
    state,
    handleChange,
	}
}