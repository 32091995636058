import { Reducer } from "react";
import { Patient } from "../../../models";
import { PatientProviderActionType } from "./enums/PatientProviderActionType.enum";
import { PatientProviderAction } from "./interfaces/PatientProviderAction.interface";

export const PatientReducer: Reducer<Patient, PatientProviderAction> = (patient, action) => {

	const { type, payload } = action;
  switch (type) {

    case PatientProviderActionType.SET_PATIENT:
			
      return payload;   

    case PatientProviderActionType.UPDATE_PATIENT:

      return {
        ...patient,        
        ...payload,
      };

    default:
      return patient;
  }
}