import React from "react";
import { Button, Card, CardContent, Collapse, createStyles, Divider, Grid, makeStyles, Theme } from "@material-ui/core";
import { Consideration } from "../../../models";
import CardTitle from "../../common/card-title";
import useConsiderationItem from "./hooks";
import ConsiderationForm from "../consideration-form";
import clsx from "clsx";
import useDragDrop from "../../common/hooks/use-drag-drop";
import Questions from "../../common/questions";
import { TopicType } from "../../../common/enums/topic-type.enum";

/* 
	Individual Risk
*/
interface iProps {
	data: Consideration;
	dropCard: () => void;
	index: number;
	moveCard: (dragIndex: number, hoverIndex: number) => void;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
	approveBtn: {
		background: theme.palette.primary.main,
		display: "block",
		margin: "25px 0 15px 0",
		'&:hover': {
			background: theme.palette.primary.dark
		}
	},
	cardBody: {
		display: "flex",
		top: 0
	},
	cArea: {
		flex: "1",
		paddingTop: 0,
		position: "relative"
	},
	cAreaContent: {
		position: "sticky",
		top: 15
	},
	form: {
		position: "sticky",
		top: 15,
	},
	dragging: {
		opacity: 0,
	},
	root: {
		position: 'relative',
		borderTop: "2px solid",
		borderColor: "#c7c7c7",
		marginBottom: 15,
		overflow: "visible"		
	},
  title: {
		borderColor: theme.palette.success.light,
  },
	titleBar: {
		cursor: "pointer",
	}
}));

const ConsiderationItem = ({ data, dropCard, index, moveCard }: iProps) => {

	const { collapsed, editing, handleSubmit, isLoading, name, toggleShow, toggleEdition, considerationHtml } = useConsiderationItem(data);
	const { isDragging, ref } = useDragDrop(dropCard, index, moveCard);
	const css = useStyles();

	return (
		<Card ref={ref} className={clsx(css.root, css.title, isDragging && css.dragging)} >
			<CardContent className={css.titleBar} onClick={toggleShow}>
				<CardTitle text={name} collapsed={collapsed()} />
			</CardContent>
			<Collapse in={collapsed()} timeout="auto" unmountOnExit>
				<div className={css.cardBody}>					
					<CardContent className={css.cArea}>
						<Divider style={{ margin: "15px 0" }} />
						{
							editing()
							 ? (<ConsiderationForm cancelAction={toggleEdition} initialValues={{name, considerationHtml }} handleSubmit={handleSubmit} loading={isLoading()} />)
							 : (
									<Grid container>
										<Grid item xs={12}>
											<div dangerouslySetInnerHTML={{ __html: considerationHtml }}></div>
										</Grid>
										<Grid item xs={6} >
											<Button
												onClick={toggleEdition}											
												className={css.approveBtn}
												color="primary"
												variant="contained"
											>
												Edit Consideration
											</Button>
										</Grid>
									</Grid>
								)
						}
						<Divider style={{ margin: "15px 0" }} />
						<Questions canCreateNew topicId={data.id} topicType={TopicType.CONSIDERATIONS} />
					</CardContent>
				</div>
			</Collapse>
		</Card>
	);
}

export default ConsiderationItem;