import React from "react";
import { Link as RouterLink } from 'react-router-dom';
import { createStyles, makeStyles, Theme, Typography, Divider, Button, Tooltip, Paper } from "@material-ui/core";
import { MailOutline, Phone } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import { calcAge, displayDOB } from "../../../lib";
import { Patient } from "../../../models";
import { Loading } from "./";

interface Props {
	patient: Patient | null;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
	overview: {
		padding: 15,
		boxSizing: "border-box"
	},
	title: {
		fontSize: 22,
		display: "inline-flex",
		alignItems: "center"
	},
	dob: {
		fontSize: 14,
		display: "block",
		margin: "-5px 0 0 40px"
	},
	contactDetails: {
		padding: 15,
		boxSizing: "border-box",
	},
	contactItem: {
		fontSize: 14,
		marginRight: 25,
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-start",
		marginBottom: 10,
		"&:last-child": {
			marginRight: 0
		}
	},
	additionalMultiple: {
		display: "flex",
	},
	additionalItem: {
		fontSize: 14,
		margin: 15
	},
	actions: {
		padding: 15,
		boxSizing: "border-box"
	},
	label: {
		color: theme.palette.grey[600]
	}
}));

const PatientOverview = ({ patient }: Props) => {
	const css = useStyles();

	if (!patient) {
		return <Loading />
	}

	return (
		<Paper elevation={1} component="section">
			<div className={css.overview}>
				<Typography component="h2" className={css.title}>
					{patient.sex === "Male" && <img src="/icons/male.svg" alt="Male" width={30} style={{ marginRight: 10 }} />}
					{patient.sex === "Female" && <img src="/icons/female.svg" alt="Female" width={30} style={{ marginRight: 10 }} />}
					{patient.title} {patient.firstName} {patient.lastName}
				</Typography>
				<Typography className={css.dob}>
					{calcAge(patient.dob as string)} years old ({displayDOB(patient.dob as string)})
				</Typography>
			</div>

			<Divider light />

			<div className={css.contactDetails}>
				<Tooltip title="Phone" placement="top-start" arrow>
					<Typography className={css.contactItem}>
						<Phone style={{ marginRight: 8 }} fontSize="small" color="disabled" />
						{patient.phone}
					</Typography>
				</Tooltip>

				<Tooltip title="Email" placement="top-start" arrow>
					<Typography className={css.contactItem}>
						<MailOutline style={{ marginRight: 8 }} fontSize="small" color="disabled" />
						{patient.email}
					</Typography>
				</Tooltip>
			</div>

			{patient.NHSnumber && (
				<Typography className={css.additionalItem}>
					<span className={css.label}>NHS #:</span><br /> {patient.NHSnumber}
				</Typography>
			)}

			<div className={css.additionalMultiple}>
				{patient.privateInsuranceNumber && (
					<Typography className={css.additionalItem}>
						<span className={css.label}>Private Insurance #:</span><br /> {patient.privateInsuranceNumber}
					</Typography>
				)}

				{patient.insuranceAuthorisationCode && (
					<Typography className={css.additionalItem}>
						<span className={css.label}>Insurance Auth Code:</span><br /> {patient.insuranceAuthorisationCode}
					</Typography>
				)}
			</div>

			<div className={css.actions}>
				<Button fullWidth component={RouterLink} to={`/patients/${patient.id}/edit`} startIcon={<EditIcon />} variant="outlined" color="primary" >
					Edit Personal Details
				</Button>
			</div>
		</Paper>
	);
}

export default PatientOverview;