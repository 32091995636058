import { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react"
import { apiInstance } from "../../../../common/api";
import { Severity } from "../../../../common/enums/severity.enum";
import useAPINotification from "../../../../common/hooks/useAPINotification";
import { APINotificationActionTypes } from "../../../../common/providers/APINotification/enums/APINotificationActionType.enum";
import { GlobalStateContext } from "../../../../common/providers/global-state-provider/global-state.context";
import { SubProcedureText } from "../../../../models/SubProcedureText";

interface iProps {
  procedureId?: string;
  text?: SubProcedureText;
}

export default function useSubProcedureTextForm({ procedureId, text }: iProps) {

  const { dispatchNotification } = useAPINotification();
  const { globalState } = useContext(GlobalStateContext);

  const [ value, setValue ] = useState<string>("");
  const [ stateText, setStateText ] = useState<SubProcedureText>();
  const [ loading, setLoading ] = useState(false);
  const [ modified, setModified ] = useState(false);

  useEffect(() => {

    if(text){
      setValue(text.text);
      setStateText(text);
    }
  }, [text]);

  function handleChange(event: ChangeEvent<any>){
    
    switch (event.target.name) {
      case "text":

        setValue(event.target.value);
        if(!modified && ((stateText && event.target.value !== stateText.text) || !stateText)){
          setModified(true);
        }
        if(modified && ((stateText && event.target.value === stateText.text) || (!stateText && event.target.value === ""))){
          setModified(false);
        }
        break;
    
      default:
        break;
    }
  }

  function cancelAction(){
    
    text ? setValue(text.text) : setValue("");
    setModified(false);
  }


  async function handleSubmit(event: FormEvent<HTMLFormElement>){
    event.preventDefault();

    setLoading(true);
    try{

      const res = stateText ? await apiInstance.patch(`/sub-procedure-texts/${stateText.id}`, { text: value }) : await apiInstance.post(`/sub-procedure-texts`, { text: value, procedureId: procedureId, userId: globalState.user.id, clinicId: globalState.user.clinicId });
      if(res.status === 200){
        setStateText(res.data);
      }
        
    } catch (error) {

      console.log(error);
      dispatchNotification({ type: APINotificationActionTypes.SET_NOTIFICATION, payload: { message: "There was an error trying to save the text", severity: Severity.ERROR } });
    } finally {
      setLoading(false);
      setModified(false);
    }
  }

  return {
    cancelAction,
    handleChange,
    handleSubmit,
    loading,
    modified,
    value,
  }
}