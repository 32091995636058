import { ChangeEvent, useEffect, useState } from "react";
import { SubSpeciality } from "../../../../models";

export default function useSubSpecialityForm(data: SubSpeciality | undefined) {

  const [name, setName] = useState<string>("");

  useEffect(() => {

    if(data){

      setName(data.name);
    }
  }, [data]);

	function onFieldChange(event: ChangeEvent<HTMLInputElement>) {
		setName(event.target.value);
	}

	return {    
    name,    
    onFieldChange
	}
}