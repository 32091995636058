import { useContext } from "react";
import { APINotificationContext } from "../providers/APINotification/APINotification.context";

export default function useAPINotification() {
  const { notification, dispatchNotification } = useContext(APINotificationContext);

  return { 
    notification,
    dispatchNotification
  };
}