import React from "react";
import { Button, Card, CardContent, Collapse, createStyles, Dialog, DialogContent, DialogTitle, FormControl, Grid, IconButton, List, makeStyles, TextField, Theme, Typography } from "@material-ui/core";
import { ProcedureCategory } from "../../../models";
import clsx from "clsx";
import { Edit, ExpandLess, ExpandMore } from "@material-ui/icons";
import useProcCategory from "./hooks/useProcCategory";
import ProcedureItem from "../procedure-item";
import ProcedureForm from "../procedureForm";
import { initial } from "lodash";

const useStyles = makeStyles((theme: Theme) => createStyles({
  approveBtn: {
		background: theme.palette.primary.main,
		display: "block",
		margin: "25px 0 15px",
		'&:hover': {
			background: theme.palette.primary.dark
		},
		width: "70%"
	},
  root: {
		borderColor: theme.palette.info.light,
		position: 'relative',
		borderTop: "2px solid",		
		marginBottom: 15,
		overflow: "visible",
		'&:hover $editButton': {			
			opacity: 0.5,
			cursor: "pointer"
		}
	},
	editButton: {
		position: 'absolute',
		top: -10,
		left: -10,
		opacity: 0		
	},
	form:{
		width: '100%'
	},
  title: {
		marginBottom: 0
  },
  titleBar: {
		// cursor: "pointer",
		paddingBottom: "16px !important",
  },
  titleArea: {
		display: "flex",
		alignItems: "center"
  },
  progressIcon: {
		marginRight: 10
  },
  cardBody: {
		display: "flex",
		top: 0
	},
	cArea: {
		maxWidth: "100%",
		flex: "1",
		paddingTop: 0,
		position: "relative"
	},
	cAreaContent: {
		position: "sticky",
		top: 15
	},
}));

interface iProps {
	data: ProcedureCategory;
}

const ProcCategory = ({ data }: iProps) => {
	
	const { addNew, collapsed, editing, handleSubmit, handleProcedureSubmit, isLoading, name, onFieldChange, procedures, setCollapsed, toggleAddNew, toggleEdition } = useProcCategory(data);	
	const css = useStyles();

	return (
    <Card className={clsx(css.root)}>
			<IconButton onClick={toggleEdition} className={css.editButton}>
				<Edit />
			</IconButton>
			<CardContent className={css.titleBar} >
        <Typography variant="h3" component="h3" className={css.title}>
          <div className={css.titleArea}>
						{
							editing() 
							? (
								// TODO this should be a new component called ProcCategoryForm
								<form onSubmit={(event) => handleSubmit(event, data.id)} className={css.form}>
									<Grid container spacing={3}>
										<Grid item xs={7}>
											<FormControl fullWidth margin="normal">
												<TextField
													autoFocus
													required
													onChange={onFieldChange}
													name="name"
													label="Name"
													value={name}
												/>
											</FormControl>
										</Grid>
										<Grid item xs={5}>
											<Button
												style={{ marginTop: 25, marginRight: 25 }}
												onClick={toggleEdition}
												type="button"
												variant="contained"
												color="default">
												Cancel
											</Button>

											<Button
												style={{ marginTop: 25 }}
												type="submit"
												variant="contained"
												color="primary">
												Save Category
											</Button>
										</Grid>
									</Grid>
								</form>
							) 
							: name }
            <IconButton onClick={()=> setCollapsed(!collapsed) }>
              {collapsed ? <ExpandLess style={{ color: "rgba(0, 0, 0, 0.54)" }} /> : <ExpandMore style={{ color: "rgba(0, 0, 0, 0.54)" }} />}
            </IconButton>
          </div>
        </Typography>
			</CardContent>
			<Collapse in={!collapsed} timeout="auto" unmountOnExit>
				<div className={css.cardBody}>
					<CardContent className={css.cArea}>
						<List>
              {procedures.map(procedure => <ProcedureItem key={procedure.id} data={procedure} />)}
            </List>
						<Button fullWidth className={css.approveBtn} onClick={toggleAddNew} color="primary" variant="contained">Add Procedure</Button>
						<Dialog
								fullWidth
								maxWidth="lg"
								aria-labelledby="simple-dialog-title"
								open={addNew()}
								onBackdropClick={toggleAddNew}
							>
								<DialogTitle id="simple-dialog-title">Add new procedure</DialogTitle>								
								<DialogContent dividers>
									<ProcedureForm cancelAction={toggleAddNew} handleSubmit={handleProcedureSubmit} loading={isLoading()} edition={true} />
								</DialogContent>
							</Dialog>
					</CardContent>					
				</div>
			</Collapse>
		</Card>
	);
}

export default ProcCategory;