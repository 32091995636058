import { useCallback, useContext, useEffect, useState } from "react";
import { apiInstance } from "../../../common/api";
import { Severity } from "../../../common/enums/severity.enum";
import useAPINotification from "../../../common/hooks/useAPINotification";
import { APINotificationActionTypes } from "../../../common/providers/APINotification/enums/APINotificationActionType.enum";
import { GlobalStateContext } from "../../../common/providers/global-state-provider/global-state.context";
import { ProcedureCategory } from "../../../models";
import { SubProcedureText } from "../../../models/SubProcedureText";

export default function useSubProcedureTexts(){

  const { dispatchNotification } = useAPINotification();
  const { globalState } = useContext(GlobalStateContext);

  const [ procedures, setProcedures ] = useState<ProcedureCategory[]>([]);
  const [ subProcedureTexts, setSubProcedureTexts ] = useState<SubProcedureText[]>([]);

  const fetchProcedures = useCallback(async () => {
    try{

      const res = await apiInstance.get("/me/procedures");
      
      if (res.data) {
				setProcedures(res.data);
			}
    } catch (err) {

      console.log(err);
      dispatchNotification({ type: APINotificationActionTypes.SET_NOTIFICATION, payload: { message: "Error trying to get procedures.", severity: Severity.ERROR } });
    }
  }, [dispatchNotification]);

  const fetchSubProcedureTexts = useCallback(async () => {
    try{

      const res = await apiInstance.get(`/sub-procedure-texts?filter=userId||$eq||${globalState.user.id}`);

      if (res.data) {
				setSubProcedureTexts(res.data);
			}
    } catch (err) {

      console.log(err);
      dispatchNotification({ type: APINotificationActionTypes.SET_NOTIFICATION, payload: { message: "Error trying to get sub-procedure texts.", severity: Severity.ERROR } });
    }
  }, [dispatchNotification, globalState.user.id]);

  useEffect(() => {

    fetchProcedures();
    fetchSubProcedureTexts();
  }, [fetchProcedures, fetchSubProcedureTexts]);

  return {
    procedures,
    subProcedureTexts
  }
}