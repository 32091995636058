import { useEffect, useReducer } from "react";
import { RequestQueryBuilder } from "@nestjsx/crud-request";
import { ConsentFormReducer } from "../ConsentForm.reducer";
import { apiInstance } from "../../../api";
import useAPINotification from "../../../hooks/useAPINotification";
import { APINotificationActionTypes } from "../../APINotification/enums/APINotificationActionType.enum";
import { Severity } from "../../../enums/severity.enum";
import { ConsentForm } from "../../../../models";
import { ConsentFormProviderActionType } from "../enums/ConsentFormProviderActionType.enum";

export const initialState: ConsentForm = {
  id: "",
  locked: false,
  patientSubmittedAt: "",
  status: "",
  patientId: "",
  clinicId: "",
  procedureId: "",
  createdBy: "",
  updatedBy: "",
  createdAt: "",
  updatedAt: "",
  answers: [],
  procedure: null as any,
  patient: null as any,
  user: null as any,
  clinic: null as any,
  resources: [],
  qualityAssurances: [],
  flags: [],
  subProcedures: [],
};

export default function useConsentFormProvider(consentFormId: string) {
  const [consentForm, dispatch] = useReducer(ConsentFormReducer, initialState);
  const { dispatchNotification } = useAPINotification();

  useEffect(() => {
    const fetchConsentForm = async () => {
      try {
        const qb = RequestQueryBuilder.create();
        qb.select([
          "id",
          "patientSubmittedAt",
          "locked",
          "updatedAt",
          "status",
          "createdAt",
          "clinicId",
          "patientId",
          "procedureId",
          "userId",
        ])
          .setJoin({ field: "clinic", select: ["id", "name"] })
          .setJoin({ field: "patient" })
          .setJoin({ field: "user" })
          .setJoin({
            field: "answers",
            select: [
              "id",
              "understood",
              "riskId",
              "considerationId",
              "benefitId",
              "qualityAssuranceId",
              "questionId",
              "updatedAt",
              "value",
              "moreDetails",
              "moreDetails",
            ],
          })
          .setJoin({
            field: "resources",
            select: [
              "id",
              "location",
              "meta",
              "patientId",
              "userId",
              "createdAt",
            ],
          })
          .setJoin({
            field: "flags",
            select: [
              "id",
              "comment",
              "important",
              "considerationId",
              "benefitId",
              "riskId",
              "updatedAt",
            ],
          })
          .setJoin({ field: "subProcedures", select: ["id", "name"] })
          .query();

        const res = await apiInstance.get(
          `/consent-forms/${consentFormId}?${qb.queryString}`
        );

        if (res.status === 200) {
          dispatch({
            type: ConsentFormProviderActionType.SET_CONSENT_FORM,
            payload: res.data,
          });
        }
      } catch (error: any) {
        console.log(error);
        let msg = error.dispatchNotification({
          type: APINotificationActionTypes.SET_NOTIFICATION,
          payload: {
            message: "Error trying to get the consent application",
            severity: Severity.ERROR,
          },
        });
      }
    };

    fetchConsentForm();
  }, [consentFormId, dispatchNotification]);

  return {
    consentForm,
    dispatch,
  };
}
