import { useEffect, useState } from "react";
import { apiInstance } from "../../../common/api";
import { Severity } from "../../../common/enums/severity.enum";
import useAPINotification from "../../../common/hooks/useAPINotification";
import { APINotificationActionTypes } from "../../../common/providers/APINotification/enums/APINotificationActionType.enum";

import { Patient } from "../../../models";

export default function useFetchPatient(patientId: string | undefined) {
  const { dispatchNotification } = useAPINotification();
  const [loading, setLoading] = useState<boolean>(false);
  const [patient, setPatient] = useState<Patient | null>(null);
  const [patientNotFound, setPatientNotFound] = useState<boolean>(false);

  useEffect(() => {
    const fetchPatient = async () => {
      try {
        setLoading(true);
        const res = await apiInstance.get("/patients/" + patientId);

        if (res.data) {
          setPatient(res.data);
        }
      } catch (error: any) {
        if (error.response && error.response.status === 404) {
          setPatientNotFound(true);
        }

        dispatchNotification({
          type: APINotificationActionTypes.SET_NOTIFICATION,
          payload: {
            message: "Error trying to get your applications highlights.",
            severity: Severity.ERROR,
          },
        });
      } finally {
        setLoading(false);
      }
    };

    if (patientId) {
      fetchPatient();
    }
  }, [dispatchNotification, patientId]);

  return {
    patientNotFound,
    patient,
    loading,
  };
}
