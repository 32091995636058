import { Reducer } from "react";
import { Highlight } from "../../../models";
import { GlobalStateProviderActionType } from "./enums/global-state-provider-action-type.enum";
import { GlobalStateProviderAction } from "./interfaces/global-state-provider-action.interface";
import { GlobalState, initialState } from "./interfaces/global-state.interface";

export const GlobalStateReducer: Reducer<GlobalState, GlobalStateProviderAction> = (globalState, action) => {
	const { type, payload } = action;
	switch (type) {

		case GlobalStateProviderActionType.RESET_STATE:
			
			return initialState;

		case GlobalStateProviderActionType.SET_ALERT:
			return { ...globalState, alert: payload }

		case GlobalStateProviderActionType.SET_APPLICATIONS_HIGHLIGHTS:
			return { ...globalState, applicationsHighlights: payload }

		case GlobalStateProviderActionType.SET_PARAMS_CURRENT_FREQ:
			return { ...globalState, params_currentFreq: payload }

		case GlobalStateProviderActionType.SET_PARAMS_CURRENT_PROCEDURE:
			return { ...globalState, params_currentProcedure: payload }

		case GlobalStateProviderActionType.SET_USER:
			return { ...globalState, user: payload }

		case GlobalStateProviderActionType.UPDATE_APPLICATIONS_HIGHLIGHTS:
			return { 
				...globalState, 
				applicationsHighlights: globalState.applicationsHighlights.reduce((newArray, nextItem) => {

					if( nextItem.status !== payload){
						newArray.push(nextItem);
					}

					if( nextItem.status === payload && nextItem.count > 1){
						newArray.push({ status: nextItem.status, count: nextItem.count-1 })
					}
					return newArray
				}, [] as Highlight[])
			}

		default:
			return globalState;
	}
}