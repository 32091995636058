import { Box, Fade, Paper } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

const Loading = () => {
	const inputHeight = 35;
	const buttonHeight = 35;

	return (
		<Paper elevation={1} component="section">
			<Fade in={true} timeout={1500}>
				<div style={{ padding: 15 }}>
					<Box display="flex" mb={1}>
						<Skeleton animation="wave" style={{ marginRight: 15, height: inputHeight }} width="33.3%" />
						<Skeleton animation="wave" style={{ marginRight: 15, height: inputHeight }} width="33.3%" />
						<Skeleton animation="wave" style={{ height: inputHeight }} width="33.3%" />
					</Box>

					<Box display="flex" mb={1}>
						<Skeleton animation="wave" style={{ marginRight: 15, height: inputHeight }} width="33.3%" />
						<Skeleton animation="wave" style={{ marginRight: 15, height: inputHeight }} width="33.3%" />
						<Skeleton animation="wave" style={{ height: inputHeight }} width="33.3%" />
					</Box>

					<Box display="flex" mb={1}>
						<Skeleton animation="wave" style={{ marginRight: 15, height: inputHeight }} width="33.3%" />
						<Skeleton animation="wave" style={{ marginRight: 15, height: inputHeight }} width="33.3%" />
						<Skeleton animation="wave" style={{ height: inputHeight }} width="33.3%" />
					</Box>

					<Box display="flex" mb={1}>
						<Skeleton animation="wave" style={{ marginRight: 15, height: inputHeight }} width="33.3%" />
						<Skeleton animation="wave" style={{ marginRight: 15, height: inputHeight }} width="33.3%" />
						<Skeleton animation="wave" style={{ height: inputHeight }} width="33.3%" />
					</Box>

					<Box display="flex" mt={1}>
						<Skeleton animation="wave" style={{ marginRight: 12, height: buttonHeight }} width={120} />
						<Skeleton animation="wave" style={{ height: buttonHeight }} width={120} />
					</Box>
				</div>
			</Fade>
		</Paper>
	);
}

export default Loading;