import { useContext, useEffect, useState } from "react";
import { apiInstance } from "../../../../common/api";
import { Severity } from "../../../../common/enums/severity.enum";
import useAPINotification from "../../../../common/hooks/useAPINotification";
import { APINotificationActionTypes } from "../../../../common/providers/APINotification/enums/APINotificationActionType.enum";
import { GlobalStateContext } from "../../../../common/providers/global-state-provider/global-state.context";
import { PatientProviderContext } from "../../../../common/providers/PatientProvider/Patient.context";
import { Interaction, User } from "../../../../models";

export default function useInteractionItem(interaction: Interaction) {

  const { globalState } = useContext(GlobalStateContext);
  const { patient } = useContext(PatientProviderContext);
  const { dispatchNotification } = useAPINotification();
  const [ interactionState, setInteractionState ] = useState<Interaction>(interaction);
  
  const [ user, setUser ] = useState<User>();

  useEffect(() => {

    const fetchUser = async () => {
      try{
              
        const res = await apiInstance.get(`/users/${interactionState.userId}`);                    
        
        if(res.status === 200){
          setUser(res.data);
        }
      } catch(err) {

      console.log(err);
      dispatchNotification({ type: APINotificationActionTypes.SET_NOTIFICATION, payload: { message: "Error trying to get user", severity: Severity.ERROR } })
      }
    }
    if(interactionState.userId) {
      
      interactionState.userId !== globalState.user.id ? fetchUser() : setUser(globalState.user);
    }
  }, [interactionState.userId, dispatchNotification, globalState.user]);

  function updateInteraction( update: Interaction ){
    setInteractionState({ ...interaction, ...update });
  }

	return {
    interactionState,
    patient,
    updateInteraction,
    user,
	}
}