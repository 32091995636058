import { useState, ChangeEvent, FormEvent, useContext, useEffect } from "react";

import { appConfig } from "../../../constants";
import { fakePatient } from "../../../lib";

import { useFetchPatient } from ".";
import { Patient } from "../../../models";
import { apiInstance } from "../../../common/api";
import { GlobalStateContext } from "../../../common/providers/global-state-provider/global-state.context";
import { GlobalStateProviderActionType } from "../../../common/providers/global-state-provider/enums/global-state-provider-action-type.enum";

interface FormFields extends Partial<Patient> {
  confirmEmail?: string;
}

export default function usePatientForm(
  patientId: string | "",
  setPatientId: (v: string) => void
) {
  const { dispatchToGlobal } = useContext(GlobalStateContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [abort, setAbort] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const {
    patient,
    patientNotFound,
    loading: patientLoading,
  } = useFetchPatient(patientId);

  useEffect(() => {
    if (patient) {
      setValues({ ...patient, confirmEmail: patient.email });
    }
  }, [patient]);

  const useFakeData = appConfig.FAKER && !patientId;

  const fakeEmail = fakePatient().email;

  const [values, setValues] = useState<FormFields>({
    title: useFakeData ? fakePatient().title : "",
    firstName: useFakeData ? fakePatient().firstName : "",
    lastName: useFakeData ? fakePatient().lastName : "",
    dob: useFakeData ? fakePatient().dob : "",
    insuranceAuthorisationCode: useFakeData
      ? fakePatient().insuranceAuthorisationCode
      : "",
    NHSnumber: useFakeData ? fakePatient().NHSnumber : "",
    privateInsuranceNumber: useFakeData
      ? fakePatient().privateInsuranceNumber
      : "",
    phone: useFakeData ? fakePatient().phone : "",
    email: useFakeData ? fakeEmail : "",
    confirmEmail: useFakeData ? fakeEmail : "",
    sex: useFakeData ? fakePatient().sex : "",
    addressLine1: useFakeData ? fakePatient().addressLine1 : "",
    addressLine2: useFakeData ? fakePatient().addressLine2 : "",
    city: useFakeData ? fakePatient().city : "",
    county: useFakeData ? fakePatient().county : "",
    postcode: useFakeData ? fakePatient().postcode : "",
  });

  function onFieldChange(event: ChangeEvent<any>) {
    setValues({ ...values, [event.target.name]: event.target.value });
  }

  const handleSubmit = async (
    event: FormEvent<HTMLFormElement>,
    patientId: string | ""
  ) => {
    event.preventDefault();

    try {
      setError(null);
      setLoading(true);
      if (values.email === values.confirmEmail) {
        const res = patientId
          ? await apiInstance.patch("/patients/" + patientId, values)
          : await apiInstance.post("/patients", values);

        if (res.status === 201 || res.status === 200) {
          dispatchToGlobal({
            type: GlobalStateProviderActionType.SET_ALERT,
            payload: {
              content: `Patient successfully ${
                patientId ? "updated" : "created"
              }`,
              severity: "success",
            },
          });
          setPatientId(res.data.id);
          setSuccess(true);
        }
      } else {
        setError("The email and email confirmation fields do not match.");
      }
    } catch (error: any) {
      console.log(error);

      switch (error.response.status) {
        case 409:
          setError(
            "A patient already exists in your clinic with this email address."
          );
          break;

        default:
          setError("Unexpected error creating patient, please try again.");
          break;
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    values,
    setValues,
    onFieldChange,
    handleSubmit,
    loading,
    patientNotFound,
    patientLoading,
    success,
    abort,
    setAbort,
    error,
  };
}
