import React from "react";
import { Box, Grid, TextField, Typography } from "@material-ui/core";
import usePatientDetails from "./hooks";
import { Loading } from "../../patients/PatientOverview";
import { format } from "date-fns";

const PatientDetails = () => {

  const { patient } = usePatientDetails();

  if(patient.id === ""){
    return <Loading />
  }

  return (
    <Box component="section" m={3}>
      <Typography variant="h6">Patient Details</Typography>
      <Grid container spacing={4}>
        <Grid container item xs={12} spacing={6}>
          <Grid item lg={4}>
            <TextField
              fullWidth
              label="Title"
              defaultValue={`${patient.title || " "}`}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item lg={4}>
            <TextField
              fullWidth
              label="First name"
              defaultValue={`${patient.firstName || " "}`}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item lg={4}>
            <TextField
              fullWidth
              label="Last name"
              defaultValue={`${patient.lastName || " "}`}
              InputProps={{ readOnly: true }}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={6}>
          <Grid item lg={4}>
            <TextField
              fullWidth
              label="Email address"
              defaultValue={`${patient.email || " "}`}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item lg={3}>
            <TextField
              fullWidth
              label="Phone"
              defaultValue={`${patient.phone || " "}`}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item lg={3}>
            <TextField
              fullWidth
              label="Date of birth"
              defaultValue={`${format(new Date(patient.dob), "dd/MM/yyyy") || " "}`}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item lg={2}>
            <TextField
              fullWidth
              label="Gender"
              defaultValue={`${patient.sex || " "}`}
              InputProps={{ readOnly: true }}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={6}>
          <Grid item lg={4}>
            <TextField
              fullWidth
              label="NHS Number"
              defaultValue={`${patient.NHSnumber || " "}`}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item lg={4}>
            <TextField
              fullWidth
              label="Private insurance number"
              defaultValue={`${patient.privateInsuranceNumber || " "}`}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item lg={4}>
            <TextField
              fullWidth
              label="Insurance authorisation code"
              defaultValue={`${patient.insuranceAuthorisationCode || " "}`}
              InputProps={{ readOnly: true }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default PatientDetails;