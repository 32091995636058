import React from "react";

import { Button, FormControl, makeStyles, TextField } from "@material-ui/core";
import useInteractionForm from "./hooks";
import { Interaction } from "../../../models";

interface Props {
  interactionId: string;
  updateInteraction: (update: Interaction) => void;  
}

const useStyles = makeStyles((theme) => ({
  textField: {
    display: 'block'
  }
}));

const InteractionForm = ({ interactionId, updateInteraction }: Props) => {

  const css = useStyles();
  const { handleChange, handleSubmit, state } = useInteractionForm({interactionId, updateInteraction});
  
	return (
    <form onSubmit={handleSubmit}>
      <FormControl className={css.textField}>
        <TextField          
          required
          label="Health professional response:"
          multiline
          rows={4}
          variant="outlined"
          name="userComment"
          value={state.userComment}
          onChange={handleChange}
        />
      </FormControl>
      <Button
        style={{marginTop: 15}}
        disabled={ state.userComment === "" }
        type="submit"
        variant="contained"
        color="primary">
        Save response
      </Button>
    </form>
	);
};

export default InteractionForm;