import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  createStyles,
  makeStyles,
  Theme,
  Typography,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  CircularProgress,
  Fade,
} from "@material-ui/core";
import {
  Assignment,
  FiberManualRecord,
  InfoOutlined,
} from "@material-ui/icons";
import { ConsentForm, Patient } from "../../../models";
import { CreateApplication, Loading } from ".";
import { useFetchConsentApplications } from "../hooks";
import { format } from "date-fns";
import useGetColor from "../../../common/hooks/useGetColor";

interface Props {
  patient: Patient | null;
}

interface StatusProps {
  application: ConsentForm;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 15,
      boxSizing: "border-box",
    },
    title: {
      fontSize: 24,
      display: "flex",
      alignItems: "center",
      marginBottom: 15,
    },
    tableContainer: {
      marginTop: 15,
    },
    buttons: {
      marginRight: 10,
    },
  })
);

const PatientApplications = ({ patient }: Props) => {
  const css = useStyles();
  const patientId = patient?.id;

  const { consentApps, fetchConsentForms } = useFetchConsentApplications();
  const { getColor } = useGetColor();

  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const closeCreateModal = () => setShowCreateModal(false);

  const openCreateModal = () => setShowCreateModal(true);

  // Fetch consent application on load and then
  // everytime the create modal is closed
  useEffect(() => {
    if (showCreateModal === false) {
      fetchConsentForms(patientId);
    }
  }, [fetchConsentForms, patientId, showCreateModal]);

  const Status = ({ application }: StatusProps) => {
    return (
      <Box display="flex" alignItems="center">
        <FiberManualRecord
          fontSize="small"
          style={{ color: getColor(application.status), marginRight: 5 }}
        />
        <Typography variant="caption">{application.status}</Typography>
      </Box>
    );
  };

  return (
    <Paper elevation={1} component="section" className={css.container}>
      {patient && (
        // Opens modal to create consent application
        <CreateApplication
          show={showCreateModal}
          close={closeCreateModal}
          patientId={patient.id}
        />
      )}

      <Typography component="h2" className={css.title}>
        <Assignment style={{ marginRight: 15 }} color="secondary" /> Consent
        Applications
      </Typography>

      {/* Show message if there are no consent forms created yet */}
      {consentApps && consentApps.length === 0 && (
        <Box mb={2} display="flex" alignItems="center">
          <InfoOutlined
            fontSize="small"
            color="action"
            style={{ marginRight: 10 }}
          />
          <Typography component="p" variant="subtitle1">
            This patient does not yet have any consent applications.
          </Typography>
        </Box>
      )}

      <Button
        disabled={!patient || !consentApps}
        onClick={openCreateModal}
        disableElevation
        variant="contained"
        color="primary"
      >
        New Consent Application{" "}
        {(!patient || !consentApps) && (
          <CircularProgress style={{ marginLeft: 15 }} size={15} />
        )}
      </Button>

      {!consentApps && <Loading />}

      {consentApps && consentApps.length > 0 && (
        <TableContainer className={css.tableContainer}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Procedure</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Created By</TableCell>
                <TableCell>Status</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {consentApps.map((app) => {
                return (
                  <Fade in={true} key={app.id}>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {app.procedure!.name}
                      </TableCell>
                      <TableCell>
                        {format(new Date(app.createdAt), "dd/MM/yyyy, HH:mm")}
                      </TableCell>
                      <TableCell>{app.createdBy}</TableCell>
                      <TableCell>
                        <Status application={app} />
                      </TableCell>
                      <TableCell>
                        <Button
                          className={css.buttons}
                          component={RouterLink}
                          to={`/applications/${app.id}`}
                          variant="outlined"
                          color="primary"
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  </Fade>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
};

export default PatientApplications;
