import {
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  Grow,
  Input,
  InputLabel,
  ListSubheader,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { Fragment, useContext } from "react";
import { ProcedureCategory, User } from "../../../models";
import useUserForm from "./hooks";
import { Redirect } from "react-router-dom";
import ProfilePic from "../ProfilePic/ProfilePic";
import { RoleTypes } from "../../../common/enums/roleTypes.enum";
import { GlobalStateContext } from "../../../common/providers/global-state-provider/global-state.context";
import { Alert } from "@material-ui/lab";

interface Props {
  currentUser: User;
  setAbort: (v: boolean) => void;
  userId?: string;
  editingSelf?: boolean;
}

// interface Procedure {
// 	id: string;
// 	name: string;
// 	procedureCategoryId: string
// }

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  proceduresSelectArea: {
    marginTop: 25,
  },
}));

const UserForm = ({ currentUser, setAbort, userId, editingSelf }: Props) => {
  const {
    emailsMatch,
    error,
    values,
    setAvatar,
    showConfirmEmail,
    onFieldChange,
    handleSubmit,
    loading,
    success,
    dropdownVals,
  } = useUserForm(currentUser, userId, editingSelf);

  const avatarResources =
    currentUser.resources &&
    currentUser.resources.length &&
    currentUser.resources.filter((r) => r.type === "avatar");
  const avatar =
    avatarResources && avatarResources.length
      ? avatarResources[avatarResources.length - 1].location
      : null;

  const classes = useStyles();
  const { globalState } = useContext(GlobalStateContext);

  if (success && !loading) {
    return (
      <Redirect to={{ pathname: "/users", state: { persistAlert: true } }} />
    );
  }

  if (loading) {
    return <span>loading</span>;
  }

  return (
    <form onSubmit={(event) => handleSubmit(event, userId)}>
      <Grid container spacing={3}>
        {userId && (editingSelf || userId === currentUser.id) && (
          <Grid item lg={12}>
            <ProfilePic
              userId={userId}
              existing={avatar || ""}
              setAvatar={setAvatar}
            />
          </Grid>
        )}

        <Grid item lg={8}>
          <Grid container spacing={3}>
            <Grid item lg={4}>
              <FormControl fullWidth margin="normal">
                <TextField
                  autoFocus
                  required
                  onChange={onFieldChange}
                  name="title"
                  label="Title"
                  value={values.title}
                />
              </FormControl>
            </Grid>

            <Grid item lg={4}>
              <FormControl fullWidth margin="normal">
                <TextField
                  onChange={onFieldChange}
                  required
                  name="firstName"
                  label="First name"
                  value={values.firstName}
                />
              </FormControl>
            </Grid>

            <Grid item lg={4}>
              <FormControl fullWidth margin="normal">
                <TextField
                  onChange={onFieldChange}
                  required
                  name="lastName"
                  label="Last name"
                  value={values.lastName}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item lg={6}>
              <FormControl margin="normal" fullWidth>
                <TextField
                  required
                  type="email"
                  onChange={onFieldChange}
                  name="email"
                  label="Email"
                  value={values.email}
                />
              </FormControl>
            </Grid>
            <Grid item lg={4}>
              {showConfirmEmail ? (
                <FormControl margin="normal" fullWidth>
                  <TextField
                    error={!emailsMatch}
                    helperText={
                      values.email !== values.confirmEmail
                        ? "emails don't match"
                        : ""
                    }
                    required
                    type="email"
                    onChange={onFieldChange}
                    name="confirmEmail"
                    label="Confirm Email"
                    value={values.confirmEmail}
                  />
                </FormControl>
              ) : null}
            </Grid>

            <Grid item lg={6}>
              <FormControl margin="normal" fullWidth>
                <TextField
                  onChange={onFieldChange}
                  name="addressLine1"
                  label="Address line 1"
                  value={values.addressLine1}
                />
              </FormControl>
            </Grid>

            <Grid item lg={6}>
              <FormControl margin="normal" fullWidth>
                <TextField
                  onChange={onFieldChange}
                  name="addressLine2"
                  label="Address line 2"
                  value={values.addressLine2}
                />
              </FormControl>
            </Grid>

            <Grid item lg={4}>
              <FormControl margin="normal" fullWidth>
                <TextField
                  onChange={onFieldChange}
                  name="city"
                  label="City"
                  value={values.city}
                />
              </FormControl>
            </Grid>

            <Grid item lg={4}>
              <FormControl margin="normal" fullWidth>
                <TextField
                  onChange={onFieldChange}
                  name="county"
                  label="County"
                  value={values.county}
                />
              </FormControl>
            </Grid>

            <Grid item lg={4}>
              <FormControl margin="normal" fullWidth>
                <TextField
                  onChange={onFieldChange}
                  name="postcode"
                  label="Postcode"
                  value={values.postcode}
                />
              </FormControl>
            </Grid>

            <Grid item lg={6}>
              <FormControl margin="normal" fullWidth>
                <TextField
                  required
                  onChange={onFieldChange}
                  name="jobTitle"
                  label="Job title"
                  value={values.jobTitle}
                />
              </FormControl>
            </Grid>
            {editingSelf ? null : (
              <Grid item lg={4}>
                <FormControl margin="normal" fullWidth>
                  <TextField
                    required
                    select
                    onChange={onFieldChange}
                    name="role"
                    label="Role"
                    value={values.role}
                  >
                    {dropdownVals.roles.map((option: string, i: number) => (
                      <MenuItem key={option + i} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
            )}
          </Grid>

          {(values.role === RoleTypes.CONSULTANT ||
            values.role === RoleTypes.CLINIC_ADMIN) && (
            <Fragment>
              {globalState.user.role === RoleTypes.ADMIN && (
                <Fragment>
                  {editingSelf ? null : (
                    <Grid item lg={12}>
                      <FormControl margin="normal" fullWidth>
                        <TextField
                          required
                          select
                          onChange={onFieldChange}
                          name="clinicId"
                          label="Clinic"
                          value={values.clinicId}
                        >
                          {dropdownVals.clinics.map(
                            (
                              option: { id: string; name: string },
                              i: number
                            ) => {
                              return (
                                <MenuItem
                                  key={option.id + i}
                                  value={option.id}
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {option.name}
                                </MenuItem>
                              );
                            }
                          )}
                        </TextField>
                      </FormControl>
                    </Grid>
                  )}
                </Fragment>
              )}
              {values.role !== RoleTypes.CLINIC_ADMIN && (
                <section className={classes.proceduresSelectArea}>
                  <Grid container spacing={3}>
                    <Grid item lg={12}>
                      <Typography component="h2" variant="h6">
                        Practising procedures
                      </Typography>
                      <Typography component="p">
                        Select the speciality, sub speciality and the procedures
                        relevant to the consultant.
                      </Typography>
                    </Grid>
                    <Grid item lg={6}>
                      <FormControl margin="normal" fullWidth>
                        <TextField
                          required
                          select
                          onChange={onFieldChange}
                          name="speciality"
                          label="Speciality"
                          value={values.specialityId}
                        >
                          {dropdownVals.specialities.map(
                            (
                              option: { id: string; name: string },
                              i: number
                            ) => (
                              <MenuItem
                                key={option.id + i}
                                value={option.id}
                                style={{ textTransform: "capitalize" }}
                              >
                                {option.name}
                              </MenuItem>
                            )
                          )}
                        </TextField>
                      </FormControl>
                    </Grid>
                    <Grid item lg={6}>
                      <FormControl margin="normal" fullWidth>
                        <TextField
                          required
                          select
                          onChange={onFieldChange}
                          name="subSpeciality"
                          label="Sub speciality"
                          value={values.subSpecialityId}
                        >
                          {dropdownVals.subSpecialities.map(
                            (
                              option: { id: string; name: string },
                              i: number
                            ) => (
                              <MenuItem
                                key={option.id + i}
                                value={option.id}
                                style={{ textTransform: "capitalize" }}
                              >
                                {option.name}
                              </MenuItem>
                            )
                          )}
                        </TextField>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item lg={12}>
                      <FormControl margin="normal" fullWidth>
                        <InputLabel htmlFor="grouped-select">
                          Procedures
                        </InputLabel>
                        <Select
                          required
                          labelId="procedures-chip-label"
                          id="procedures-mutiple-chip"
                          name="procedures"
                          multiple
                          value={values.procedures}
                          onChange={onFieldChange}
                          input={<Input id="procedures-multiple-chip" />}
                          renderValue={(selected: any) => (
                            <div className={classes.chips}>
                              {selected.map((value: any) =>
                                dropdownVals.procedureCategories.map(
                                  (procedure: any) =>
                                    procedure.id === value && (
                                      <Chip
                                        key={procedure.name}
                                        label={procedure.name}
                                        className={classes.chip}
                                      />
                                    )
                                )
                              )}
                            </div>
                          )}
                          MenuProps={MenuProps}
                        >
                          {dropdownVals.procedureCategories.map(
                            (item: ProcedureCategory) => {
                              return item.procedures ? (
                                <ListSubheader
                                  disableSticky={true}
                                  key={item.id}
                                  style={{
                                    textTransform: "capitalize",
                                    lineHeight: "1.4rem",
                                    marginTop: 15,
                                    color: "rgba(0, 0, 0, 0.35)",
                                  }}
                                >
                                  {item.name}
                                </ListSubheader>
                              ) : (
                                <MenuItem
                                  key={item.id}
                                  value={item.id}
                                  style={{
                                    textTransform: "capitalize",
                                    paddingLeft: 28,
                                  }}
                                >
                                  {item.name}
                                </MenuItem>
                              );
                            }
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>
              )}
            </Fragment>
          )}

          <Button
            style={{ marginTop: 25, marginRight: 25 }}
            onClick={() => setAbort(true)}
            type="button"
            variant="contained"
            color="default"
          >
            Back
          </Button>

          <Button
            style={{ marginTop: 25 }}
            type="submit"
            disabled={loading}
            variant="contained"
            color="primary"
          >
            {userId ? "Update" : "Create"} User{" "}
            {loading && (
              <CircularProgress style={{ marginLeft: 15 }} size={15} />
            )}
          </Button>
        </Grid>
      </Grid>

      {error && (
        <Box mt={5}>
          <Grow in={true} timeout={1000}>
            <Alert
              style={{ margin: "15px 0" }}
              variant="filled"
              severity="error"
            >
              {error}
            </Alert>
          </Grow>
        </Box>
      )}
    </form>
  );
};

export default UserForm;
