import { useState } from "react";

export default function useEditClinic() {
  const [abort, setAbort] = useState<boolean>(false);

  return {
    abort,
    setAbort,
  };
}
