import { useEffect, useReducer } from "react";

import { apiInstance } from "../../../api";

import useAPINotification from "../../../hooks/useAPINotification";
import { APINotificationActionTypes } from "../../APINotification/enums/APINotificationActionType.enum";
import { Severity } from "../../../enums/severity.enum";
import { PatientReducer } from "../Patient.reducer";
import { PatientProviderActionType } from "../enums/PatientProviderActionType.enum";

const initialState = {
	id: "",
	title: "",
	firstName: "",
	lastName: "",
	dob: "",
	insuranceAuthorisationCode: "",
	NHSnumber: "",
	privateInsuranceNumber: "",
	phone: "",
	email: "",
	createdAt: "",
	updatedAt: "",
	sex: "",
	addressLine1: "",
	addressLine2: "",
	city: "",
	county: "",
	postcode: "",
};


export default function useCPatientProvider(patientId?: string) {
  
  const [ patient, patientProviderDispatch ] = useReducer(PatientReducer, initialState);
	const { dispatchNotification } = useAPINotification();

	useEffect(() => {
		const fetchConsentForm = async () => {
			try {
	
				const res = await apiInstance.get(`/patients/${patientId}?`);
	
				if (res.status === 200) {
					patientProviderDispatch({ type: PatientProviderActionType.SET_PATIENT, payload:res.data });
				}
			} catch (error) {
				console.log(error);
				dispatchNotification({ type: APINotificationActionTypes.SET_NOTIFICATION, payload: { message: "Error trying to get the patient", severity: Severity.ERROR } });
			}
    };
    
    if(patientId){

      fetchConsentForm();
    }
  }, [patientId, dispatchNotification]);
  
	return {
		patient,
		patientProviderDispatch,
	};
}