import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import LoggedInTemplate from "../../templates/LoggedIn";
import PopConfirm from "../../PopConfirm";
//import UserForm from "../user-form";
import { GlobalStateContext } from "../../../common/providers/global-state-provider/global-state.context";
import useAddClinic from "./hooks/use-add-clinic";
import ClinicForm from "../clinic-form/ClinicForm";

const ClinicsAdd = () => {
  const { globalState } = useContext(GlobalStateContext);
  const { push } = useHistory();
  const { abort, setAbort } = useAddClinic();

  //For the `currentClinic` part, not sure if we even need that and can maybe just use current User for whatever that is used for
  return (
    <LoggedInTemplate title="Add Clinic">
      <PopConfirm
        title="Are you sure?"
        message="Any unsaved data you have entered will be lost."
        show={abort}
        handleClose={() => setAbort(false)}
        continueAction={() => push("/clinics")}
      />
      <Grid container spacing={3}>
        <Grid item lg={12}>
          <ClinicForm setAbort={(v: boolean) => setAbort(v)} />
        </Grid>
      </Grid>
    </LoggedInTemplate>
  );
};

export default ClinicsAdd;
