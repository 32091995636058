import { FormEvent, useCallback, useEffect, useState } from "react"
import update from 'immutability-helper'

import { Boolean } from "../../../common/enums/boolean.enum";
import { Benefit } from "../../../models"
import { apiInstance } from "../../../common/api";
import useAPINotification from "../../../common/hooks/useAPINotification";
import { APINotificationActionTypes } from "../../../common/providers/APINotification/enums/APINotificationActionType.enum";
import { Severity } from "../../../common/enums/severity.enum";

export default function useBenefits() {

	const { dispatchNotification } = useAPINotification();
	const [ benefits, setBenefits] = useState<Benefit[]>([]);
	const [ addNewStatus, setAddNewStatus ] = useState<Boolean>(Boolean.FALSE);
	const [ loading, setLoading ] = useState<Boolean>(Boolean.FALSE);

	useEffect(() =>{
		const fetchBenefits = async () => {

			try{
	
				const res = await apiInstance.get("/benefits?sort=order,ASC&sort=name,ASC");
				if(res.status === 200){
					setBenefits(res.data);
				}
			} catch (error) {
				console.log(error);
				dispatchNotification({ type: APINotificationActionTypes.SET_NOTIFICATION, payload: { message: "Error trying to get benefits.", severity: Severity.ERROR } });
			}
		}

		fetchBenefits();
	}, [dispatchNotification]);

	function addNew(){

    return addNewStatus === Boolean.FALSE ? false : true;
	}
	
	function toggleAddNew(){

    addNewStatus === Boolean.FALSE ? setAddNewStatus(Boolean.TRUE) : setAddNewStatus(Boolean.FALSE);
	}

	function isLoading(){
    return loading === Boolean.TRUE;
  }
	
	async function handleSubmit(event: FormEvent<HTMLFormElement>, data: any) {
    event.preventDefault();
    
    setLoading(Boolean.TRUE);
    try {
      const res = await apiInstance.post(`/benefits`, data);
      
      if (res.status === 201) {
				benefits ? setBenefits(benefits.concat(res.data)) : setBenefits([res.data]);
        setLoading(Boolean.FALSE);
      }
      
    } catch (error) {
      console.log(error);
			dispatchNotification({ type: APINotificationActionTypes.SET_NOTIFICATION, payload: { message: "Error trying to create benefit.", severity: Severity.ERROR } });
    } finally {
      toggleAddNew();
    }
	}

	async function reorderBenefits() {
    
    setLoading(Boolean.TRUE);
    try {
			const updatedBenefits = benefits.map((benefit: Benefit, index) => {

				if(benefit.order !== (index + 1)){
					benefit.order = (index + 1);
				}

				return benefit;
			});

      const res = await apiInstance.patch(`/benefits`, updatedBenefits);
			
      if (res.status === 200) {
        setLoading(Boolean.FALSE);
      }
      
    } catch (error) {
      console.log(error);
			dispatchNotification({ type: APINotificationActionTypes.SET_NOTIFICATION, payload: { message: "Error trying to update benefits.", severity: Severity.ERROR } });
    }
	}
	
	
	const moveBenefit = useCallback(
		(dragIndex: number, hoverIndex: number) => {
			
			const dragBenefit = benefits[dragIndex]
			setBenefits(
				update(benefits, {
					$splice: [
						[dragIndex, 1],
						[hoverIndex, 0, dragBenefit],
					],
				}),
			)
		},
		[benefits],
	)

	return {
		addNew,
		benefits,
		handleSubmit,
		isLoading,
		moveBenefit,
		reorderBenefits,
		toggleAddNew
	}
}