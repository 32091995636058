import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { apiInstance } from "../../../../common/api";

import { Boolean } from "../../../../common/enums/boolean.enum";
import { Severity } from "../../../../common/enums/severity.enum";
import useAPINotification from "../../../../common/hooks/useAPINotification";
import { APINotificationActionTypes } from "../../../../common/providers/APINotification/enums/APINotificationActionType.enum";
import { Procedure, ProcedureCategory } from "../../../../models";

export default function useProcCategory(procedureCategory: ProcedureCategory) {

  const { dispatchNotification } = useAPINotification();
  const [ collapsed, setCollapsed ] = useState<boolean>(true);
  const [ addNewStatus, setAddNewStatus ] = useState<Boolean>(Boolean.FALSE);
  const [ edition, setEdition ] = useState<Boolean>(Boolean.FALSE);
  const [ edited, setEdited ] = useState<Boolean>(Boolean.FALSE);
  const [ name, setName ] = useState<string>("");
  const [ procedures, setProcedures ] = useState<Procedure[]>([]);

  useEffect(() =>{

    setName(procedureCategory.name);
    setProcedures(procedureCategory.procedures || []);
  }, [procedureCategory.name, procedureCategory.procedures])

  function toggleEdition(){
    
    
    if(edited === Boolean.FALSE){
      setName(procedureCategory.name);
    }
    editing() ? setEdition(Boolean.FALSE) : setEdition(Boolean.TRUE);
  }

  function editing(){
    return edition === Boolean.TRUE;
  }

  function toggleAddNew(){
    
    addNew() ? setAddNewStatus(Boolean.FALSE) : setAddNewStatus(Boolean.TRUE);
  }

  function addNew(){
    return addNewStatus === Boolean.TRUE;
  }

  function onFieldChange(event: ChangeEvent<HTMLInputElement>) {
		setName(event.target.value);
  }

  function isLoading(){
    return edition === Boolean.TRUE;
  }

  
  async function handleSubmit(event: any, id: string){
    event.preventDefault();

    try {

      const res = await apiInstance.patch(`/procedure-categories/${id}`, { name });
  
      if(res.status === 200){
        setEdited(Boolean.TRUE);
        setEdition(Boolean.FALSE);
      } else {
        toggleEdition();
      }
    } catch (error) {
      console.log(error);
      dispatchNotification({ type: APINotificationActionTypes.SET_NOTIFICATION, payload: { message: "Error trying to update category.", severity: Severity.ERROR } });
    }
  }

  async function handleProcedureSubmit(event: FormEvent<HTMLFormElement>, data: any) {
    event.preventDefault();
    
    try{
      data.procedureCategoryId = procedureCategory.id;
      const res = await apiInstance.post(`/procedures`, data);
        if(res.status === 201){
          res.data.risks = data.risks;
          res.data.benefits = data.benefits;
          res.data.considerations = data.considerations;
          
          setProcedures(procedures.concat(res.data));
        }
    } catch (error) {
      console.log(error);
      dispatchNotification({ type: APINotificationActionTypes.SET_NOTIFICATION, payload: { message: "Error trying to create procedure.", severity: Severity.ERROR } });
    } finally {
      
      toggleAddNew();
    }
  }

	return {
    addNew,
    collapsed,
    editing,
    handleSubmit,
    handleProcedureSubmit,
    isLoading,
    name,
    onFieldChange,
    procedures,
    setCollapsed,
    toggleAddNew,
    toggleEdition,
	}
}
