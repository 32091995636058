import {
  Chip,
  makeStyles,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React from "react";
import { HighlightOff } from "@material-ui/icons";
import { User } from "../../../models/User";
import { apiInstance } from "../../../common/api";
import { useFetchConsultants } from "../../patients/hooks";

interface Filter {
  field: string;
  value: string[];
}

interface ActiveFiltersProps {
  statusFilters: string[];
  userFilters: string[];
  getColor: (status: string) => string;
  handleFilter: (filter: Filter) => void;
}

const useStyles = makeStyles((theme) => ({
  statusSection: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: "0.5rem",
    marginLeft: "2rem",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));

const ActiveFilters = ({
  statusFilters,
  userFilters,
  getColor,
  handleFilter,
}: ActiveFiltersProps) => {
  const classes = useStyles();
  const { clinicConsultants } = useFetchConsultants();
  const filters = [
    ...statusFilters.map((filter) => ({ value: filter, category: "status" })),
    ...userFilters.map((filter) => ({ value: filter, category: "user" })),
  ].filter((filter) => filter.value !== "All");
  const showIcon = filters.length > 0;

  const onRemoveFilter = (field: string, value: string) => {
    const remainingValues =
      field === "status"
        ? statusFilters.filter((filter) => filter !== value)
        : userFilters.filter((filter) => filter !== value);

    const filter: Filter = {
      field,
      value: remainingValues,
    };

    handleFilter(filter);
  };

  const getUserNameById = (userId: string) => {
    try {
      if (clinicConsultants.length > 0) {
        const user: User = clinicConsultants.filter((c) => c.id === userId)[0];
        if (user) {
          return `${user.title} ${user.firstName} ${user.lastName}`;
        }
      }
    } catch (error) {
      console.error(error);
      return "Error getting user name";
    }
  };

  return (
    <div>
      {showIcon && filters.length > 0 ? (
        <div className={`${classes.statusSection}`}>
          <Typography variant="h3">Filters: </Typography>
          {filters.map((filter, index) => (
            <Chip
              key={index}
              label={
                filter.category === "user"
                  ? getUserNameById(filter.value)
                  : filter.value
              }
              style={{
                backgroundColor:
                  filter.category === "user"
                    ? "#2594A2"
                    : getColor(filter.value),
                color: "white",
                fontWeight: 500,
                fontSize: "0.91rem",
              }}
              icon={
                <Tooltip title="Remove Filter" placement="top">
                  <HighlightOff
                    style={{ color: "white", fontSize: "1.7rem" }}
                    onClick={() =>
                      onRemoveFilter(filter.category, filter.value)
                    }
                  />
                </Tooltip>
              }
            />
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default ActiveFilters;
