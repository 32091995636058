import { FormEvent, useCallback, useEffect, useState } from "react"
import update from 'immutability-helper'

import { Boolean } from "../../../common/enums/boolean.enum";
import { Risk } from "../../../models"
import { apiInstance } from "../../../common/api";
import useAPINotification from "../../../common/hooks/useAPINotification";
import { APINotificationActionTypes } from "../../../common/providers/APINotification/enums/APINotificationActionType.enum";
import { Severity } from "../../../common/enums/severity.enum";

export default function useRisks() {

	const { dispatchNotification } = useAPINotification();
	const [ risks, setRisks] = useState<Risk[]>([]);
	const [ addNewStatus, setAddNewStatus ] = useState<Boolean>(Boolean.FALSE);
	const [ loading, setLoading ] = useState<Boolean>(Boolean.FALSE);

	useEffect(() =>{
		const fetchRisks = async () => {
	
			try {
	
				const res = await apiInstance.get("/risks?sort=order,ASC&sort=name,ASC");
				if(res.status === 200){
					setRisks(res.data);
				}
			} catch (error) {
	
				console.log(error);
				dispatchNotification({ type: APINotificationActionTypes.SET_NOTIFICATION, payload: { message: "Error trying to get risks.", severity: Severity.ERROR } });
			}
		}
		fetchRisks();
	}, [dispatchNotification]);
	

	function addNew(){

    return addNewStatus === Boolean.FALSE ? false : true;
	}
	
	function toggleAddNew(){

    addNewStatus === Boolean.FALSE ? setAddNewStatus(Boolean.TRUE) : setAddNewStatus(Boolean.FALSE);
	}

	function isLoading(){
    return loading === Boolean.TRUE;
  }
	
	async function handleSubmit(event: FormEvent<HTMLFormElement>, data: any) {
    event.preventDefault();
    
    setLoading(Boolean.TRUE);
    try {
			
			data.order = risks[risks.length - 1].order + 1;
      const res = await apiInstance.post(`/risks`, data);
      
      if (res.status === 201) {
				risks ? setRisks(risks.concat(res.data)) : setRisks([res.data]);
        setLoading(Boolean.FALSE);
      }
      
    } catch (error) {
      console.log(error);
			dispatchNotification({ type: APINotificationActionTypes.SET_NOTIFICATION, payload: { message: "Error trying to create risk.", severity: Severity.ERROR } });
    } finally {
      toggleAddNew();
    }
	}

	async function reorderRisks() {
    
    setLoading(Boolean.TRUE);
    try {
			const updatedRisks = risks.map((risk: Risk, index) => {

				if(risk.order !== (index + 1)){
					risk.order = (index + 1);
				}

				return risk;
			});

      const res = await apiInstance.patch(`/risks`, updatedRisks);			
			
      if (res.status === 200) {
        setLoading(Boolean.FALSE);
      }
      
    } catch (error) {
      console.log(error);
			dispatchNotification({ type: APINotificationActionTypes.SET_NOTIFICATION, payload: { message: "Error trying to update risks.", severity: Severity.ERROR } });
    }
	}
	
	const moveRisk = useCallback(
		(dragIndex: number, hoverIndex: number) => {
			
			const dragRisk = risks[dragIndex]
			setRisks(
				update(risks, {
					$splice: [
						[dragIndex, 1],
						[hoverIndex, 0, dragRisk],
					],
				}),
			)
		},
		[risks],
	)

	return {
		addNew,		
		handleSubmit,
		isLoading,
		moveRisk,
		reorderRisks,
    risks,
		toggleAddNew
	}
}