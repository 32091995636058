import React from "react";
import {
  FormControlLabel,
  ListItem,
  ListItemText,
  makeStyles,
  Radio,
  RadioGroup,
  Theme,
} from "@material-ui/core";
import { QualityAssurance } from "../../../models";
import useQualityAssuranceItem from "./hooks";

interface Props {
  qualityAssurance: QualityAssurance;
  disabled: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  radios: {
    width: 250,
    display: "flex",
    justifyContent: "flex-end",
  },
  listItem: {
    backgroundColor: "#ececec",
    marginBottom: "2px",
  },
  no: {
    borderRadius: 3,
    margin: 0,
    marginLeft: 5,
    paddingRight: "15px",
    backgroundColor: "#fff1f0;",
    border: "1px solid #ffa39e",
  },
  yes: {
    borderRadius: 3,
    margin: 0,
    marginLeft: 5,
    paddingRight: "15px",
    backgroundColor: "#f6ffed",
    border: "1px solid #b7eb8f",
  },
}));

const QualityAssuranceItem = ({ disabled, qualityAssurance }: Props) => {
  const { handleRadioChange, answer } =
    useQualityAssuranceItem(qualityAssurance);

  const css = useStyles();

  return (
    <ListItem key={qualityAssurance.id} className={css.listItem}>
      <ListItemText primary={qualityAssurance.text} />
      <RadioGroup
        className={css.radios}
        row
        value={answer ? answer.understood : null}
        onChange={handleRadioChange}
      >
        <FormControlLabel
          disabled={disabled}
          className={css.no}
          value={false}
          control={<Radio color="primary" />}
          label="No"
        />
        <FormControlLabel
          disabled={disabled}
          className={css.yes}
          value={true}
          control={<Radio color="primary" />}
          label="Yes"
        />
      </RadioGroup>
    </ListItem>
  );
};

export default QualityAssuranceItem;
