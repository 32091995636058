import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { format, differenceInCalendarYears } from "date-fns";
import { Patient } from "../../models";
import { Chip, createStyles, makeStyles, Theme } from "@material-ui/core";
import clsx from 'clsx';
import LoggedInTemplate from "../../components/templates/LoggedIn";
import SearchBox from "../../components/search-box";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		sexChip: {
			color: "#fff",
			fontWeight: "bold"
		},
		sexChipMale: {
			backgroundColor: "#5b92e5",
		},
		sexChipFemale: {
			backgroundColor: "#FF69B4",
		}
	}),
);

const Consultants = () => {
	const css = useStyles();
	
	const [data] = useState<Patient[]>([]);
	const [loading] = useState<boolean>(false);

	const calcAge = (dob: Date) => differenceInCalendarYears(new Date(), dob);

	useEffect(() => {
		const fetchPatients = async () => {
			try {

			} catch (error) {

			} finally {

			}
		}

		fetchPatients();
	}, []);

	// Data fetching
	// https://material-table.com/#/docs/features/remote-data

	const handleSearch = (value: string) => {
		// const values = new FormData(event.currentTarget);
		// const searchValue = values.get("search");
		// setLoading(true);

		// // simulate search
		// setTimeout(() => {
		// 	// setData([sampleData[0]]);
		// 	setLoading(false);
		// }, 2000);
	}

	const onSearchClear = () => {
		
	}

	return (
		<LoggedInTemplate title="Consultants">
			<SearchBox handleSearch={handleSearch} loading={loading} />
			<MaterialTable
				isLoading={loading}
				options={{
					padding: "dense",
					search: false,
					showTitle: false,
					toolbar: false,
					actionsColumnIndex: -1,
				}}
				actions={[
					{
						icon: 'visibility',
						tooltip: 'View Patient',
						onClick: (event, rowData) => {
							// Do save operation
						}
					}
				]}
				columns={[
					{ title: "Title", field: "title", width: 50 },
					{ title: "First name", field: "firstName", width: 150 },
					{ title: "Last name", field: "lastName", width: 150 },
					{
						title: "Date of birth",
						field: "dateOfBirth",
						dateSetting: { locale: "en-GB" },
						customSort: (a, b) => calcAge(new Date(a.dob)) - calcAge(new Date(b.dob)),
						render: (rowData: Patient) => <span>{format(new Date(rowData.dob), "dd/MM/yyyy")} ({calcAge(new Date(rowData.dob))})</span>,
						width: 150
					},
					{
						title: "Sex",
						field: "sex",
						width: 100,
						render: (rowData: Patient) => {
							const isMale = rowData.sex === "male";
							const classes = clsx(css.sexChip, isMale ? css.sexChipMale : css.sexChipFemale);
							return <Chip size="small" classes={{ root: classes }} label={rowData.sex} />
						}
					},
					{ title: "Email", field: "email", width: 150 },
					{ title: "Phone", field: "phone", width: 100 },
					{
						title: "Created at",
						field: "createdAt",
						render: (rowData: Patient) => <span>{format(new Date(rowData.createdAt), "dd/MM/yyyy, HH:mm")}</span>,
						width: 150
					},
					{
						title: "Last updated",
						field: "updatedAt",
						render: (rowData: Patient) => <span>{format(new Date(rowData.updatedAt), "dd/MM/yyyy, HH:mm")}</span>,
						width: 150
					},
				]}
				data={data}
			/>
		</LoggedInTemplate>
	);
}

export default Consultants;
