import { Box, List, ListItem, Typography } from "@material-ui/core";
import { FiberManualRecord } from "@material-ui/icons";
import React from "react";
import useGetColor from "../../../common/hooks/useGetColor";
import { User } from "../../../models";
import useRequiresAction from "./hooks";
import { useStyles } from "./requires-action.css";

interface iProps {
  user: User;
}

const RequiresActions = ({ user }: iProps) => {
  const classes = useStyles();
  const { highlights } = useRequiresAction({ user });
  const { getColor } = useGetColor();

  return highlights.length > 0 ? (
    <List className={classes.list}>
      {highlights.map((highlight, index) => (
        <ListItem key={index} className={classes.listItem}>
          <Box component="span" className={classes.listItemText}>
            <FiberManualRecord
              fontSize="small"
              style={{ color: getColor(highlight.status), marginRight: 5 }}
            />
            <Typography>{highlight.status}</Typography>
          </Box>
          <Box component="span" className={classes.listItemCount}>
            {highlight.count}
          </Box>
        </ListItem>
      ))}
    </List>
  ) : (
    <Typography className={classes.congrats} variant="h5">
      Congratulations you are up to date with your consent applications!
    </Typography>
  );
};
export default RequiresActions;
