import { useContext, useMemo, useState } from "react";
import { apiInstance } from "../../../../common/api";
import { Severity } from "../../../../common/enums/severity.enum";
import useAPINotification from "../../../../common/hooks/useAPINotification";
import { APINotificationActionTypes } from "../../../../common/providers/APINotification/enums/APINotificationActionType.enum";
import { ConsentFormContext } from "../../../../common/providers/ConsentFormProvider/ConsentForm.context";
import { ConsentFormProviderActionType } from "../../../../common/providers/ConsentFormProvider/enums/ConsentFormProviderActionType.enum";
import { Topic } from "../../../../models";

export default function useTopicItem(topic: Topic) {

  const { consentForm, dispatch } = useContext(ConsentFormContext);
  const { dispatchNotification } = useAPINotification();
    
  const [ showModal, setShowModal ] = useState(false);
  const [ reviewed, setReviewed ]  = useState(false);
  
  const answer = useMemo(() => {
    
    return consentForm.answers.find(answer => answer.benefitId === topic.id || answer.riskId === topic.id || answer.considerationId === topic.id);

  }, [consentForm.answers, topic.id])

  const flag = useMemo(() => {
    
    return consentForm.flags.find(flag => flag.benefitId === topic.id || flag.riskId === topic.id || flag.considerationId === topic.id);

  }, [consentForm.flags, topic.id])

  function modified() {
    return !answer ? false : !consentForm.locked && (new Date(answer.updatedAt) < new Date(topic.updatedAt))
  }

  const hideThisItem = useMemo(() => {

    return consentForm.locked && (new Date(consentForm.updatedAt) < new Date(topic.createdAt));
  }, [consentForm.locked, consentForm.updatedAt, topic.createdAt]);

  function toggleModal(){
		
		if(showModal && !reviewed){
			setReviewed(true);
		}
		setShowModal(!showModal);
  }
  
  async function updateAnswer(){

    try{

      if(!answer) {
        throw Error("No Answer.");
      }

      const res = await apiInstance.patch(`/answers/${answer.id}`, answer);
      if(res.status === 200){
        dispatch({ type: ConsentFormProviderActionType.UPDATE_ANSWER, payload: res.data });
      }
    } catch(err) {

      console.log(err);     
      dispatchNotification({ type: APINotificationActionTypes.SET_NOTIFICATION, payload: { message: "Error trying to update answer", severity: Severity.ERROR }})
    }
	}

  return {
    answer,
    flag,
    hideThisItem,
    modified,
    reviewed,
    showModal,
    toggleModal,
    updateAnswer,
  }
}