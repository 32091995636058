import React from "react";
import { Checkbox, FormControl, FormControlLabel, FormGroup, Grid, Radio, RadioGroup, TextField, Typography } from "@material-ui/core";
import { QuestionTypes } from "../../../common/enums/question-types.enum";
import { Question } from "../../../models/Question";

import { useTopicQuestionItemStyles } from "./topic-question-item.css";
import useTopicQuestionItem from "./hooks/use-topic-question-item";

/* 
	Individual Risk
*/
interface iProps {
	question: Question;
}

const TopicQuestionItem = ({ question }: iProps) => {

	const classes = useTopicQuestionItemStyles();
	const { answer } = useTopicQuestionItem({question});
	
	return (
		<Grid container className={classes.questionContainer}>
			<Grid item xs={12}>
				<Typography variant="h5" className={classes.questionTitle}>{question.title}</Typography>
				<Typography variant="caption" className={classes.questionDesc}>{question.description}</Typography>
				{
					question.type === QuestionTypes.RADIO_GROUP || question.type === QuestionTypes.RADIO_GROUP_FREE_TEXT
					? (
							<RadioGroup name="value" value={ answer ? answer.value : "" } className={classes.options} >
								{question.options ? question.options.split(",").map( (option, index) => <FormControlLabel key={index} value={option} label={option} disabled control={<Radio />}></FormControlLabel>) : null}
								{question.type === QuestionTypes.RADIO_GROUP_FREE_TEXT ? <FormControlLabel value="other" label="Other" disabled control={<Radio />}></FormControlLabel> : null}
							</RadioGroup>
						)
						: null
				}
				{
					question.type === QuestionTypes.MULTI_CHOICE || question.type === QuestionTypes.MULTI_CHOICE_FREE_TEXT
					? (
						<FormGroup row >
							{question.options ? question.options.split(",").map( (option, index) => <FormControlLabel key={index} label={option} disabled control={<Checkbox checked={answer && answer.value.split(",").find(value => value === option) ? true : false} name={option} value={option} />}></FormControlLabel>) : null}
							{question.type === QuestionTypes.MULTI_CHOICE_FREE_TEXT ? <FormControlLabel label="Other" disabled control={<Checkbox checked={answer && answer.value.split(",").find(value => value === "other") ? true : false} name="Other" value="other" />}></FormControlLabel> : null}
						</FormGroup>
						)
						: null
				}
				 {
          answer && answer.moreDetails
          ? (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
										disabled
                    fullWidth
                    multiline
                    rows={4}
                    variant="filled"
                    name="moreDetails"
                    value={answer.moreDetails}
                  />
                </FormControl>
              </Grid>
            )
          : null
        }
			</Grid>
			{/* {
				answer
				? (
						<Grid item xs={12} className={classes.flagFormContainer}>
							<FlagForm answerId={answer.id} />
						</Grid>
					)
				: null
			} */}
		</Grid>
	);
}

export default TopicQuestionItem;