import React, { useState, useContext } from "react";
import {
  Menu,
  MenuItem,
  Button,
  makeStyles,
  createStyles,
  Theme,
  Box,
  Link,
} from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";

import { Redirect } from "react-router-dom";
import Avatar from "./Avatar";
import { Link as RouterLink } from "react-router-dom";
import { apiInstance } from "../common/api";
import { GlobalStateContext } from "../common/providers/global-state-provider/global-state.context";
import { GlobalStateProviderActionType } from "../common/providers/global-state-provider/enums/global-state-provider-action-type.enum";
import useAPINotification from "../common/hooks/useAPINotification";
import { APINotificationActionTypes } from "../common/providers/APINotification/enums/APINotificationActionType.enum";
import { Severity } from "../common/enums/severity.enum";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    profile: {
      display: "flex",
      alignItems: "center",
      marginLeft: "auto",
    },
    titles: {
      display: "flex",
      flexDirection: "column",
    },
  })
);

const Profile = () => {
  const classes = useStyles();
  const { dispatchNotification } = useAPINotification();
  const { dispatchToGlobal, globalState } = useContext(GlobalStateContext);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [loggedOut, setLoggedOut] = useState<boolean>(false);

  const menuEl = document.getElementById("open-menu");

  const handleMenuOpen = () => setMenuOpen(true);
  const handleMenuClose = () => setMenuOpen(false);

  const handleLogout = async () => {
    handleMenuClose();

    try {
      await apiInstance.get("/auth/logout");
      sessionStorage.removeItem("auth");
      setLoggedOut(true);
      dispatchToGlobal({ type: GlobalStateProviderActionType.RESET_STATE });
    } catch (error) {
      console.log(error);
      dispatchNotification({
        type: APINotificationActionTypes.SET_NOTIFICATION,
        payload: {
          message: "Error trying to logout",
          severity: Severity.ERROR,
        },
      });
    }
  };

  if (loggedOut) {
    return <Redirect to="/login" />;
  }

  const { email, title, firstName, lastName, jobTitle, resources } =
    globalState.user;

  const avatarResources =
    resources &&
    resources.length &&
    resources.filter((r) => r.type === "avatar");
  const avatar =
    avatarResources && avatarResources.length
      ? avatarResources[avatarResources.length - 1].location
      : null;

  return (
    <div className={classes.profile}>
      <Button
        aria-controls="profile-menu"
        aria-haspopup="true"
        aria-label="account of current user"
        onClick={handleMenuOpen}
      >
        <Avatar
          firstName={firstName}
          lastName={lastName}
          email={email}
          avatarImg={avatar ? avatar : null}
        />
        <MoreVert id="open-menu" />
      </Button>

      <Menu
        id="profile-menu"
        anchorEl={menuEl}
        keepMounted
        open={menuOpen}
        onClose={handleMenuClose}
      >
        <Box>
          <MenuItem onClick={handleMenuClose}>
            <Link color="inherit" component={RouterLink} to="/profile">
              Profile
            </Link>
          </MenuItem>

          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Box>
      </Menu>

      <div className={classes.titles}>
        <Box fontWeight="fontWeightMedium">
          {title} {firstName} {lastName}
        </Box>
        <Box fontSize={12}>{jobTitle}</Box>
      </div>
    </div>
  );
};

export default Profile;
