import React, { Fragment, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  makeStyles,
  createStyles,
  Theme,
  Paper,
  Typography,
  List,
  ListItem,
  Button,
  Dialog,
  CircularProgress,
  DialogActions,
  Box,
} from "@material-ui/core";
import QualityAssurances from "./QuaityAssurances/QualityAssurances";
import { Patient } from "../../models";
import { Signatures } from "./Signatures";
import useConsentApplication from "./hooks";
import { Alert } from "@material-ui/lab";
import ResendApplicationButton from "../patients/PatientApplications/ResendApplicationButton";
import NotifyPatientButton from "./NoitifyPatientButton";
import ConsentFormProvider from "../../common/providers/ConsentFormProvider/ConsentForm.provider";
import LoggedInTemplate from "../templates/LoggedIn";
import { Loading } from "../patients/PatientOverview";
import Procedure from "./Procedure";
import PatientProvider from "../../common/providers/PatientProvider";
import ProcedureProvider from "../../common/providers/ProcedureProvider";
import { ConsentFormStatus } from "../../common/enums/consent-form-status.enum";
import { RoleTypes } from "../../common/enums/roleTypes.enum";
import { GlobalStateContext } from "../../common/providers/global-state-provider/global-state.context";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "block",
    },
    actions: {
      marginBottom: 15,
      display: "flex",
      justifyContent: "flex-end",
      "& button": {
        marginLeft: 8,
      },
    },
    formContainer: {
      padding: 25,
      boxSizing: "border-box",
      maxWidth: 900,
    },
    title: {
      fontSize: 24,
      display: "flex",
      alignItems: "center",
      marginBottom: 15,
    },
    list: {
      width: "100%",
    },
    listItem: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
    notification: {
      display: "flex",
    },
    section: {
      display: "block",
      padding: 0,
      marginBottom: 75,
      marginTop: 25,
      paddingBottom: 25,
      "&:last-of-type": {
        marginBottom: 0,
      },
    },
  })
);

const ConsentApplication = () => {
  const css = useStyles();
  const { globalState } = useContext(GlobalStateContext);

  const Content = () => {
    const {
      consentForm,
      downloadPDF,
      handleSubmission,
      isLoading,
      loading,
      message,
      setMessage,
      showFlagAlert,
      showNotificationButton,
      showResendButton,
      showQA,
      showSignatures,
      downloadSummaryPDF,
    } = useConsentApplication();

    return loading ? (
      <Loading />
    ) : (
      <div className={css.container}>
        <section className={css.actions}>
          <Button
            variant="outlined"
            component={RouterLink}
            to={`/patients/${consentForm.patientId}`}
          >
            Back
          </Button>
          {consentForm.locked ? (
            <Fragment>
              <Button variant="outlined" color="primary" onClick={downloadPDF}>
                Download
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={downloadSummaryPDF}
              >
                Download Summary
              </Button>
            </Fragment>
          ) : null}
          {showNotificationButton() && (
            <NotifyPatientButton
              hasNotified={
                consentForm.status === ConsentFormStatus.PATIENT_NOTIFIED
              }
              disabled={false}
            />
          )}
          {showResendButton() && (
            <ResendApplicationButton consentFormId={consentForm.id} />
          )}
        </section>

        {showFlagAlert() ? (
          <Alert severity="warning">
            Note: There are some potentially high importance answers below for
            you to review.
          </Alert>
        ) : null}

        <Paper elevation={1} className={css.formContainer}>
          {message ? (
            <Dialog
              onClose={(e) => setMessage(null)}
              aria-labelledby="simple-dialog-title"
              open={message ? true : false}
            >
              <Alert severity={message.type}>{message.text}</Alert>
              <DialogActions>
                <Button onClick={(e) => setMessage(null)} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          ) : null}
          <PatientProvider patientId={consentForm.patientId}>
            <List>
              <ListItem>
                <Typography variant="h6" component="h6">
                  {consentForm.clinic ? consentForm.clinic.name : ""} Consent
                  Certificate
                </Typography>
              </ListItem>
              <ListItem>
                <ProcedureProvider procedureId={consentForm.procedureId}>
                  <Procedure />
                </ProcedureProvider>
              </ListItem>
              {showQA() && (
                <ListItem divider={true} className={css.section}>
                  <QualityAssurances
                    disableQA={true}
                    applicationId={consentForm.id}
                    patient={consentForm.patient as Patient}
                    locked={consentForm.locked}
                  />
                </ListItem>
              )}

              {showSignatures() ? (
                <ListItem className={css.section}>
                  <Signatures consentFormStatus={consentForm.status} />
                </ListItem>
              ) : null}
            </List>
          </PatientProvider>

          {showNotificationButton() && (
            <NotifyPatientButton
              hasNotified={
                consentForm.status === ConsentFormStatus.PATIENT_NOTIFIED
              }
              disabled={false}
              fullWidth
              // queries={queries}
              // responses={responses}
            />
          )}

          {consentForm.status ===
            ConsentFormStatus.PENDING_CONSULTANT_SUBMISSION &&
            !consentForm.locked && (
              <Button
                disabled={isLoading()}
                onClick={handleSubmission}
                variant="contained"
                fullWidth
                size="large"
                color="primary"
                tabIndex={2}
                type="submit"
              >
                Submit Consent{" "}
                {isLoading() && (
                  <CircularProgress style={{ marginLeft: 15 }} size={15} />
                )}
              </Button>
            )}
        </Paper>
      </div>
    );
  };

  return (
    <ConsentFormProvider>
      <LoggedInTemplate title="Consent Certificate">
        <Box display="flex">
          <Content />
        </Box>
      </LoggedInTemplate>
    </ConsentFormProvider>
  );
};

export default ConsentApplication;
