import { Button, Checkbox, FormControlLabel, FormGroup, Grid, Radio, RadioGroup, Typography } from "@material-ui/core";
import React from "react";
import { QuestionTypes } from "../../../../common/enums/question-types.enum";
import { Question } from "../../../../models/Question";
import QuestionForm from "../../question-form";

import useQuestionItem from "./hooks";
import { useQuestionItemStyles } from "./question-item.css";

/* 
	Individual Risk
*/
interface iProps {
	canBeEdited?: boolean;
	question: Question;
}

const QuestionItem = ({ canBeEdited, question }: iProps) => {

	const classes = useQuestionItemStyles();
	const { deleted, handleDelete, handleSubmit, showForm, state, toggleEdition } = useQuestionItem({question});

	return (
		deleted
		? null
		: showForm
			? <QuestionForm handleSubmit={handleSubmit} cancelAction={toggleEdition} question={state} />		
			: (
				<Grid container className={classes.questionContainer}>
					<Grid item xs={12}>
						<Typography variant="h4" className={classes.questionTitle}>{state.title}</Typography>
						<Typography variant="caption" className={classes.questionDesc}>{state.description}</Typography>
						{
							question.type === QuestionTypes.RADIO_GROUP || question.type === QuestionTypes.RADIO_GROUP_FREE_TEXT
							? (
								<RadioGroup name="value" className={classes.options} >
									{state.options ? state.options.split(",").map( option => <FormControlLabel value={option} label={option} disabled control={<Radio />}></FormControlLabel>) : null}
									{state.type === QuestionTypes.RADIO_GROUP_FREE_TEXT ? <FormControlLabel value="other" label="Other" disabled control={<Radio />}></FormControlLabel> : null}
								</RadioGroup>
								)
								: null
						}
						{
							question.type === QuestionTypes.MULTI_CHOICE || question.type === QuestionTypes.MULTI_CHOICE_FREE_TEXT
							? (
								<FormGroup row >
									{state.options ? state.options.split(",").map( option => <FormControlLabel value={option} label={option} disabled control={<Checkbox />}></FormControlLabel>) : null}
									{state.type === QuestionTypes.MULTI_CHOICE_FREE_TEXT ? <FormControlLabel value="other" label="Other" disabled control={<Checkbox />}></FormControlLabel> : null}
								</FormGroup>
								)
								: null
						}
					</Grid>
					<Grid item xs={12} >
						{
							canBeEdited
							? (
									<Grid item xs={12} className={classes.buttons} >
										<Button
											onClick={toggleEdition}
											color="primary"
											variant="contained"
											className={classes.button}
										>
											Edit Question
										</Button>
										<Button
											className={classes.button}
											variant="contained"
											color="secondary"
											onClick={handleDelete}>
											Delete
										</Button>
									</Grid>
								)
							: null
						}
					</Grid>
				</Grid>
			)
	);
}

export default QuestionItem;